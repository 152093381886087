import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { useAuth } from "../../../../AuthContext";
import Sidebar from "../../components/Sidebar/adminSidebar";
import TeacherHeader from "./components/TeacherHeader";
import GeneralInformation from "./components/GeneralInformation";
import DegreeInformation from "./components/DegreeInformation";
import ImageModal from "./components/ImageModal";
import ActionModals from "./components/ActionModals";
import "./adminTeacherDashboard.css";
import "./adminTeacherDetails.css";

const AdminTeacherDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { accessToken } = useAuth();
  
  const [teacher, setTeacher] = useState(null);
  const [pendingTeachers, setPendingTeachers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState("detailedInformation");
  const [zoomedImage, setZoomedImage] = useState(null);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isNotificationModalOpen, setIsNotificationModalOpen] = useState(false);
  const [isRejectModalOpen, setIsRejectModalOpen] = useState(false);

  useEffect(() => {
    if (!accessToken) {
      navigate("/login");
    }
  }, [accessToken, navigate]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [teachersResponse, pendingResponse] = await Promise.all([
          axios.get(`${process.env["REACT_APP_BASE_API"]}/api/admin/getAllTeacher/`, {
            headers: { Authorization: `Bearer ${accessToken}` },
          }),
          axios.get(`${process.env["REACT_APP_BASE_API"]}/api/admin/getTeachersInActive/`, {
            headers: { Authorization: `Bearer ${accessToken}` },
          })
        ]);

        const foundTeacher = teachersResponse.data.find(
          (teacher) => teacher.m_id === parseInt(id)
        );
        setTeacher(foundTeacher);
        setPendingTeachers(pendingResponse.data);
      } catch (error) {
        setError("Error fetching data");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id, accessToken]);

  const handleAccept = async () => {
    try {
      if (!teacher) return;
      await axios.post(
        `${process.env["REACT_APP_BASE_API"]}/api/admin/acceptTeacher/`,
        { id: teacher.m_id },
        { headers: { Authorization: `Bearer ${accessToken}` }}
      );
      setIsNotificationModalOpen(true);
    } catch (error) {
      console.error("Error accepting teacher:", error);
      alert("Failed to accept teacher");
    }
  };

  const handleRejectTeacher = async (reason) => {
    try {
      if (!teacher) return;
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_API}/api/admin/rejectTeacher/`,
        { id: teacher.m_id, reason },
        { headers: { Authorization: `Bearer ${accessToken}` }}
      );
      setIsRejectModalOpen(false);
      setIsNotificationModalOpen(true);
      setTeacher(response.data);
    } catch (error) {
      const errorMessage = error.response?.data?.error || "An error occurred while rejecting the teacher.";
      alert(errorMessage);
    }
  };

  if (error) return <div>{error}</div>;

  return (
    <div className="main">
      <Sidebar pendingCount={pendingTeachers.length} />
      <div className="teacher-main">
        <TeacherHeader 
          teacher={teacher}
          onAccept={() => setIsConfirmModalOpen(true)}
          onReject={() => setIsRejectModalOpen(true)}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        
        {activeTab === "detailedInformation" && (
          <>
            <GeneralInformation teacher={teacher} />
            <DegreeInformation 
              teacher={teacher} 
              onImageClick={(imageSrc) => setZoomedImage(imageSrc)} 
            />
          </>
        )}
        
        {activeTab === "courses" && (
          <div>Courses content here</div>
        )}
      </div>

      {zoomedImage && (
        <ImageModal 
          imageSrc={zoomedImage} 
          onClose={() => setZoomedImage(null)} 
        />
      )}

      <ActionModals
        isConfirmModalOpen={isConfirmModalOpen}
        isNotificationModalOpen={isNotificationModalOpen}
        isRejectModalOpen={isRejectModalOpen}
        onConfirmAccept={handleAccept}
        onCloseConfirm={() => setIsConfirmModalOpen(false)}
        onCloseNotification={() => {
          setIsNotificationModalOpen(false);
          navigate(`/admin/teacher/${teacher.m_id}`);
          window.location.reload();
        }}
        onConfirmReject={handleRejectTeacher}
        onCloseReject={() => setIsRejectModalOpen(false)}
      />
    </div>
  );
};

export default AdminTeacherDetails;
