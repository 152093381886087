import React, { useState } from 'react';
import './ChangePasswordModal.css';
import ValidateRegister from '../../../pages/TeacherSignUp/verify'; 

const ChangePasswordModal = ({ onClose, onChangePassword }) => {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [currentPasswordError, setCurrentPasswordError] = useState('');

  const validatePassword = () => {
    let valid = true;
    const passwordValidation = ValidateRegister.validate_password(newPassword); 

    if (!newPassword) {
      setPasswordError('Password is required.');
      valid = false;
    } else if (passwordValidation === 'invalid_length') {
      setPasswordError('Password must be between 8 and 64 characters.');
      valid = false;
    } else if (passwordValidation === 'contains_space') {
      setPasswordError('Password should not contain spaces.');
      valid = false;
    } else if (passwordValidation === 'no_uppercase') {
      setPasswordError('Password must contain at least one uppercase letter.');
      valid = false;
    } else if (passwordValidation === 'no_lowercase') {
      setPasswordError('Password must contain at least one lowercase letter.');
      valid = false;
    } else if (passwordValidation === 'no_digit') {
      setPasswordError('Password must contain at least one digit.');
      valid = false;
    } else if (passwordValidation === 'no_special_char') {
      setPasswordError('Password must contain at least one special character.');
      valid = false;
    } else {
      setPasswordError('');
    }

    if (!currentPassword) {
      setCurrentPasswordError('Current password is required.');
      valid = false;
    } else {
      setCurrentPasswordError('');
    }

    return valid;
  };

  const handleSubmit = () => {
    setErrorMessage('');
    if (!validatePassword()) return;

    if (newPassword !== confirmPassword) {
      setErrorMessage('Mật khẩu mới và xác nhận không khớp');
      return;
    }

    onChangePassword(currentPassword, newPassword);
  };

  return (
    <div className="modal">
      <div className="modal-content" style={{ margin: '8% auto' }}>
        <span className="close" onClick={onClose}>&times;</span>
        <h3>Đổi mật khẩu</h3>
        <p>Mật khẩu của bạn phải có tối thiểu 8 ký tự, bao gồm cả chữ số, chữ cái và ký tự đặc biệt (!@$%...)</p>

        <input
          type="password"
          placeholder="Nhập mật khẩu hiện tại của bạn"
          value={currentPassword}
          onChange={(e) => setCurrentPassword(e.target.value)}
        />
        {currentPasswordError && <p className="error-message">{currentPasswordError}</p>}

        <input
          type="password"
          placeholder="Nhập mật khẩu mới của bạn"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
        />
        {passwordError && <p className="error-message">{passwordError}</p>}

        <input
          type="password"
          placeholder="Xác nhận mật khẩu mới của bạn"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />

        {errorMessage && <p className="error-message">{errorMessage}</p>}

        <a className="forgot-password" href="/enter-email">
          Bạn quên mật khẩu ư?
        </a>

        {/* <div className="checkbox-wrapper">
          <input type="checkbox" id="logout-other-devices" />
          <label htmlFor="logout-other-devices">Đăng xuất khỏi các thiết bị khác</label>
        </div> */}

        <button className="btn-confirm btn-confirm-change-password" onClick={handleSubmit}>
          Đổi mật khẩu
        </button>
      </div>
    </div>
  );
};

export default ChangePasswordModal;