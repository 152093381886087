import React from "react";

const GeneralInformation = ({ teacher }) => {
  if (!teacher) return <div>No teacher data found.</div>;

  const teacherInfo = [
    { label: "ID", value: teacher.m_id },
    { label: "Username", value: teacher.m_user_name },
    { label: "Email", value: teacher.m_email || "N/a" },
    { label: "Full name", value: teacher.m_name || "N/a" },
    { label: "Birthday", value: teacher.m_birthday || "N/a" },
    { label: "Phone number", value: teacher.m_phone || "N/a" },
    {
      label: "Current status",
      value: "Online",
      customClass: "box-content-item-badge-green",
    },
    {
      label: "Acceptance status",
      value:
        teacher.m_status === "ACTIVE"
          ? "Active"
          : teacher.m_status === "REJECTED"
            ? "Rejected"
            : "Pending",
      customClass:
        teacher.m_status === "ACTIVE"
          ? "box-content-item-badge-green"
          : teacher.m_status === "REJECTED"
            ? "box-content-item-badge-red"
            : "box-content-item-badge-orange",
    },
  ];

  return (
    <div className="general-info-section">
      <div className="general-info-box">
        <div className="general-info-box-header">General Information</div>
        <div className="general-info-box-body">
          <div className="general-info-box-content">
            {teacherInfo.map((info, index) => (
              <div key={index} className="general-info-box-content-item">
                <div className="box-content-item-label-sub">{info.label}</div>
                <div
                  className={info.customClass || "box-content-item-label-main"}
                >
                  {info.customClass ? `• ${info.value}` : info.value}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeneralInformation;
