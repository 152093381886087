import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './GroupCreation.css';
import { useAuth } from '../../AuthContext';
import AWS from 'aws-sdk';
import { toast } from 'react-toastify';

const s3 = new AWS.S3({
    accessKeyId: process.env["REACT_APP_AWS_ACCESS_KEY_ID"],
    secretAccessKey: process.env["REACT_APP_AWS_SECRET_ACCESS_KEY"],
    region: process.env["REACT_APP_AWS_REGION"],
});

const uploadToS3 = async (file) => {
    const params = {
        Bucket: process.env["REACT_APP_S3_BUCKET_NAME"],
        Key: `${Date.now()}-${file.name}`, 
        Body: file,
    };
    try {
        const data = await s3.upload(params).promise();
        return data.Location; 
    } catch (error) {
        console.error('Error uploading to S3:', error);
        throw new Error('Upload failed');
    }
};

function GroupCreation({ onClose, onGroupCreated, currentUser }) {
  const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));
  const [groupName, setGroupName] = useState('');
  const [groupAvatar, setGroupAvatar] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const { accessToken } = useAuth();

  const handleAvatarChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setGroupAvatar(e.target.files[0]);
    }
  };

  const handleSearchChange = async (e) => {
    setSearchTerm(e.target.value);
    if (e.target.value) {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_API}/api/user/search-users/`, {
          params: { query: e.target.value },
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        const filteredResults = response.data.filter(user => user.username !== currentUser);
        setSearchResults(filteredResults);
      } catch (error) {
        console.error('Error searching users:', error);
      }
    } else {
      setSearchResults([]);
    }
  };

  const handleAddMember = (user) => {
    if (!selectedMembers.some(member => member.username === user.username)) {
      setSelectedMembers([...selectedMembers, user]);
    }
  };

  const handleRemoveMember = (username) => {
    setSelectedMembers(selectedMembers.filter(member => member.username !== username));
  };

  const handleCreateGroup = async () => {
    if (selectedMembers.length < 2) {
        toast.error('Group must have at least 3 participants.');
        return;
    }
    try {
      let avatarUrl = null;
      if (groupAvatar) {
        const formData = new FormData();
        formData.append('file', groupAvatar);
        const uploadResult = await uploadToS3(groupAvatar);
        avatarUrl = uploadResult;
      }

      const response = await axios.post(`${process.env.REACT_APP_BASE_API}/api/group/createGroup/`, {
        m_group_name: groupName,
        m_group_avatar: avatarUrl,
        m_group_participants: selectedMembers.map(member => member.username),
      }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });

      if (response.status === 201) {
        toast.success('Group created successfully!');
        await sleep(1000); // 1 second delay
        onGroupCreated();
        onClose();
        window.location.reload();
      }
    } catch (error) {
        toast.error('Failed to create group.');
      console.error('Error creating group:', error);
    }
  };

  return (
    <div className="group-creation-modal">
      <div className="group-creation-modal-content">
        <h2>Create Group</h2>
        <input
          type="text"
          placeholder="Group Name"
          value={groupName}
          onChange={(e) => setGroupName(e.target.value)}
        />
        <input
          type="file"
          onChange={handleAvatarChange}
        />
        <input
          type="text"
          placeholder="Search users..."
          value={searchTerm}
          onChange={handleSearchChange}
        />
        <div className="search-results">
          {searchResults.map(user => (
            <div key={user.username} className="search-result-item" onClick={() => handleAddMember(user)}>
              <div className="user-info">
                <span className="username">{user.username}</span>
                <span className="role">{user.role}</span>
              </div>
            </div>
          ))}
        </div>
        <div className="selected-members">
          {selectedMembers.map(member => (
            <div key={member.username} className="selected-member-item">
              <span>{member.username}</span>
              <button onClick={() => handleRemoveMember(member.username)}>X</button>
            </div>
          ))}
        </div>
        <button onClick={handleCreateGroup}>Create</button>
        <button onClick={onClose}>Cancel</button>
      </div>
    </div>
  );
}

export default GroupCreation;