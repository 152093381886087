import React from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate if using react-router-dom
import FreeCourseCard from '../../../Card/CourseCard/CourseCard';
import CourseCardWhiteBig from '../../../Card/CourseCard/CourseCardWhiteBig';
import './GlobalCourse.css'; // Import the CSS file for styling


const freeCourses = [
    { title: "Tiếng anh cơ bản", subtitle: "Kiến thức nhập môn", userCount: "130.643", duration: "3h12p" },
    { title: "TOEIC 700+", subtitle: "TOEIC nâng cao cho KSTN BK", userCount: "29.957", duration: "10h18p" },
    { title: "IELTS 6.0", subtitle: "IELTS cho sinh viên OISP", userCount: "201.438", duration: "29h5p" },
    { title: "AV nói viết 245+", subtitle: "Chuẩn đầu ra KSTN", userCount: "45.315", duration: "6h31p" },
];

const GlobalCourse = ({ myCourses }) => {
    const navigate = useNavigate(); // Define navigate using useNavigate hook
    const user = {}; // Define user object or get it from props/context

    const handleDelete = (courseId) => {
        if (window.confirm("Are you sure you want to delete this course?")) {
            // Perform delete operation here
            // After deletion, navigate to the teacher/course page
            navigate('/teacher/course');
        }
    };

    return (
        <div className="course-container">
            <div className="my-courses">
                <h2 className="my-courses-title">Khóa học của tôi</h2>
                {myCourses.length > 0 ? (
                    myCourses.map((course, index) => (
                        <div key={index} className="course-card-container">
                            <CourseCardWhiteBig 
                                title={course.m_title}
                                subtitle={course.m_description}
                                userCount={0}
                                onClick={() => navigate(`/teacher/course/${course.m_course_id}`)}
                            />
                        </div>
                    ))
                ) : (
                    <div className="no-courses">Bạn chưa có khóa học nào</div>
                )}
            </div>

            <hr className="divider" />

            <div className="global-course">
                <h2 className='free-courses-title'>Khóa học miễn phí</h2>
                {freeCourses.map((course, index) => (
                    <FreeCourseCard 
                        key={index}
                        title={course.title}
                        subtitle={course.subtitle}
                        userCount={course.userCount}
                        duration={course.duration}
                    />
                ))}
            </div>
        </div>
    );
};

export default GlobalCourse;