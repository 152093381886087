import React, { useState, useRef } from "react";
import ProfileHeader from "../../../../components/ProfileHeader/profileHeader";
import defaultBackground from "../../../../assets/bg_teacher_detail.jpg";
import "../VocabularyLesson/NewVocabularyLesson.css";
import { useAuth } from "../../../../AuthContext";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import AWS from "aws-sdk";
import QuizHeader from "./components/QuizHeader/QuizHeader";
import QuizQuestionModal from "./components/QuizQuestionModal/QuizQuestionModal";
import './NewQuizQuestion.css';
import ArrowUp from "../../../../assets/Arrow--up.svg";
import ArrowDown from "../../../../assets/Arrow--down.svg";
import Launch from "../../../../assets/Launch.svg";
import TrashCan from "../../../../assets/Trash-can.svg";
// AWS S3 Configuration
const s3 = new AWS.S3({
  accessKeyId: process.env["REACT_APP_AWS_ACCESS_KEY_ID"],
  secretAccessKey: process.env["REACT_APP_AWS_SECRET_ACCESS_KEY"],
  region: "ap-southeast-1",
});

const uploadToS3 = async (file) => {
  const params = {
    Bucket: "ivsenglishapp",
    Key: `lessons/${Date.now()}-${file.name}`,
    Body: file,
  };
  try {
    const data = await s3.upload(params).promise();
    return data.Location;
  } catch (error) {
    console.error("Error uploading to S3:", error);
    throw new Error("Upload failed");
  }
};

const NewQuizLesson = () => {
  const [quizList, setQuizList] = useState([]);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const { accessToken } = useAuth();
  const { state } = useLocation();
  const navigate = useNavigate();
  const course_id = state?.course_id;
  const courseTitle = state?.courseTitle;
  const [lessonImage, setLessonImage] = useState({
    preview: defaultBackground,
    file: null,
    url: "",
  });
  const fileInputRef = useRef(null);
  const [isQuestionModalOpen, setIsQuestionModalOpen] = useState(false);
  const [questionData, setQuestionData] = useState(null);
  const [editingIndex, setEditingIndex] = useState(null);

  const addQuiz = () => {
    setQuestionData(null);
    setEditingIndex(null);
    setIsQuestionModalOpen(true);
  };

  const handleSaveQuestion = (questionData) => {
    console.log("Question data: ", questionData)
    if (editingIndex !== null && editingIndex >= 0) {
      const updatedList = [...quizList];
      updatedList[editingIndex] = {
        title: questionData.title,
        m_question_text: questionData.m_question_text,
        questionType: questionData.questionType,
        m_correct_text_answer: questionData.m_correct_text_answer,
        option_items: questionData.option_items.map((option) => ({
          option_text: option.option_text,
          is_correct: option.isCorrect || option.is_correct,
        })),
      };
      setQuizList(updatedList);
    } else {
      setQuizList(prevList => [...prevList, {
        title: questionData.title,
        m_question_text: questionData.m_question_text,
        questionType: questionData.questionType,
        m_correct_text_answer: questionData.m_correct_text_answer,
        option_items: questionData.option_items.map((option) => ({
          option_text: option.option_text,
          is_correct: option.isCorrect || option.is_correct,
        })),
      }]);
    }
    setEditingIndex(null);
    setQuestionData(null);
  };

  const removeQuiz = (index) => {
    const updatedList = quizList.filter((_, i) => i !== index);
    setQuizList(updatedList);
  };

  const saveQuiz = async () => {
    if (quizList.length === 0) {
        alert("Vui lòng thêm ít nhất một câu hỏi.");
        return;
    }

    for (const quiz of quizList) {
        if (quiz.option_items.length < 2 && quiz.questionType == "MULTIPLE_CHOICE") {
            alert(`Câu hỏi "${quiz.title}" cần có ít nhất 2 lựa chọn.`);
            return;
        }

        if(quiz.questionType == "MULTIPLE_CHOICE") {
          const correctAnswers = quiz.option_items.filter(option => option.is_correct).length;
          if (correctAnswers !== 1) {
              alert(`Câu hỏi "${quiz.title}" phải có đúng một đáp án đúng.`);
              return;
          }
        }
    }

    console.log("Quiz list: ", quizList)

    const quizItems = quizList.map((item) => ({
      m_question_text: item.title,
      m_question_description: item.m_question_text,
      m_correct_text_answer: item.m_correct_text_answer,
      m_question_type: item.questionType,
      option_items: item.questionType === "MULTIPLE_CHOICE" 
          ? item.option_items.map((option) => ({
              option_text: option.option_text,
              is_correct: option.is_correct,
          }))
          : []  
    }));

    const payload = {
        m_title: title,
        m_course_id: course_id,
        m_content: description,
        m_lesson_image: lessonImage.url,
        quiz_items: quizItems,
    };

    try {
        const response = await axios.post(
            `${process.env.REACT_APP_BASE_API}/api/course/createQuiz/`,
            payload,
            {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            }
        );

        if (response.status === 201) {
            alert("Tạo quiz thành công!");
            navigate(`/teacher/course/${course_id}`);
        } else {
            alert("Lỗi khi tạo quiz.");
        }
    } catch (error) {
        console.error(error);
        alert("Có lỗi xảy ra khi tạo quiz.");
    }
};

  // Add image handling functions
  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      try {
        const preview = URL.createObjectURL(file);
        setLessonImage((prev) => ({
          ...prev,
          preview: preview,
          file: file,
        }));

        const imageUrl = await uploadToS3(file);
        setLessonImage((prev) => ({
          ...prev,
          url: imageUrl,
        }));
      } catch (error) {
        console.error("Upload failed:", error);
        alert("Lỗi khi tải ảnh lên");
        setLessonImage({
          preview: defaultBackground,
          file: null,
          url: "",
        });
      }
    }
  };

  const handleCancel = () => {
    navigate(-1);
  };

  const handleChangeImageClick = () => {
    fileInputRef.current.click();
  };

  const moveQuiz = (index, direction) => {
    const newList = [...quizList];
    if (direction === "up" && index > 0) {
      [newList[index - 1], newList[index]] = [newList[index], newList[index - 1]];
    } else if (direction === "down" && index < newList.length - 1) {
      [newList[index], newList[index + 1]] = [newList[index + 1], newList[index]];
    }
    setQuizList(newList);
  };

  const handleEditQuiz = (quiz, index) => {
    const editData = {
      ...quiz,
      option_items: quiz.option_items.map(option => ({
        option_text: option.option_text,
        is_correct: option.is_correct
      }))
    };
    setQuestionData(editData);
    setEditingIndex(index);
    setIsQuestionModalOpen(true);
  };

  return (
    <div>
      <div className="vocabulary-lesson-header-newlesson">
        <ProfileHeader />
      </div>

      <div className="vocabulary-lesson-wrapper">
        <div className="vocabulary-lesson-navigation">
          <button className="vocabulary-back-btn" onClick={handleCancel}>
            BACK
          </button>
        </div>

        <div className="vocabulary-lesson-container">
          <div className="vocabulary-header-newlesson">
            <QuizHeader
              courseTitle={courseTitle}
              isNewLesson={true}
              onSave={saveQuiz}
              onCancel={handleCancel}
            />
          </div>

          <div className="lesson-image-container">
            <img
              src={lessonImage.preview || defaultBackground}
              alt="Lesson background"
              className="vocabulary-lesson-background"
            />
            <div className="change-image-button">
              <button
                className="btn-change-image"
                onClick={handleChangeImageClick}
              >
                Change Image +
              </button>
              <input
                ref={fileInputRef}
                type="file"
                accept="image/*"
                onChange={handleImageUpload}
                style={{ display: "none" }}
              />
            </div>
          </div>

          <div className="new-vocabulary-lesson-container">
            <div className="new-vocabulary-lesson-form">
              <div className="new-vocabulary-lesson-form-group">
                <label>
                  Tiêu đề<span className="required-mark">*</span>
                </label>
                <input
                  type="text"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  placeholder="Tên tiêu đề"
                />
              </div>

              <div className="new-vocabulary-lesson-form-group">
                <label>
                  Description<span className="required-mark">*</span>
                </label>
                <textarea
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  placeholder="Descriptions"
                />
              </div>
            </div>
          </div>
          <div className="vocabulary-edit-container-newlesson">
            <div className="vocabulary-edit-header">
              <button
                className="vocabulary-edit-add-newlesson"
                onClick={addQuiz}
              >
                Add question
              </button>
            </div>

            <div className="quiz-list-container">
              {quizList.map((quiz, quizIndex) => (
                <div className="quiz-question-card" key={quizIndex}>
                  <div className="quiz-question-header">
                    <h3>{quiz.title}</h3>
                    <div className="quiz-question-actions">
                      <button
                        className="quiz-action-btn"
                        onClick={() => moveQuiz(quizIndex, "down")}
                        disabled={quizIndex === quizList.length - 1}
                      >
                        <img
                          src={ArrowDown}
                          alt="Move down"
                          style={{
                            opacity: quizIndex === quizList.length - 1 ? 0.5 : 1,
                          }}
                        />
                      </button>
                      <button
                        className="quiz-action-btn"
                        onClick={() => moveQuiz(quizIndex, "up")}
                        disabled={quizIndex === 0}
                      >
                        <img
                          src={ArrowUp}
                          alt="Move up"
                          style={{ opacity: quizIndex === 0 ? 0.5 : 1 }}
                        />
                      </button>
                      <button
                        className="quiz-action-btn"
                        onClick={() => handleEditQuiz(quiz, quizIndex)}
                      >
                        <img src={Launch} alt="Edit" />
                      </button>
                      <button
                        className="quiz-action-btn"
                        onClick={() => removeQuiz(quizIndex)}
                      >
                        <img src={TrashCan} alt="Delete" />
                      </button>
                    </div>
                  </div>
                  
                  <div className="quiz-question-content">
                    <div dangerouslySetInnerHTML={{ __html: quiz.m_question_text }} />
                  </div>

                  <div className="quiz-options-container">
                    {quiz.option_items.map((option, optionIndex) => (
                      <div key={optionIndex} className="quiz-option-item">
                        <div className="quiz-option-letter">
                          {String.fromCharCode(65 + optionIndex)}.
                        </div>
                        <div className="quiz-option-text">
                          {option.option_text}
                        </div>
                        <div className="quiz-option-correct">
                          {option.is_correct && (
                            <span className="correct-answer-indicator">✓</span>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <QuizQuestionModal
        isOpen={isQuestionModalOpen}
        onClose={() => {
          setIsQuestionModalOpen(false);
          setQuestionData(null);
          setEditingIndex(null);
        }}
        onSave={handleSaveQuestion}
        initialData={questionData}
      />
    </div>
  );
};

export default NewQuizLesson;
