import React from 'react';

const DegreeInformation = ({ teacher, onImageClick }) => {
  const parseImages = (imageString) => {
    if (!imageString) return [];
    return imageString.split(',').filter(url => url.trim());
  };

  const images = [
    {
      label: 'Portraits',
      src: parseImages(teacher?.m_portrait_image),
      fallback: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQigWH0UfCafVa7QtE7Ogmxh5dPn2iq0z568A&s",
      alt: "Ảnh chân dung"
    },
    {
      label: 'Certificates',
      src: parseImages(teacher?.m_certificate_image),
      fallback: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQigWH0UfCafVa7QtE7Ogmxh5dPn2iq0z568A&s",
      alt: "Ảnh chứng chỉ"
    },
    {
      label: 'Others',
      src: parseImages(teacher?.m_other_image),
      fallback: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQigWH0UfCafVa7QtE7Ogmxh5dPn2iq0z568A&s",
      alt: "Ảnh khác"
    }
  ];

  const renderImageGrid = (images, fallback, alt, label) => {
    if (images.length === 0) {
      return (
        <div className="box-content-item-label-main" style={{ width: "100%", display: "flex" }}>
          <img
            src={fallback}
            alt={alt}
            className="image-placeholder"
            style={{ maxWidth: 500 }}
          />
        </div>
      );
    }

    return (
      <div className="box-content-item-label-main" style={{ width: "100%", display: "flex", flexWrap: "wrap", gap: "16px" }}>
        {images.map((img, idx) => (
          <img
            key={idx}
            src={img}
            alt={`${alt} ${idx + 1}`}
            className="image-placeholder"
            onClick={() => onImageClick(img)}
            style={{ maxWidth: 250, flex: "0 0 auto" }}
          />
        ))}
      </div>
    );
  };

  return (
    <div className="general-info-section">
      <div className="general-info-box">
        <div className="general-info-box-header">Degree information</div>
        <div className="general-info-box-body">
          {teacher ? (
            <div className="general-info-box-content">
              <div className="general-info-box-content-item" style={{ flexDirection: "column", width: "100%" }}>
                {images.map((image, index) => (
                  <React.Fragment key={index}>
                    <div className="box-content-item-label-sub">{image.label}</div>
                    {renderImageGrid(image.src, image.fallback, image.alt, image.label)}
                  </React.Fragment>
                ))}
              </div>
            </div>
          ) : (
            <div>No teacher data found.</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DegreeInformation;