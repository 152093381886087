import ProfileHeader from "../../../components/ProfileHeader/profileHeader";
import { useNavigate } from "react-router-dom";
import './newCourse.css';
import { useState, useEffect } from "react";
import { Button, TextField, Switch, FormControlLabel } from "@mui/material";
import axios from "axios";
import { useAuth } from "../../../AuthContext";
import NotificationModal from "../../../components/Modal/NotificationModal";

const NewCourse = () => {
    const navigate = useNavigate();
    const { accessToken, user } = useAuth();
    const [courseName, setCourseName] = useState('');
    const [courseNameError, setCourseNameError] = useState('');
    const [courseDescription, setCourseDescription] = useState('');
    const [courseDescriptionError, setCourseDescriptionError] = useState('');
    const [courseStatus, setCourseStatus] = useState('public');
    const [isNotificationModalOpen, setIsNotificationModalOpen] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState('');
    const [isTeacher, setIsTeacher] = useState(false);
    const [currentUser, setCurrentUser] = useState(user);
    const [loading, setLoading] = useState(true); // Add loading state

    useEffect(() => {
        if (!accessToken) {
            navigate('/login');
            return;
        }

        const fetchUserProfile = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BASE_API}/api/me/`, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                });
                const userProfile = response.data;
                setCurrentUser(userProfile);
                if (userProfile.role === 'teacher') {
                    setIsTeacher(true);
                } else {
                    navigate('/not-authorized'); // Redirect if not a teacher
                }
            } catch (error) {
                console.error('Error fetching user profile:', error);
                navigate('/login'); // Redirect to login on error
            } finally {
                setLoading(false); // Set loading to false after fetching user profile
            }
        };

        if (!user) {
            fetchUserProfile();
        } else {
            setCurrentUser(user);
            if (user.role === 'teacher') {
                setIsTeacher(true);
            } else {
                navigate('/not-authorized'); // Redirect if not a teacher
            }
        }
    }, [accessToken, navigate, user]);

    const handleCreateCourse = async () => {
        if (courseName === '') {
            setCourseNameError('Course title must not be empty');
            return;
        }
        if (courseDescription === '') {
            setCourseDescriptionError('Description must not be empty');
            return;
        }

        if (!currentUser) {
            console.error('User is not defined');
            setNotificationMessage('Error while creating course.');
            setIsNotificationModalOpen(true);
            return;
        }

        console.log('Current user:', currentUser); // Log currentUser to check if it is a teacher object
        // Check if currentUser is a teacher instance

        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_API}/api/course/createCourse/`, {
                m_title: courseName,
                m_description: courseDescription,
                m_status: courseStatus,
                m_course_id: currentUser.id // Assuming currentUser.id is the course ID
            }, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });

            if (response.status === 201) {
                setNotificationMessage('Course has been created successfully!');
                setIsNotificationModalOpen(true);
                
                setTimeout(() => {
                    const newCourseId = response.data.m_course_id;
                    console.log('New course ID:', newCourseId);
                    navigate(`/teacher/course/${newCourseId}`);
                }, 3000); // Redirect after 3 seconds
            } else {
                setNotificationMessage('Error while creating course.');
                setIsNotificationModalOpen(true);
            }
        } catch (error) {
            console.error('Error creating course:', error);
            setNotificationMessage('Error while creating course.');
            setIsNotificationModalOpen(true);
        }
    };


    if (!isTeacher) {
        return null; // Ensure the component does not render if the user is not a teacher
    }

    return (
        <div className="new-course-container">
            <ProfileHeader></ProfileHeader>
            <div className="newCourse-content">
                <h2 className="my-courses-title">Tạo khóa học mới</h2>
                <div className="new-course-form">
                    <div className="course-title-and-status">
                        <TextField
                            className="new-course-title-input"
                            label="Tên khóa học"
                            variant="outlined"
                            value={courseName}
                            onChange={(e) => {
                                setCourseName(e.target.value);
                                setCourseNameError('');
                            }}
                            error={courseNameError !== ''}
                            helperText={courseNameError}
                            style={{ marginRight: '3rem' }}
                        />
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={courseStatus === 'private'}
                                    onChange={(e) => setCourseStatus(e.target.checked ? 'private' : 'public')}
                                    color="primary"
                                />
                            }
                            label="Khóa học riêng tư"
                            className="course-status-switch"
                        />
                    </div>
                    <TextField
                        className="new-course-description-input"
                        label="Mô tả khóa học"
                        variant="outlined"
                        multiline
                        rows={4}
                        value={courseDescription}
                        onChange={(e) => {
                            setCourseDescription(e.target.value);
                            setCourseDescriptionError('');
                        }}
                        error={courseDescriptionError !== ''}
                        helperText={courseDescriptionError}
                        style={{ marginTop: '2rem', width: '100%' }} // Add margin and set width to 100%
                    />
                    <div className="newcourse-button-group">
                        <Button
                            className="course-toolbar-blue-save-button"
                            variant="contained"
                            style={{ marginRight: '1rem' }}
                            onClick={handleCreateCourse}
                        >
                            Save
                        </Button>
                        <Button
                            className="course-toolbar-white-cancel-button"
                            variant="outlined"
                            onClick={() => navigate('/teacher/course/')}
                        >
                            Cancel
                        </Button>
                    </div>
                </div>
            </div>
            {isNotificationModalOpen && (
                <NotificationModal
                    message={notificationMessage}
                    onClose={() => {
                        setIsNotificationModalOpen(false);
                    }}
                />
            )}
        </div>
    );
};

export default NewCourse;