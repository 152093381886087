import React, { useState } from 'react';
import { TextField, Button, IconButton } from '@mui/material';
import { Google as GoogleIcon, Facebook as FacebookIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { useDropzone } from 'react-dropzone';
import './teacher_signup.css';
import { useLocation, useNavigate } from 'react-router-dom';
import AuthHeader from '../../components/AuthHeader/authHeader';
import AWS from 'aws-sdk';
import axios from 'axios';


const s3 = new AWS.S3({
    accessKeyId: process.env["REACT_APP_AWS_ACCESS_KEY_ID"],
    secretAccessKey: process.env["REACT_APP_AWS_SECRET_ACCESS_KEY"],
    region: process.env["REACT_APP_AWS_REGION"],
});

const UploadImg = () => {
    const location = useLocation(); 
    const { email, username, password } = location.state || {};
    const [fullName, setFullName] = useState('');
    const [note, setNotes] = useState('');
    const [portraitImages, setPortraitImages] = useState([]);
    const [certificateImages, setCertificateImages] = useState([]);
    const [otherImages, setOtherImages] = useState([]);
    const [errors, setErrors] = useState({});
    const [uploading, setUploading] = useState(false);
    const navigate = useNavigate();

    const uploadToS3 = async (file) => {
        const params = {
            Bucket: process.env["REACT_APP_S3_BUCKET_NAME"],
            Key: `${Date.now()}-${file.name}`, 
            Body: file,
        };
        try {
            const data = await s3.upload(params).promise();
            return data.Location; 
        } catch (error) {
            console.error('Error uploading to S3:', error);
            throw new Error('Upload failed');
        }
    };

    const handleRegister = async () => {
        const newErrors = {};
    
        // Validate input
        if (!fullName.trim()) {
            newErrors.fullName = 'Full name is required.';
        }
    
        if (portraitImages.length === 0) {
            newErrors.portraitImages = 'Please upload at least one portrait image.';
        }
    
        if (certificateImages.length === 0) {
            newErrors.certificateImages = 'Please upload at least one certificate image.';
        }
    
        if (otherImages.length === 0) {
            newErrors.otherImages = 'Please upload at least one other image.';
        }
    
        if (!note.trim()) {
            newErrors.note = 'Notes are required.';
        }
    
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }
    
        setUploading(true);
    
        try {
            const [portraitUrls, certificateUrls, otherUrls] = await Promise.all([
                Promise.all(portraitImages.map(uploadToS3)),
                Promise.all(certificateImages.map(uploadToS3)),
                Promise.all(otherImages.map(uploadToS3)),
            ]);
    
            const teacherData = {
                username,                   
                password,                   
                email,                      
                role: "teacher",            
                full_name: fullName,        
                note,                       
                portrait_image: portraitUrls[0],    
                certificate_image: certificateUrls[0], 
                other_image: otherUrls[0],           
            };
    
            // Gửi dữ liệu lên server bằng axios
            const response = await axios.post(`${process.env["REACT_APP_BASE_API"]}/api/register/`, teacherData, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
    
            // Kiểm tra phản hồi thành công
            if (response.status === 201) {
                navigate('/verify-email', { state: { username, email } });
            } else {
                console.error('Error registering teacher:', response.data);
                setErrors({ form: 'Registration failed. Please try again.' });
            }
        } catch (error) {
            console.error('Error uploading images or registering teacher:', error.response ? error.response.data : error.message);
            setErrors({ form: 'An error occurred. Please try again.' });
        } finally {
            setUploading(false); 
        }
    };
    

    const removeImage = (index, setImages) => {
        setImages(prevImages => prevImages.filter((_, i) => i !== index));
    };

    const handleDropPortrait = (acceptedFiles) => {
        setPortraitImages([...portraitImages, ...acceptedFiles]);
        if (acceptedFiles.length > 0) {
            setErrors(prevErrors => ({ ...prevErrors, portraitImages: '' }));
        }
    };

    const handleDropCertificate = (acceptedFiles) => {
        setCertificateImages([...certificateImages, ...acceptedFiles]);
        if (acceptedFiles.length > 0) {
            setErrors(prevErrors => ({ ...prevErrors, certificateImages: '' }));
        }
    };

    const handleDropOther = (acceptedFiles) => {
        setOtherImages([...otherImages, ...acceptedFiles]);
        if (acceptedFiles.length > 0) {
            setErrors(prevErrors => ({ ...prevErrors, otherImages: '' }));
        }
    };

    const { getRootProps: getRootPropsPortrait, getInputProps: getInputPropsPortrait } = useDropzone({ onDrop: handleDropPortrait });
    const { getRootProps: getRootPropsCertificate, getInputProps: getInputPropsCertificate } = useDropzone({ onDrop: handleDropCertificate });
    const { getRootProps: getRootPropsOther, getInputProps: getInputPropsOther } = useDropzone({ onDrop: handleDropOther });

    const renderImagePreviews = (images, setImages) => {
        return images.map((file, index) => (
            <div key={index} className="image-preview">
                <img src={URL.createObjectURL(file)} alt="Preview" className="preview-image" />
                <IconButton onClick={() => removeImage(index, setImages)} aria-label="delete" size="small">
                    <DeleteIcon />
                </IconButton>
            </div>
        ));
    };


    return (
        <div className="signup-page">
            <AuthHeader />
            <div className="signup-header">
                <h1>English App</h1>
                <p>Học một ngôn ngữ mới, sống một cuộc đời mới.</p>
            </div>

            <div className="signup-container">
                <div className="signup-image">
                    <img src={require('../../assets/signup_teacher.png')} alt="Profile" />
                </div>

                <div className="form-container">
                    <div className="auth-tabs">
                        <button className="tab" onClick={() => navigate('/login')}>Log in</button>
                        <button className="tab active" onClick={() => navigate('/signup')}>Sign up</button>
                    </div>

                    <form onSubmit={(e) => e.preventDefault()}>
                        <TextField
                            label="Full name"
                            variant="outlined"
                            fullWidth
                            value={fullName}
                            onChange={(e) => setFullName(e.target.value)}
                            margin="normal"
                            error={!!errors.fullName}
                            helperText={errors.fullName}
                            required
                        />

                        <div {...getRootPropsPortrait()} className={`dropzone ${errors.portraitImages ? 'error-border' : ''}`}>
                            <input {...getInputPropsPortrait()} />
                            <p>Drop your portrait image here, or click to browse</p>
                            <div className="preview-container">
                                {renderImagePreviews(portraitImages, setPortraitImages)}
                            </div>
                        </div>
                        {errors.portraitImages && <span className="error-text">{errors.portraitImages}</span>}

                        <div {...getRootPropsCertificate()} className={`dropzone ${errors.certificateImages ? 'error-border' : ''}`}>
                            <input {...getInputPropsCertificate()} />
                            <p>Drop your certificate image here, or click to browse</p>
                            <div className="preview-container">
                                {renderImagePreviews(certificateImages, setCertificateImages)}
                            </div>
                        </div>
                        {errors.certificateImages && <span className="error-text">{errors.certificateImages}</span>}

                        <div {...getRootPropsOther()} className={`dropzone ${errors.otherImages ? 'error-border' : ''}`}>
                            <input {...getInputPropsOther()} />
                            <p>Drop your other image here, or click to browse</p>
                            <div className="preview-container">
                                {renderImagePreviews(otherImages, setOtherImages)}
                            </div>
                        </div>
                        {errors.otherImages && <span className="error-text">{errors.otherImages}</span>}

                        <TextField
                            label="Notes"
                            variant="outlined"
                            fullWidth
                            value={note}
                            onChange={(e) => setNotes(e.target.value)}
                            margin="normal"
                            multiline
                            rows={4}
                            error={!!errors.note}
                            helperText={errors.note}
                            required
                        />

                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={handleRegister}
                            className="register-button"
                            disabled={uploading} 
                        >
                            {uploading ? 'Uploading...' : 'Register'}
                        </Button>
                    </form>

                    <div className="social-login">
                        <span>Or</span>
                        <div className="social-buttons">
                            <Button
                                variant="outlined"
                                startIcon={<FacebookIcon />}
                                className="social-btn fb"
                                style={{ borderColor: '#3b5998', color: '#3b5998', margin: '0 10px' }}
                            >
                                Facebook
                            </Button>
                            <Button
                                variant="outlined"
                                startIcon={<GoogleIcon />}
                                className="social-btn google"
                                style={{ borderColor: '#db4437', color: '#db4437' }}
                            >
                                Google
                            </Button>
                        </div>
                    </div>

                    <div className="login-link">
                        Do you have an account? <a href="/" onClick={() => navigate('/login')}>Login</a>
                    </div>
                </div>
            </div>

            <footer className="signup-footer">
                @IVS Joint Stock Company
            </footer>
        </div>
    );
};

export default UploadImg;