import ProfileHeader from "../../../components/ProfileHeader/profileHeader";
import "./teacherDashboard.css";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../AuthContext";
import React, { useEffect, useState } from "react";
import axios from "axios";
import GlobalCourse from "../../../components/List/Courses/Global/GlobalCourse";

const TeacherDashBoard = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [myCourses, setMyCourses] = useState([]);
  const { accessToken } = useAuth();

  useEffect(() => {
    if (!accessToken) {
      navigate("/login");
    }

    const fetchUserProfile = async () => {
      try {
        const response = await axios.get(
          `${process.env["REACT_APP_BASE_API"]}/api/me/`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        setUser(response.data);
      } catch (error) {
        setError("Lỗi khi tải thông tin người dùng.");
      } finally {
        setLoading(false);
      }
    };

    if (accessToken) {
      fetchUserProfile();
    }
  }, [accessToken, navigate]);

  useEffect(() => {
    if (!user) return;

    const fetchCourses = async () => {
      try {
        const response = await axios.get(
          `${process.env["REACT_APP_BASE_API"]}/api/course/getAllCourses/`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
            params: {
              teacher_id: user.m_id, 
            }
          }
        );
        const courses = response.data;
        setMyCourses(courses);
      } catch (error) {
        setError("Lỗi khi tải thông tin khóa học.");
      } finally {
        setLoading(false);
      }
    };

    if (accessToken) {
      fetchCourses();
    }
  }, [accessToken, user]);

  // if (loading) return <Loading text="Loading dashboard..." />;
  if (error) return <div className="error-message">{error}</div>;

  return (
    <div className="teacher-dashboard-container">
      <ProfileHeader></ProfileHeader>
      <div className="teacher-dashboard-content">
        <GlobalCourse myCourses={myCourses}></GlobalCourse>
      </div>
    </div>
  );
};

export default TeacherDashBoard;
