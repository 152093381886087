import React from 'react';
import { Avatar } from "@mui/material";

const UserList = ({ users, onUserSelect }) => {
  return (
    <div className="user-list">
      {users.map((user) => (
        <div
          key={`${user.m_id || user.id}-${user.m_user_name || user.username}`}
          className="user-info-wrapper"
          onClick={() => onUserSelect(user)}
        >
          <div className="user-info">
            <div className="avatar-area">
              <Avatar
                alt={user.m_user_name || user.username}
                src={user.m_avatar_link || "/default-avatar.png"}
                sx={{ width: 150, height: 150 }}
              />
              <h3 className="user-fullname">{user.m_name || user.name || "--"}</h3>
            </div>
            <div className="info-area">
              <div className="info-row">
                <div className="info-item">
                  <span className="info-label">Username</span>
                  <span className="info-value">
                    {user.m_user_name || user.username}
                  </span>
                </div>
                <div className="info-item">
                  <span className="info-label">Email</span>
                  <span className="info-value">{user.m_email || user.email}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default UserList;