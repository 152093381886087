import React, { useState, useEffect } from 'react';
import { TextField, Button, IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import './verify_email.css';
import { useNavigate } from 'react-router-dom';
import AuthHeader from '../../components/AuthHeader/authHeader';
import { useLocation } from 'react-router-dom';
import NotificationModal from '../../components/Modal/NotificationModal';
import axios from 'axios';
import { useAuth } from '../../AuthContext';

const VerifyCodePage = () => {
    const [code, setCode] = useState(["", "", "", "", ""]);
    const navigate = useNavigate();
    const { state } = useLocation();
    const username = state?.username;
    const email = state?.email;
    const is_forgot_password = state?.is_forgot_password;
    const [isVerfiedSuccess, setIsVerifiedSuccess] = useState(false);
    const [isNotificationModalOpen, setIsNotificationModalOpen] = useState(false);
    const [responseMessage, setResponseMessage] = useState("");
    const [resendCooldown, setResendCooldown] = useState(0);
    const { setAccessToken } = useAuth();
    const { setRefreshToken } = useAuth();
    const [ verifyResponse, setVerifyResponse] = useState(null);

    const handleChange = (index, value) => {
        if (/^[0-9]?$/.test(value)) {
            const newCode = [...code];
            newCode[index] = value;
            setCode(newCode);

            // Move focus to the next input field
            if (value && index < 4) {
                document.getElementById(`code-input-${index + 1}`).focus();
            }
        }
    };

    const handleCloseNotificationModal = () => {
        setIsNotificationModalOpen(false);
        if (isVerfiedSuccess) {
            if(verifyResponse) {
                setAccessToken(verifyResponse.access);
                setRefreshToken(verifyResponse.refresh);
                if(is_forgot_password) {
                    navigate('/new-password')
                } else {
                    if (verifyResponse.role === 'admin') {
                        navigate('/admin/dashboard');
                    } else if (verifyResponse.role === 'teacher') {
                        navigate('/teacher/dashboard');
                    } else {
                        navigate('/login')
                    }
                }
            }
        }
    };

    const handleVerifyCode = async () => {
        console.log("Đây là username", username)
        console.log("Có phải reset password không: ", is_forgot_password)
        if (code.join('').length === 5) {
            try {
                let res = await axios.post(`${process.env["REACT_APP_BASE_API"]}/api/verify-otp/`, {
                    username, 
                    otp: code.join('')
                });
                setIsVerifiedSuccess(true);
                setVerifyResponse(res.data)
                if(!is_forgot_password) {
                    setResponseMessage(res.data.message);
                } else {
                    setResponseMessage("Xác thực OTP thành công")
                }
                setIsNotificationModalOpen(true);
            } catch (error) {
                console.error('Error verifying code:', error);
                setIsVerifiedSuccess(false);
                setResponseMessage(error.response.data.message);
                setIsNotificationModalOpen(true);
            }
        } else {
            alert("Please enter a 5-digit code.");
        }
    };

    const handleResendOtp = async () => {
        try {
            const res = await axios.post(`${process.env["REACT_APP_BASE_API"]}/api/resend-otp/`, {
                username
            });
            setResponseMessage("OTP has been resent to your email.");
            setIsNotificationModalOpen(true);
            setResendCooldown(60); // Start the cooldown timer
        } catch (error) {
            console.error('Error resending OTP:', error);
            setResponseMessage("Failed to resend OTP. Please try again.");
            setIsNotificationModalOpen(true);
        }
    };

    useEffect(() => {
        if (resendCooldown > 0) {
            const timer = setInterval(() => {
                setResendCooldown(prev => prev - 1);
            }, 1000);
            return () => clearInterval(timer);
        }
    }, [resendCooldown]);

    const maskEmail = (email) => {
        if (!email) return "";
        const [name, domain] = email.split("@");
        const maskedName = name.slice(0, 2) + "****";
        const [domainName, domainExt] = domain.split(".");
        const maskedDomain = domainName.slice(0, 2) + "......" + domainExt;
        return `${maskedName}@${maskedDomain}`;
    };

    return (
        <div className="verify-code-page">
            <AuthHeader />
            <div className="verify-code-header">
                <h1>English App</h1>
                <p>Học một ngôn ngữ mới, sống một cuộc đời mới.</p>
            </div>

            <div className="verify-code-container">
                <div className="verify-code-image">
                    <img src={require('../../assets/brain.png')} alt="Profile" />
                </div>

                <div className="verify-code-form">
                    <IconButton onClick={() => navigate(-1)} >
                        <ArrowBackIcon />
                    </IconButton>

                    <h2>Check your email</h2>
                    <p>We sent a reset link to <strong>{maskEmail(email)}</strong></p>
                    <p>Enter the 5-digit code mentioned in the email</p>

                    <div className="code-inputs">
                        {code.map((digit, index) => (
                            <TextField
                                key={index}
                                id={`code-input-${index}`}
                                value={digit}
                                onChange={(e) => handleChange(index, e.target.value)}
                                variant="outlined"
                                inputProps={{
                                    maxLength: 1,
                                    style: { textAlign: 'center', fontSize: '20px' },
                                }}
                                style={{ width: '50px', marginRight: '10px' }}
                            />
                        ))}
                    </div>

                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={handleVerifyCode}
                        className="verify-button"
                    >
                        Verify Code
                    </Button>

                    <div className="resend-link">
                        Haven't got the email yet? 
                        <a
                            href="#"
                            onClick={(e) => { e.preventDefault(); if (resendCooldown === 0) handleResendOtp(); }}
                            style={{ pointerEvents: resendCooldown > 0 ? 'none' : 'auto', color: resendCooldown > 0 ? 'gray' : 'blue' }}
                        >
                            Resend email {resendCooldown > 0 ? `(${resendCooldown}s)` : ""}
                        </a>
                    </div>
                </div>
            </div>

            <footer className="verify-code-footer">
                @IVS Joint Stock Company
            </footer>

            {isNotificationModalOpen && (
                <NotificationModal 
                message={responseMessage}
                onClose={handleCloseNotificationModal} 
                />
            )}
        </div>
    );
};

export default VerifyCodePage;