import defaultAvatar from "../../../../assets/basic_avatar.jpg";

const UserItem = ({ user, onClick, isTeacher }) => {
  return (
    <div
      className={`tsu-user-item ${isTeacher ? "clickable" : ""}`}
      onClick={isTeacher ? onClick : undefined}
    >
      <div className="tsu-user-avatar">
        <img
          src={user.m_portrait_image || user.m_avatar_link || defaultAvatar}
          alt={user.m_name}
        />
      </div>

      <div className="tsu-user-content">
        <div className="tsu-user-column tsu-column-1">
          <div className="tsu-detail-row">
            <p className="tsu-label">Name</p>
            <p className="tsu-value">{user.m_name || "---"}</p>
          </div>
          <div className="tsu-detail-row">
            <p className="tsu-label">Date of Birth</p>
            <p className="tsu-value">{user.m_birthdate || "---"}</p>
          </div>
          <div className="tsu-detail-row">
            <p className="tsu-label">Phone</p>
            <p className="tsu-value">{user.m_phone || "---"}</p>
          </div>
        </div>
        <div className="tsu-user-column tsu-column-2">
          <div className="tsu-detail-row">
            <p className="tsu-label">Email</p>
            <p className="tsu-value">{user.m_email || "---"}</p>
          </div>
          <div className="tsu-detail-row tsu-bio">
            <p className="tsu-label">Bio</p>
            <p className="tsu-value">{user.m_bio || "---"}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserItem;
