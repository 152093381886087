import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './QuizQuestionModal.css';

const QuizQuestionModal = ({ isOpen, onClose, onSave, initialData }) => {
  const [questionData, setQuestionData] = useState({
    title: '',
    m_question_text: '',
    questionType: 'MULTIPLE_CHOICE', 
    option_items: [
      { option_text: '', isCorrect: false, is_correct: false },
      { option_text: '', isCorrect: false, is_correct: false }
    ],
    m_correct_text_answer: ''
  });

  useEffect(() => {
    if (isOpen && initialData) {
      setQuestionData({
        title: initialData.title || '',
        m_question_text: initialData.m_question_text || '',
        questionType: initialData.questionType || 'MULTIPLE_CHOICE',
        option_items: initialData.option_items || [
          { option_text: '', isCorrect: false, is_correct: false },
          { option_text: '', isCorrect: false, is_correct: false }
        ],
        m_correct_text_answer: initialData.m_correct_text_answer || ''
      });
    } else if (isOpen) {
      setQuestionData({
        title: '',
        m_question_text: '',
        questionType: 'MULTIPLE_CHOICE',
        option_items: [
          { option_text: '', isCorrect: false, is_correct: false },
          { option_text: '', isCorrect: false, is_correct: false }
        ],
        m_correct_text_answer: ''
      });
    }
  }, [isOpen, initialData]);

  const handleTitleChange = (e) => {
    setQuestionData(prev => ({
      ...prev,
      title: e.target.value
    }));
  };

  const handleQuestionChange = (content) => {
    setQuestionData(prev => ({
      ...prev,
      m_question_text: content
    }));
  };

  const handleOptionChange = (index, value) => {
    const updatedOptions = [...questionData.option_items];
    updatedOptions[index].option_text = value;
    setQuestionData(prev => ({
      ...prev,
      option_items: updatedOptions
    }));
  };

  const handleCorrectAnswerChange = (index) => {
    const updatedOptions = questionData.option_items.map((option, i) => ({
      ...option,
      isCorrect: i === index,
      is_correct: i === index
    }));
    setQuestionData(prev => ({
      ...prev,
      option_items: updatedOptions
    }));
  };

  const handleCorrectAnswerTextChange = (e) => {
    setQuestionData(prev => ({
      ...prev,
      m_correct_text_answer: e.target.value
    }));
  };

  const addOption = () => {
    setQuestionData(prev => ({
      ...prev,
      option_items: [...prev.option_items, { option_text: '', isCorrect: false, is_correct: false }]
    }));
  };

  const removeOption = (index) => {
    const updatedOptions = questionData.option_items.filter((_, i) => i !== index);
    setQuestionData(prev => ({
      ...prev,
      option_items: updatedOptions
    }));
  };

  const handleQuestionTypeChange = (e) => {
    setQuestionData(prev => ({
      ...prev,
      questionType: e.target.value
    }));
  };

  const handleSave = () => {
    onSave(questionData);
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="quiz-modal-overlay">
      <div className="quiz-modal">
        <h2>Add New Question</h2>

        <div className="quiz-modal-content">
          <div className="quiz-modal-field">
            <label>Title<span className="required">*</span></label>
            <input
              type="text"
              value={questionData.title}
              onChange={handleTitleChange}
              placeholder="Enter question title"
              className="quiz-modal-title-input"
            />
          </div>

          <div className="quiz-modal-field">
            <label>Question Content</label>
            <ReactQuill 
              value={questionData.m_question_text}
              onChange={handleQuestionChange}
              modules={{
                toolbar: [
                  [{ 'header': [1, 2, false] }],
                  ['bold', 'italic', 'underline'],
                  ['image', 'code-block'],
                  [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                  ['clean']
                ]
              }}
            />
          </div>

          <div className="quiz-modal-field">
            <label>Question Type</label>
            <select
              value={questionData.questionType}
              onChange={handleQuestionTypeChange}
            >
              <option value="MULTIPLE_CHOICE">Multiple Choice</option>
              <option value="FILL_IN_THE_BLANK">Fill in the Blank</option>
            </select>
          </div>

          {questionData.questionType === 'MULTIPLE_CHOICE' && (
            <div className="quiz-modal-options">
              <label>Answer Options</label>
              {questionData.option_items.map((option, index) => (
                <div key={index} className="option-row">
                  <input
                    type="text"
                    value={option.option_text}
                    onChange={(e) => handleOptionChange(index, e.target.value)}
                    placeholder={`Option ${index + 1}`}
                  />
                  <input
                    type="radio"
                    checked={option.isCorrect}
                    onChange={() => handleCorrectAnswerChange(index)}
                    name="correctAnswer"
                  />
                  <button 
                    className="remove-option-btn"
                    onClick={() => removeOption(index)}
                  >
                    🗑️
                  </button>
                </div>
              ))}
              <button className="add-option-btn" onClick={addOption}>
                + Add Option
              </button>
            </div>
          )}

          {questionData.questionType === 'FILL_IN_THE_BLANK' && (
            <div className="quiz-modal-field">
              <label>Correct Answer Text</label>
              <input
                type="text"
                value={questionData.m_correct_text_answer}
                onChange={handleCorrectAnswerTextChange}
                placeholder="Enter correct answer"
              />
            </div>
          )}
        </div>

        <div className="quiz-modal-actions">
          <button className="cancel-btn" onClick={onClose}>
            Cancel
          </button>
          <button className="save-btn" onClick={handleSave}>
            Save Question
          </button>
        </div>
      </div>
    </div>
  );
};

export default QuizQuestionModal;