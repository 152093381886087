import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../../../../../AuthContext';

const CourseCard = ({ course, viewMode }) => {
  const navigate = useNavigate();
  const { accessToken } = useAuth();
  const [teacherName, setTeacherName] = useState('Loading...');

  useEffect(() => {
    const fetchTeacherInfo = async () => {
      try {
        const response = await axios.post(
          `${process.env["REACT_APP_BASE_API"]}/api/admin/getUserInfor/`,
          { id: course.m_teacher_id },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-Type': 'application/json',
            },
          }
        );
        if (response.data) {
          setTeacherName(response.data.m_name || response.data.m_user_name);
        }
      } catch (error) {
        setTeacherName('Unknown Teacher');
        console.error('Error fetching teacher:', error);
      }
    };

    if (course.m_teacher_id) {
      fetchTeacherInfo();
    }
  }, [course.m_teacher_id, accessToken]);

  const handleClick = () => {
    navigate(`/admin/courses/${course.m_course_id}`);
  };

  return (
    <div className={viewMode === 'list' ? 'course-list-item' : 'course-card'}>
      <div className="course-image" onClick={handleClick} style={{ cursor: 'pointer' }}>
        <img 
          src={course.m_course_image || "https://via.placeholder.com/240x130"} 
          alt={course.m_title}
        />
      </div>
      <div className="course-info">
        <h3 className="course-title" onClick={handleClick} style={{ cursor: 'pointer' }}>{course.m_title}</h3>
        <p className="course-description">{course.m_description}</p>
        <div className="course-details">
          <span className={`status-badge ${course.m_status.toLowerCase()}`}>
            <span className="status-dot"></span>
            {course.m_status === "public" ? "Public" : "Private"}
          </span>
          <span className="course-teacher">Teacher: {teacherName}</span>
          <span className="course-date">
            Created: {new Date(course.m_created_at).toLocaleDateString()}
          </span>
          <span className="course-date">
            Last updated: {new Date(course.m_updated_at).toLocaleDateString()}
          </span>
        </div>
      </div>
    </div>
  );
};

export default CourseCard;
