import React from 'react';
import './UpdateStatusModal.css';

const UpdateStatusModal = ({ isOpen, onClose, onConfirm }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="update-modal">
        <h2>Update Status</h2>
        <p>
          Your changes will be submitted for review. Your status will be changed to "Pending" 
          until an admin reviews your changes.
        </p>
        <div className="modal-buttons">
          <button className="cancel-button" onClick={onClose}>
            Cancel
          </button>
          <button className="confirm-button" onClick={onConfirm}>
            Submit for Review
          </button>
        </div>
      </div>
    </div>
  );
};

export default UpdateStatusModal;
