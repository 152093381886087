import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../../../../../AuthContext';

const CourseGridCard = ({ course }) => {
  const navigate = useNavigate();
  const { accessToken } = useAuth();
  const [teacherName, setTeacherName] = useState('Loading...');

  const handleClick = () => {
    navigate(`/admin/courses/${course.m_course_id}`);
  };

  useEffect(() => {
    const fetchTeacherInfo = async () => {
      try {
        const response = await axios.post(
          `${process.env["REACT_APP_BASE_API"]}/api/admin/getUserInfor/`,
          { id: course.m_teacher_id },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-Type': 'application/json',
            },
          }
        );
        if (response.data) {
          setTeacherName(response.data.m_name || response.data.m_user_name);
        }
      } catch (error) {
        setTeacherName('Unknown Teacher');
        console.error('Error fetching teacher:', error);
      }
    };

    if (course.m_teacher_id) {
      fetchTeacherInfo();
    }
  }, [course.m_teacher_id, accessToken]);

  return (
    <div className="course-grid-card">
      <div className="course-grid-image" onClick={handleClick} style={{ cursor: 'pointer' }}>
        <img src={course.m_course_image} alt={course.m_title} />
      </div>
      <div className="course-grid-info">
        <h3 className="course-grid-title" onClick={handleClick} style={{ cursor: 'pointer' }}>{course.m_title}</h3>
        <p className="course-grid-description">{course.m_description}</p>
        <div className="course-grid-footer">
          <div className="course-grid-status-row">
            <span className={`status-badge ${course.m_status.toLowerCase()}`}>
              <span className="status-dot"></span>
              {course.m_status === "public" ? "Public" : "Private"}
            </span>
            <span className="course-grid-teacher">{teacherName}</span>
          </div>
          <div className="course-grid-dates">
            <span className="course-date">
              Created: {new Date(course.m_created_at).toLocaleDateString()}
            </span>
            <span className="course-date">
              Last updated: {new Date(course.m_updated_at).toLocaleDateString()}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseGridCard;
