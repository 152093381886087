import ProfileHeader from "../../../components/ProfileHeader/profileHeader";
import "./searchUser.css";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../AuthContext";
import React, { useEffect, useState } from "react";
import axios from "axios";
import SearchIcon from "@mui/icons-material/Search";
import UserItem from "./components/UserItem";
import TeacherSearchDetail from "../TeacherSearchDetail/teacherSearchDetail";

const SearchUser = () => {
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const { accessToken } = useAuth();
  const [selectedUser, setSelectedUser] = useState(null);
  const [userType, setUserType] = useState("teacher");

  useEffect(() => {
    if (!accessToken) {
      navigate("/login");
    }

    const fetchLatestUsers = async () => {
      setLoading(true);
      setError(null);

      try {
        const endpoint =
          userType === "teacher"
            ? "/api/teacher/get-latest-teachers/?limit=10"
            : "/api/student/get-latest-students/?limit=10";

        const response = await axios.get(
          `${process.env["REACT_APP_BASE_API"]}${endpoint}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        setUsers(response.data);
      } catch (error) {
        setError(
          `Lỗi khi tải danh sách ${
            userType === "teacher" ? "giáo viên" : "học sinh"
          }.`
        );
      } finally {
        setLoading(false);
      }
    };

    if (accessToken) {
      fetchLatestUsers();
    }
  }, [accessToken, navigate, userType]);

  const handleSearch = async (e) => {
    if (e.key === "Enter") {
      setLoading(true);
      setError(null);

      try {
        const response = await axios.get(
          `${process.env["REACT_APP_BASE_API"]}/api/user/search-users-new/?query=${searchQuery}&type=${userType}&limit=10`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        setUsers(response.data);
      } catch (error) {
        setError(
          `Lỗi khi tìm kiếm ${
            userType === "teacher" ? "giáo viên" : "học sinh"
          }.`
        );
      } finally {
        setLoading(false);
      }
    }
  };

  const handleUserClick = (user) => {
    navigate(`/teacher/teacher-detail/${user.m_id}`, { state: { user } });
  };

  const handleRoleChange = (type) => {
    setUserType(type);
    setSearchQuery("");
  };

  return (
    <div className="tsu-container">
      <div className="tsu-header">
        <ProfileHeader />
      </div>

      {!selectedUser ? (
        <div className="tsu-content">
          <div className="tsu-sidebar">
            <button
              className={`tsu-role-btn tsu-btn-teacher ${
                userType === "teacher" ? "active" : ""
              }`}
              onClick={() => handleRoleChange("teacher")}
            >
              Giáo viên
            </button>
            <button
              className={`tsu-role-btn tsu-btn-student ${
                userType === "student" ? "active" : ""
              }`}
              onClick={() => handleRoleChange("student")}
            >
              Học sinh
            </button>
          </div>

          <div className="tsu-main-content">
            <div className="tsu-search-container">
              <div className="tsu-search-box">
                <SearchIcon className="tsu-search-icon" />
                <input
                  type="text"
                  placeholder={`Tìm kiếm ${
                    userType === "teacher" ? "giáo viên" : "học sinh"
                  }`}
                  className="tsu-search-input"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  onKeyPress={handleSearch}
                />
              </div>
            </div>

            <div className="tsu-users-list">
              {users.map((user) => (
                <UserItem
                  key={user.m_id}
                  user={user}
                  onClick={() => handleUserClick(user)}
                  isTeacher={userType === "teacher"}
                />
              ))}
            </div>
          </div>
        </div>
      ) : (
        <TeacherSearchDetail
          onBack={() => setSelectedUser(null)}
          user={selectedUser}
        />
      )}
    </div>
  );
};

export default SearchUser;
