import React, { useState } from 'react';
import { Avatar } from "@mui/material";
import InfoItem from './InfoItem';
import { getStatusStyle } from '../utils/statusHelper';

const TeacherInfo = ({ user }) => {
  const [zoomedImage, setZoomedImage] = useState(null);

  const toggleZoom = (imageSrc) => {
    setZoomedImage(zoomedImage ? null : imageSrc);
  };

  const infoItems = [
    { label: "ID User", value: user.m_id },
    { label: "Username", value: user.m_user_name },
    { label: "Birthday", value: user.m_birthdate },
    { label: "Email", value: user.m_email },
    { label: "Phone", value: user.m_phone },
    { label: "Note", value: user.m_note },
  ];

  const imageTypes = [
    { type: "portrait", label: "Portrait images" },
    { type: "certificate", label: "Certificate images" },
    { type: "other", label: "Other images" },
  ];

  return (
    <div className="teacher-info">
      <div className="user-info">
        <div className="avatar-area">
          <h2 className="role-display">Teacher</h2>
          <Avatar
            alt={user.m_user_name}
            src={user.m_avatar_link || "/default-avatar.png"}
            sx={{ width: 150, height: 150 }}
          />
          <h3 className="user-fullname">{user.m_name || "--"}</h3>
          {user.m_bio && <p className="user-bio">{user.m_bio}</p>}
        </div>
        <div className="info-area">
          <div className="info-grid">
            {infoItems.map((item, index) => (
              <InfoItem key={index} label={item.label} value={item.value} />
            ))}
            <div className="info-item">
              <span className="info-label">Acceptance Status</span>
              <span className={`status-button ${getStatusStyle(user.m_status)}`}>
                <span className="status-dot"></span>
                {user.m_status || "ACTIVE"}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="degree-information">
        <h2>Degree information</h2>
        <div className="image-sections-container">
          {imageTypes.map(({ type, label }) => (
            <div key={type} className="image-section">
              <h3>{label}</h3>
              <div className={`image-container ${type}`}>
                <img
                  src={user[`m_${type}_image`] || "/place_holder.jpg"}
                  alt={type}
                  className="image-placeholder"
                  onClick={() => toggleZoom(user[`m_${type}_image`] || "/place_holder.jpg")}
                />
              </div>
            </div>
          ))}
        </div>
      </div>

      {zoomedImage && (
        <div className="modal-overlay" onClick={() => setZoomedImage(null)}>
          <img src={zoomedImage} alt="Zoomed" className="image-modal" />
        </div>
      )}
    </div>
  );
};

export default TeacherInfo;