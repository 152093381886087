import React from 'react';
import './adminNavBar.css'

const AdminNavbar = () => (
  <div className="navbar">
    <div className="profile">
      <img src="https://cdn-icons-png.flaticon.com/512/219/219986.png" alt="Profile" />
      <span>Admin</span>
    </div>
  </div>
);

export default AdminNavbar;