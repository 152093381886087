import React, { useState, useEffect } from 'react';
import MDEditor from '@uiw/react-md-editor';
import './NewGrammarLesson.css';
import ProfileHeader from '../../../../components/ProfileHeader/profileHeader';
import { useAuth } from '../../../../AuthContext';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { useDropzone } from 'react-dropzone';
import { IconButton } from '@mui/material';
import { Delete as DeleteIcon } from '@mui/icons-material';
import AWS from 'aws-sdk';

const s3 = new AWS.S3({
    accessKeyId: process.env["REACT_APP_AWS_ACCESS_KEY_ID"],
    secretAccessKey: process.env["REACT_APP_AWS_SECRET_ACCESS_KEY"],
    region: "ap-southeast-1",
});

function NewGrammarLesson() {
  const [user, setUser] = useState(null);
  const [title, setTitle] = useState('');
  const [language, setLanguage] = useState('Anh Việt');
  const [description, setDescription] = useState('');
  const [content, setContent] = useState('');
  const [image, setImage] = useState(null);
  const [notification, setNotification] = useState({ isOpen: false, message: '' });
  const { accessToken } = useAuth();
  const { state } = useLocation();
  const navigate = useNavigate();
  const course_id = state?.course_id;
  const course_name = state?.courseName;

  const handleSave = async () => {
    let imageUrl = null;

    if (image) {
        const params = {
            Bucket: process.env["REACT_APP_S3_BUCKET_NAME"],
            Key: `${Date.now()}_${image.name}`,
            Body: image,
            ContentType: image.type,
        };

        try {
            const uploadResult = await s3.upload(params).promise();
            imageUrl = uploadResult.Location;
        } catch (error) {
            console.error("Error uploading image:", error);
            alert("Failed to upload image");
            return;
        }
    }

    const lessonData = {
        title,
        language,
        description,
        content,
        imageUrl, 
    };
    console.log("Saving lesson data:", lessonData);

    try {
        await axios.post(
            `${process.env["REACT_APP_BASE_API"]}/api/course/createGrammar/`,
            {
                m_title: title,
                m_course_id: course_id,
                m_content: description,
                m_grammar_content: content,
                m_lesson_image: imageUrl, 
            },
            {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                }
            }
        );
        setNotification({ isOpen: true, message: "New lesson created successfully" });
        console.log(imageUrl);
    } catch (error) {
        navigate('/login');
    }
  };

  const handleDrop = (acceptedFiles) => {
    setImage(acceptedFiles[0]);
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop: handleDrop });

  const removeImage = () => {
    setImage(null);
  };

  useEffect(() => {
    if (!accessToken) {
      navigate('/login');
      return;
    }
  
    const fetchUserProfile = async () => {
      try {
        const response = await axios.get(
          `${process.env["REACT_APP_BASE_API"]}/api/me/`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
  
        if (response.status === 401) {
          navigate('/login');
        }
  
        const userProfile = response.data;
        if (userProfile.role !== 'teacher') {
          navigate('/not-authorized');
        }
        setUser(userProfile);
  
      } catch (error) {
        navigate('/login');
      } 
    };
  
    fetchUserProfile();
  }, [accessToken, navigate]);

  return (
    <div className="container">
        <div className="newGrammarLesson-header">
        <ProfileHeader/>
        </div>
        
        <div className="newGrammarLesson-container">
            <div className="newGrammarLesson-sidebar">
                <button className="newGrammarLesson-button newGrammarLesson-button-just-blue-border" onClick={() => navigate(-1)}>
                    Back
                </button>
            </div>
            <div className="newGrammarLesson-main">
                <div className="newGrammarLesson-sub-header">
                    <div className="newGrammar-breadcrumb">
                        <span 
                            className="newGrammar-breadcrumb-link" 
                            onClick={() => navigate('/teacher/course')}
                        >
                            Chương trình học
                        </span>
                        <span>{'>'}</span>

                        <span 
                            className="newGrammar-breadcrumb-link" 
                            onClick={() => navigate('/teacher/course')}
                        >
                            Quản lý khóa học
                        </span>
                        <span>{'>'}</span>


                        <span 
                            className="newGrammar-breadcrumb-link" 
                            onClick={() => navigate(`/teacher/course/${course_id}`)}
                        >
                            {course_name}
                        </span>
                        <span>{'>'}</span>
                        <span>Tạo bài học mới</span>
                    </div>
                    <div className="newGrammarLesson-actions">
                        <button 
                            className="newGrammarLesson-button newGrammarLesson-button-just-purple-inside" 
                            onClick={handleSave}
                        >
                            Lưu
                        </button>
                        <button 
                            className="newGrammarLesson-button newGrammarLesson-button-just-purple-border" 
                            onClick={() => navigate(-1)}
                        >
                            Hủy
                        </button>
                    </div>
                </div>
                <div className="newGrammarLesson-form-group">
                    <div {...getRootProps()} className="dropzone" style={{width:"250px", height:"250px"}}>
                        <input {...getInputProps()} />
                        <p>Drop your image here, or click to browse</p>
                        {image && (
                            <div className="image-preview">
                                <img src={URL.createObjectURL(image)} alt="Preview" className="preview-image" />
                                <IconButton onClick={removeImage} aria-label="delete" size="small">
                                    <DeleteIcon />
                                </IconButton>
                            </div>
                        )}
                    </div>
                </div>
                <div className="newGrammarLesson-form-group">
                    <label htmlFor="title" className="newGrammarLesson-label">Tiêu đề</label>
                    <input
                        type="text"
                        id="title"
                        placeholder="Tên tiêu đề"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        className="newGrammarLesson-input"
                    />
                </div>
                <div className="newGrammarLesson-form-group">
                    <label htmlFor="description" className="newGrammarLesson-label">Mô tả</label>
                    <textarea
                        id="description"
                        placeholder="Mô tả bài học"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        className="newGrammarLesson-textarea"
                    />
                </div>
                <h3 className="newGrammarLesson-content-title">Nội dung bài học</h3>
                <div className="newGrammarLesson-editor">
                    <MDEditor
                        value={content}
                        onChange={setContent}
                        height={400}
                        preview="edit"
                    />
                </div>
            </div>
        </div>
        {notification.isOpen && (
            <div className="grammarLesson-dialog-overlay">
                <div className="gl-dialog" style={{ width: '500px', height: '140px', padding: '20px' }}>
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '25px' }}>
                        <h2>{notification.message}</h2>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '25px' }}>
                        <button
                            className="grammarLesson-button-just-purple-border"
                            onClick={() => {
                                setNotification({ isOpen: false, message: '' });
                                navigate(-1);
                            }}
                        >
                            Close
                        </button>
                    </div>
                </div>
            </div>
        )}
    </div>
  );
}

export default NewGrammarLesson;