import React from 'react';
import './NotificationModal.css'

const NotificationModal = ({ message, onClose }) => {
  return (
    <div className="modal">
      <div className="modal-content">
        <p>{message}</p>
        <button className="btn-confirm" onClick={onClose}>Close</button>
      </div>
    </div>
  );
};

export default NotificationModal;