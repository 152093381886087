import React from 'react';
import './NotificationModal.css'

const ConfirmModal = ({ message, onConfirm, onCancel }) => {
    return (
      <div className="modal">
        <div className="modal-content">
          <p>{message}</p>
          <button className="btn-confirm" onClick={onConfirm}>Yes</button>
          <button className="btn-cancel" onClick={onCancel}>No</button>
        </div>
      </div>
    );
  };

export default ConfirmModal;