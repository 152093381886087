import ProfileHeader from "../../../components/ProfileHeader/profileHeader";
import { useNavigate, useParams } from "react-router-dom";
import "./currentCourse.css";
import "./course.css";
import { useState, useEffect } from "react";
import axios from "axios";
import { useAuth } from "../../../AuthContext";
import SuccessModal from "../../../components/SuccessModal"; 
import CourseSidebar from "./components/Sidebars/CourseSidebar";
import CourseContents from "./components/CourseContent/CourseContents";
import { EditForm } from "./components/EditForm";

const CourseInfo = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [course, setCourse] = useState(null);
  const { accessToken } = useAuth();
  const [myLessons, setLessons] = useState([]);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);
  const [editCourseTitle, setEditCourseTitle] = useState("");
  const [editCourseDescription, setEditCourseDescription] = useState("");
  const [activeTab, setActiveTab] = useState("overview");
  const [courseName, setCourseName] = useState('');
  const [isPublished, setIsPublished] = useState(false);

  useEffect(() => {
    if (!accessToken) {
      navigate("/login");
      return;
    }

    const fetchUserProfile = async () => {
      try {
        const response = await axios.get(
          `${process.env["REACT_APP_BASE_API"]}/api/me/`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        setUser(response.data);
      } catch (error) {
        setError("Lỗi khi tải thông tin người dùng.");
      } finally {
        setLoading(false);
      }
    };

    fetchUserProfile();
  }, [accessToken, navigate]);

  useEffect(() => {
    if (!user || !user.m_id) return;

    const fetchCourseAndLessons = async () => {
      setLoading(true);
      try {
        const courseResponse = await axios.get(
          `${process.env["REACT_APP_BASE_API"]}/api/course/getCourse/`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
            params: {
              m_course_id: id,
            },
          }
        );
        setCourse(courseResponse.data);
        setCourseName(courseResponse.data.m_title);
        setIsPublished(courseResponse.data.m_status === "public");
        console.log(courseName);

        const lessonsResponse = await axios.get(
          `${process.env["REACT_APP_BASE_API"]}/api/course/getAllLessons/`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
            params: {
              course_id: id,
            },
          }
        );
        setLessons(lessonsResponse.data);
      } catch (error) {
        console.log("Lỗi: ", error);
        setError("Lỗi khi tải thông tin khóa học hoặc bài học.");
      } finally {
        setLoading(false);
      }
    };

    fetchCourseAndLessons();
  }, [accessToken, user, id, courseName]);


  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const tab = urlParams.get('tab');
    if (tab) {
      setActiveTab(tab);
    }
  }, []);

  const handleEditCourse = async () => {
    try {
      await axios.put(
        `${process.env.REACT_APP_BASE_API}/api/course/updateCourse/`,
        {
          m_course_id: id,
          m_title: editCourseTitle,
          m_description: editCourseDescription,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      setShowEditForm(false);
      setShowSuccessModal(true);
    } catch (error) {
      setError("Lỗi khi chỉnh sửa khóa học.");
    }
  };

  useEffect(() => {
    if (course) {
      setEditCourseTitle(course.m_title);
      setEditCourseDescription(course.m_description);
    }
  }, [course]);

  const handlePublishCourse = async () => {
    try {
      await axios.put(
        `${process.env["REACT_APP_BASE_API"]}/api/course/updateCourse/`,
        {
          m_course_id: id,
          m_status: "public",
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      setIsPublished(true);
      setShowSuccessModal(true);
    } catch (error) {
      setError("Lỗi khi publish khóa học.");
    }
  };

  if (error) return <p>{error}</p>;

  return (
    <div className="course-dashboard-container">
      <ProfileHeader />
      <div className="course-content-wrapper">
        <CourseSidebar activeTab={activeTab} setActiveTab={setActiveTab} />
        <div className="course-main-content">
          {activeTab === "overview" && (
            <>
              <div className="course-breadcrumb">
                <div className="breadcrumb-path">
                  <span>Chương trình học</span>
                  <span> / </span>
                  <span>Quản lý khóa học</span>
                  <span> / </span>
                  <span>{course?.m_title || "IELTS 6.0"}</span>
                </div>
                <div className="breadcrumb-actions">
                  <button
                    className="edit-button"
                    onClick={() => setShowEditForm(true)}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-pencil"><path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5Z"/><path d="M2 22l2-2 6-6"/><path d="M7.5 20.5 2 22l1.5-5.5"/></svg>
                  </button>
                </div>
              </div>

              <div className="course-header">
                <img
                  src={
                    course?.m_image ||
                    "https://photographylife.com/wp-content/uploads/2023/05/Nikon-Z8-Official-Samples-00002.jpg"
                  }
                  alt="Course cover"
                  className="course-cover-image"
                />
                <div className="course-info-wrapper">
                  <h1 className="course-title">
                    {course?.m_title || "IELTS 6.0"}
                  </h1>
                  <p className="course-description">
                    {course?.m_description || "Course description"}
                  </p>
                  <button 
                    className="publish-button" 
                    onClick={handlePublishCourse}
                    disabled={isPublished}
                  >
                    {isPublished ? "Course Published" : "Publish Course"}
                  </button>
                </div>
              </div>
            </>
          )}
          {activeTab === "contents" && (
            <>
              {course && myLessons && (
                <CourseContents 
                  lessons={myLessons} 
                  course={course} 
                />
              )}
              {(!myLessons || myLessons.length === 0) && (
                <p style={{ color: "grey" , paddingLeft: "20px"}}>No lessons here yet...</p>
              )}
            </>
          )}
          {activeTab === "settings" && <div>Settings</div>}
        </div>
      </div>
      <EditForm
        show={showEditForm}
        onClose={() => setShowEditForm(false)}
        onSave={handleEditCourse}
        title={editCourseTitle}
        description={editCourseDescription}
        setTitle={setEditCourseTitle}
        setDescription={setEditCourseDescription}
      />
      {showSuccessModal && (
        <SuccessModal
          onClose={() => {
            setShowSuccessModal(false);
            window.location.reload();
          }}
          message="Course has been updated successfully."
        />
      )}
    </div>
  );
};

export default CourseInfo;
