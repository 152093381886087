import React, { useState } from 'react';
import { Button } from '@mui/material';
import './sign_up.css'; 
import { useNavigate } from 'react-router-dom';
import AuthHeader from '../../components/AuthHeader/authHeader';
import AppDownloadPopup from '../../components/AppDownloadPopup/AppDownloadPopup';

const SignUpPage = () => {
    const [selectedRole, setSelectedRole] = useState(null);
    const [isAppDownloadPopupOpen, setIsAppDownloadPopupOpen] = useState(false);
    const navigate = useNavigate();

    const handleRegister = () => {
        if (selectedRole) {
            if (selectedRole === 'Teacher') {
                navigate('/teacher-signup');
            } else if (selectedRole === 'Student') {
                navigate('/student-signup');
            }
        } else {
            alert('Please select a role.');
        }
    };

  const handleRoleSelect = (role) => {
    setSelectedRole(role);
  };

  return (
    <div className="signup-page">
      <AuthHeader />
      <div className="signup-header">
        <h1>English App</h1>
        <p>Học một ngôn ngữ mới, sống một cuộc đời mới.</p>
      </div>

      <div className="signup-container">
        <div className="signup-image">
          <img src={require("../../assets/logo_sign_up.png")} alt="Brain" />
        </div>

        <div className="signup-form">
          <div className="auth-tabs">
            <button className="tab" onClick={() => navigate("/login")}>
              Log in
            </button>
            <button className="tab active" onClick={() => navigate("/signup")}>
              Sign up
            </button>
          </div>

          <div className="role-selection">
            <div
              className={`role-card ${
                selectedRole === "Teacher" ? "selected" : ""
              }`}
              onClick={() => handleRoleSelect("Teacher")}
            >
              <img
                src={require("../../assets/Icon_Teacher.png")}
                alt="Teacher"
              />
              <p>Teacher</p>
            </div>

            <div
              className={`role-card ${
                selectedRole === "Student" ? "selected" : ""
              }`}
              onClick={() => handleRoleSelect("Student")}
            >
              <img
                src={require("../../assets/Icon_Student.png")}
                alt="Student"
              />
              <p>Student</p>
            </div>
          </div>

          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleRegister}
            className="register-button"
          >
            Register
          </Button>

          <div className="login-link">
            Do you have an account?{" "}
            <div onClick={() => navigate("/login")}>
              Login
            </div>
          </div>
        </div>
      </div>

            <footer className="signup-footer">
                @IVS Joint Stock Company
            </footer>

            <AppDownloadPopup 
                open={isAppDownloadPopupOpen}
                onClose={() => {
                    setIsAppDownloadPopupOpen(false);
                    setSelectedRole(null);
                }}
            />
        </div>
    );
};

export default SignUpPage;
