import React, { useState, useEffect } from "react";
import axios from "axios";
import { useAuth } from "../../../../AuthContext";
import "./VocabularyLesson.css";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import ProfileHeader from "../../../../components/ProfileHeader/profileHeader";
import VocabularyHeader from "./components/VocabularyHeader/VocabularyHeader";
import defaultBackground from "../../../../assets/bg_teacher_detail.jpg";
import ConfirmModal from "../components/ConfirmModal/ConfirmModal";
import VocabularyItem from "./components/VocabularyItem/VocabularyItem";
import VocabularyModal from "./components/VocabularyModal/VocabularyModal";
import EditLessonModal from "./components/EditLessonModal/EditLessonModal";

const VocabularyLesson = () => {
  const [lesson, setLesson] = useState(null);
  const [vocabularyItems, setVocabularyItems] = useState([]);

  const [deletedVocabularyIds, setDeletedVocabularyIds] = useState([]);
  const { accessToken } = useAuth();
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const courseTitle = location.state?.courseTitle;
  const [isEditing, setIsEditing] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [newVocabulary, setNewVocabulary] = useState({
    m_english_word: "",
    m_definition: "",
    m_transcription: "",
    m_example_1: "",
    m_example_2: "",
    m_vocab_image: "",
  });
  const [formErrors, setFormErrors] = useState({
    m_english_word: false,
    m_definition: false,
    m_transcription: false,
  });
  const [isEditLessonModalOpen, setIsEditLessonModalOpen] = useState(false);
  const [editedLesson, setEditedLesson] = useState({
    title: "",
    description: "",
    image_url: "",
  });
  const [lessonFormErrors, setLessonFormErrors] = useState({
    title: false,
    description: false,
  });
  const [isEditingVocab, setIsEditingVocab] = useState(false);
  const [confirmModal, setConfirmModal] = useState({
    isOpen: false,
    title: "",
    message: "",
    onConfirm: null,
  });
  const [isPublished, setIsPublished] = useState(false);

  useEffect(() => {
    const fetchVocabularyLesson = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_API}/api/course/getVocabularyLesson/?lesson_id=${id}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        setLesson(response.data.lesson);
        setVocabularyItems(response.data.vocabulary_items);
        setIsPublished(response.data.lesson.m_status === "public");
      } catch (error) {
        console.error("Error fetching vocabulary lesson:", error);
      }
    };

    fetchVocabularyLesson();
  }, [id, accessToken]);

  const openConfirmModal = (title, message, onConfirm) => {
    setConfirmModal({
      isOpen: true,
      title,
      message,
      onConfirm,
    });
  };

  const closeConfirmModal = () => {
    setConfirmModal({
      isOpen: false,
      title: "",
      message: "",
      onConfirm: null,
    });
  };

  const handleDeleteLesson = () => {
    openConfirmModal(
      "Xóa bộ từ vựng?",
      "Toàn bộ từ vựng sẽ được xóa",
      async () => {
        try {
          await axios.delete(
            `${process.env.REACT_APP_BASE_API}/api/course/deleteLesson/`,
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
              data: {
                m_lesson_id: id,
              },
            }
          );
          //   alert("Xóa bài học thành công");
          navigate(`/teacher/course/${lesson.m_course_id}`);
          closeConfirmModal();
        } catch (error) {
          //   alert("Lỗi khi xóa bài học");
        }
      }
    );
  };

  const handleSave = async () => {
    openConfirmModal(
      "Lưu bộ từ vựng",
      "Thông tin bộ từ vựng sẽ được lưu lại",
      async () => {
        try {
          const updatedVocabulary = vocabularyItems.map((item) => ({
            m_id: item.m_id || null,
            m_english_word: item.m_english_word,
            m_definition: item.m_definition,
            m_example_1: item.m_example_1,
            m_example_2: item.m_example_2,
            m_transcription: item.m_transcription,
            m_note: item.m_note,
            m_vocab_image: item.m_vocab_image,
          }));

          await axios.put(
            `${process.env.REACT_APP_BASE_API}/api/course/updateVocabulary/`,
            {
              m_lesson_id: id,
              vocabulary_items: updatedVocabulary,
              delete_vocab_ids: deletedVocabularyIds,
            },
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            }
          );

          setDeletedVocabularyIds([]);
          //   alert("Cập nhật từ vựng thành công!");
          setIsEditing(false);
          closeConfirmModal();
        } catch (error) {
          console.error("Error saving vocabulary:", error);
          //   alert("Cập nhật thất bại!");
        }
      }
    );
  };

  const handleAddVocabulary = () => {
    setIsAddModalOpen(true);
  };

  const handleDeleteVocabulary = (index) => {
    openConfirmModal(
      "Xóa từ vựng?",
      "Từ vựng này sẽ được xóa khỏi bộ từ",
      () => {
        const itemToDelete = vocabularyItems[index];
        if (itemToDelete.m_id) {
          setDeletedVocabularyIds([...deletedVocabularyIds, itemToDelete.m_id]);
        }
        setVocabularyItems(vocabularyItems.filter((_, i) => i !== index));
        closeConfirmModal();
      }
    );
  };

  const handleEditVocabulary = () => {
    setIsEditing(true);
  };

  const handleInputChange = (field, value) => {
    setNewVocabulary((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleSaveNewVocabulary = () => {
    const errors = {
      m_english_word: !newVocabulary.m_english_word,
      m_definition: !newVocabulary.m_definition,
      m_transcription: !newVocabulary.m_transcription,
    };

    setFormErrors(errors);

    if (Object.values(errors).some((error) => error)) {
      return;
    }

    if (isEditingVocab) {
      setVocabularyItems((prev) =>
        prev.map((item) =>
          item.m_id === newVocabulary.m_id
            ? {
                ...item,
                ...newVocabulary,
                m_vocab_image: newVocabulary.m_vocab_image,
              }
            : item
        )
      );
    } else {
      setVocabularyItems((prev) => [
        ...prev,
        {
          ...newVocabulary,
          m_id: null,
          m_vocab_image: newVocabulary.m_vocab_image,
        },
      ]);
    }

    setNewVocabulary({
      m_english_word: "",
      m_definition: "",
      m_transcription: "",
      m_example_1: "",
      m_example_2: "",
      m_vocab_image: "",
    });
    setFormErrors({
      m_english_word: false,
      m_definition: false,
      m_transcription: false,
    });
    setIsEditingVocab(false);
    setIsAddModalOpen(false);
  };

  const handleMoveItem = (index, direction) => {
    const newItems = [...vocabularyItems];
    if (direction === "up" && index > 0) {
      // Swap with previous item
      [newItems[index], newItems[index - 1]] = [
        newItems[index - 1],
        newItems[index],
      ];
      setVocabularyItems(newItems);
    } else if (direction === "down" && index < vocabularyItems.length - 1) {
      // Swap with next item
      [newItems[index], newItems[index + 1]] = [
        newItems[index + 1],
        newItems[index],
      ];
      setVocabularyItems(newItems);
    }
  };

  const handleEditLesson = () => {
    setEditedLesson({
      title: lesson.m_title,
      description: lesson.m_content,
      image_url: lesson.m_lesson_image || "",
    });
    setIsEditLessonModalOpen(true);
  };

  const handleLessonInputChange = (field, value) => {
    setEditedLesson((prev) => ({
      ...prev,
      [field]: value,
    }));
    setLessonFormErrors((prev) => ({
      ...prev,
      [field]: false,
    }));
  };

  const handleSaveLesson = async () => {
    const errors = {
      title: !editedLesson.title,
      description: !editedLesson.description,
    };

    setLessonFormErrors(errors);

    if (Object.values(errors).some((error) => error)) {
      return;
    }

    try {
      await axios.put(
        `${process.env.REACT_APP_BASE_API}/api/course/updateLesson/`,
        {
          m_lesson_id: id,
          m_title: editedLesson.title,
          m_content: editedLesson.description,
          m_lesson_image: editedLesson.image_url,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      setLesson((prev) => ({
        ...prev,
        m_title: editedLesson.title,
        m_content: editedLesson.description,
        m_lesson_image: editedLesson.image_url,
      }));

      setIsEditLessonModalOpen(false);
      //   alert("Cập nhật bài học thành công!");
    } catch (error) {
      console.error("Error updating lesson:", error);
      //   alert("Cập nhật thất bại!");
    }
  };

  const handleEditVocabItem = (item) => {
    setNewVocabulary({
      m_id: item.m_id,
      m_english_word: item.m_english_word,
      m_definition: item.m_definition,
      m_transcription: item.m_transcription,
      m_example_1: item.m_example_1,
      m_example_2: item.m_example_2,
      m_vocab_image: item.m_vocab_image || "",
    });
    setIsEditingVocab(true);
    setIsAddModalOpen(true);
  };

  const handleBack = () => {
    if (isEditing) {
      setIsEditing(false);
    } else {
      navigate(`/teacher/course/${lesson.m_course_id}?tab=contents`);
    }
  };

  const handlePublishLesson = async () => {
    try {
      await axios.put(
        `${process.env.REACT_APP_BASE_API}/api/course/updateLesson/`,
        {
          m_lesson_id: id,
          m_status: "public",
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      setIsPublished(true);
      openConfirmModal("Thành công", "Bài học đã được công khai", () =>
        closeConfirmModal()
      );
    } catch (error) {
      console.error("Error publishing lesson:", error);
      openConfirmModal("Lỗi", "Không thể công khai bài học", () =>
        closeConfirmModal()
      );
    }
  };

  return (
    <>
      <div className="vocabulary-lesson-header-newlesson">
        <ProfileHeader />
      </div>

      <div className="vocabulary-lesson-wrapper">
        <div className="vocabulary-lesson-navigation">
          <button className="vocabulary-back-btn" onClick={handleBack}>
            BACK
          </button>
        </div>

        <div className="vocabulary-lesson-container">
          {lesson && (
            <>
              <div className="vocabulary-header-newlesson">
                <VocabularyHeader
                  lesson={lesson}
                  courseTitle={courseTitle}
                  isEditing={isEditing}
                  onEditLesson={handleEditLesson}
                  onDeleteLesson={handleDeleteLesson}
                />
              </div>

              {!isEditing && (
                <>
                  <img
                    src={lesson.m_lesson_image || defaultBackground}
                    alt="Lesson background"
                    className="vocabulary-lesson-background"
                  />
                </>
              )}

              <h1 className="vocabulary-lesson-title">{lesson.m_title}</h1>

              {!isEditing && (
                <>
                  <h3>Descriptions</h3>
                  <p className="vocabulary-lesson-content">
                    {lesson.m_content}
                  </p>
                </>
              )}

              {!isEditing && (
                <>
                  <div className="vocabulary-action-buttons">
                    <button
                      className="vocabulary-publish-button"
                      onClick={handlePublishLesson}
                      disabled={isPublished}
                    >
                      {isPublished ? "Lesson Published" : "Publish Lesson"}
                    </button>
                    <button
                      className="vocabulary-edit-vocabulary-button"
                      onClick={handleEditVocabulary}
                    >
                      Edit vocabulary
                    </button>
                  </div>
                  <div className="vocabulary-items">
                    {vocabularyItems.map((item, index) => (
                      <VocabularyItem
                        key={index}
                        item={item}
                        index={index}
                        isEditing={isEditing}
                        totalItems={vocabularyItems.length}
                        onMoveItem={handleMoveItem}
                        onEdit={handleEditVocabItem}
                        onDelete={handleDeleteVocabulary}
                      />
                    ))}
                  </div>
                </>
              )}

              {isEditing && (
                <div className="vocabulary-edit-container">
                  <div className="vocabulary-edit-header">
                    <h2>Danh sách từ</h2>
                    <div className="vocabulary-edit-actions">
                      <button
                        className="vocabulary-edit-save"
                        onClick={handleSave}
                      >
                        Save
                      </button>
                      <button
                        className="vocabulary-edit-add"
                        onClick={handleAddVocabulary}
                      >
                        + Thêm từ vựng
                      </button>
                    </div>
                  </div>

                  <div className="vocabulary-items">
                    {vocabularyItems.map((item, index) => (
                      <VocabularyItem
                        key={index}
                        item={item}
                        index={index}
                        isEditing={isEditing}
                        totalItems={vocabularyItems.length}
                        onMoveItem={handleMoveItem}
                        onEdit={handleEditVocabItem}
                        onDelete={handleDeleteVocabulary}
                      />
                    ))}
                  </div>
                </div>
              )}

              <VocabularyModal
                isOpen={isAddModalOpen}
                title={isEditingVocab ? "Chỉnh sửa từ vựng" : "Thêm từ mới"}
                vocabulary={newVocabulary}
                formErrors={formErrors}
                isEditing={isEditingVocab}
                onClose={() => {
                  setIsAddModalOpen(false);
                  setIsEditingVocab(false);
                  setFormErrors({
                    m_english_word: false,
                    m_definition: false,
                    m_transcription: false,
                  });
                  setNewVocabulary({
                    m_english_word: "",
                    m_definition: "",
                    m_transcription: "",
                    m_example_1: "",
                    m_example_2: "",
                    m_vocab_image: "",
                  });
                }}
                onSave={handleSaveNewVocabulary}
                onInputChange={(field, value) => {
                  handleInputChange(field, value);
                  if (formErrors[field]) {
                    setFormErrors((prev) => ({
                      ...prev,
                      [field]: false,
                    }));
                  }
                }}
              />

              <EditLessonModal
                isOpen={isEditLessonModalOpen}
                lesson={editedLesson}
                formErrors={lessonFormErrors}
                onClose={() => {
                  setIsEditLessonModalOpen(false);
                  setLessonFormErrors({
                    title: false,
                    description: false,
                  });
                }}
                onSave={handleSaveLesson}
                onInputChange={handleLessonInputChange}
              />

              <ConfirmModal
                isOpen={confirmModal.isOpen}
                onClose={closeConfirmModal}
                onConfirm={confirmModal.onConfirm}
                title={confirmModal.title}
                message={confirmModal.message}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default VocabularyLesson;
