import React from 'react';
import defaultBg from "../../../../assets/bg_teacher_detail.jpg";
import iconHeart from "../../../../assets/icon_heart.svg";
import iconChat from "../../../../assets/icon_chat.svg";

const CourseCard = ({ title, time, views, comments, image }) => {
  return (
    <div className="tsud-course">
      <div className="tsud-course-card">
        <img src={image || defaultBg} alt="Course" />
      </div>
      <h4 className="tsud-course-title">
        {title || "IELTS CƠ BẢN CHO NGƯỜI ĐI LÀM"}
      </h4>
      <div className="tsud-course-stats">
        <p className="tsud-course-time">{time || "3 months ago"}</p>
        <div className="tsud-stats">
          <span>
            <img src={iconHeart} alt="Views" className="tsud-stat-icon" />
            {views || "1,498"}
          </span>
          <span>
            <img src={iconChat} alt="Comments" className="tsud-stat-icon" />
            {comments || "3,000"}
          </span>
        </div>
      </div>
    </div>
  );
};

export default CourseCard;