// components/Dashboard.js
import React from "react";
import Card from "./components/card";
import Chart from "./components/chart";
import AdminNavbar from "../../components/Navbar/adminNavBar";
import Sidebar from "../../components/Sidebar/adminSidebar";
import "./adminGeneralDashboard.css";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useAuth } from "../../../../AuthContext";

const AdminGeneralDashboard = () => {
  const { accessToken } = useAuth(); // Lấy accessToken từ context
  const navigate = useNavigate(); // Sử dụng useNavigate để điều hướng
  useEffect(() => {
    // Kiểm tra xem người dùng đã đăng nhập chưa
    if (!accessToken) {
      navigate("/login"); // Điều hướng đến trang đăng nhập nếu chưa đăng nhập
    }
  }, [accessToken, navigate]);
  return (
    <div className="dashboard">
      <Sidebar></Sidebar>
      <AdminNavbar></AdminNavbar>
      <div className="container-general-dashboard">
        <div className="stats stats-container">
          <Card
            title="Users"
            count="40,689"
            icon="https://cdn-icons-png.flaticon.com/512/9187/9187604.png"
          />
          <Card
            title="Students"
            count="10,293"
            icon="https://cdn-icons-png.flaticon.com/512/354/354637.png"
          />
          <Card
            title="Teachers"
            count="89,000"
            icon="https://cdn-icons-png.flaticon.com/512/8065/8065351.png"
          />
          <Card
            title="Teachers Pending"
            count="2,040"
            icon="https://cdn-icons-png.freepik.com/512/206/206897.png"
          />
        </div>
        <div className="chart">
          <Chart />
        </div>
      </div>
    </div>
  );
};

export default AdminGeneralDashboard;
