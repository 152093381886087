import React from 'react';

const ViewModeSelect = ({ viewMode, setViewMode }) => {
  return (
    <div className="view-mode-container">
      <select
        value={viewMode}
        onChange={(e) => setViewMode(e.target.value)}
        className="view-mode-select"
      >
        <option value="list">List View</option>
        <option value="grid">Grid View</option>
      </select>
    </div>
  );
};

export default ViewModeSelect;