import AuthHeader from '../../components/AuthHeader/authHeader';
import React, { useState, useEffect } from 'react';
import { TextField, Button, IconButton, InputAdornment } from '@mui/material';
import { Google as GoogleIcon, Facebook as FacebookIcon, Visibility, VisibilityOff } from '@mui/icons-material';
import brainImage from '../../assets/brain.png';
import './login.css';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../AuthContext';
import axios from 'axios'; 
import NotificationModal from '../../components/Modal/NotificationModal';
import AppDownloadPopup from '../../components/AppDownloadPopup/AppDownloadPopup';
    

const LoginPage = () => {
    const navigate = useNavigate(); 
    const accessToken = localStorage.getItem('accessToken'); // Lấy accessToken từ localStorage hoặc nguồn khác
    const [isNotificationModalOpen, setIsNotificationModalOpen] = useState(false);
    const [isAppDownloadPopupOpen, setIsAppDownloadPopupOpen] = useState(false);
    
    const handleCloseNotificationModal = () => {
        setIsNotificationModalOpen(false);
    }
    
    // useEffect(() => {
    //     if (accessToken) {
    //         // Gọi API /me để lấy thông tin role
    //         axios.get(`${process.env["REACT_APP_BASE_API"]}/api/me/`, {
    //             headers: {
    //                 Authorization: `Bearer ${accessToken}`
    //             }
    //         })
    //         .then(response => {
    //             if (response.data.role === 'admin') {
    //                 navigate('/admin/dashboard');
    //             } else if (response.data.role === 'teacher' || response.data.role === 'student') {
    //                 navigate('/teacher/dashboard');
    //             }
    //         })
    //         .catch(error => {
    //             console.error('Error fetching user data:', error);
    //         });
    //     }
    // }, [accessToken, navigate]);

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const { setAccessToken } = useAuth();
    const { setRefreshToken } = useAuth();

    const handleLogin = async () => {
        let valid = true;

        if (!password) {
            setPasswordError('Password is required.');
            valid = false;
        } else if (!email) {
            valid = false;
        } else {
            setEmailError('')
            setPasswordError('');
        }

        if (valid) {
            try {
                const response = await axios.post(`${process.env["REACT_APP_BASE_API"]}/api/login/`, {
                    username_or_email: email,
                    password: password
                });
                
                // Check if user is a student
                if (response.data.role === 'student') {
                    setIsAppDownloadPopupOpen(true);
                    return;
                }
                
                setAccessToken(response.data.access);
                setRefreshToken(response.data.refresh);
                if (response.data.role === 'admin') {
                    navigate('/admin/dashboard');
                } else if (response.data.role === 'teacher') {
                    navigate('/teacher/dashboard');
                }
            } catch (error) {
                console.error('Login failed:', error);
                setIsNotificationModalOpen(true);
            }
        }
    };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <div className="login-page">
      <AuthHeader />
      <div className="login-header">
        <h1>English App</h1>
        <p>Học một ngôn ngữ mới, sống một cuộc đời mới.</p>
      </div>

      <div className="login-container">
        <div className="login-image">
          <img src={brainImage} alt="Brain" />
        </div>

        <div className="login-form">
          <div className="auth-tabs">
            <button className="tab active" onClick={() => navigate("/login")}>
              Log in
            </button>
            <button className="tab" onClick={() => navigate("/signup")}>
              Sign up
            </button>
          </div>

          <form onSubmit={(e) => e.preventDefault()}>
            <div className="form-group">
              <TextField
                label="Email/username"
                variant="outlined"
                fullWidth
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                margin="normal"
                required
                error={!!emailError}
                helperText={emailError}
              />
            </div>

            <div className="form-group">
              <TextField
                required
                label="Password"
                variant="outlined"
                fullWidth
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                margin="normal"
                error={!!passwordError}
                helperText={passwordError}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>

            <div className="forgot-password">
              <a href="/enter-email">Forgot password?</a>
            </div>

            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleLogin}
              className="login-button"
            >
              Login
            </Button>
          </form>

          <div className="social-login">
            <div className="social-buttons">
              <Button
                variant="outlined"
                startIcon={<FacebookIcon />}
                className="social-btn fb"
                style={{ borderColor: "#3b5998", color: "#3b5998" }}
              >
                Facebook
              </Button>
              <Button
                variant="outlined"
                startIcon={<GoogleIcon />}
                className="social-btn google"
                style={{ borderColor: "#db4437", color: "#db4437" }}
              >
                Google
              </Button>
            </div>
          </div>

          <div className="signup-link">
            Don't have an account?{" "}
            <div onClick={() => navigate("/signup")}>
              Sign Up
            </div>
          </div>
        </div>
      </div>

      <footer className="login-footer">@IVS Joint Stock Company</footer>

            {isNotificationModalOpen && (
                <NotificationModal 
                message="Thông tin đăng nhập không chính xác!" 
                onClose={handleCloseNotificationModal} 
                />
            )}

            <AppDownloadPopup 
                open={isAppDownloadPopupOpen}
                onClose={() => setIsAppDownloadPopupOpen(false)}
            />
        </div>
    );
};

export default LoginPage;
