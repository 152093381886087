import React from "react";
import ConfirmModal from "../../../../../components/Modal/ConfirmModal";
import NotificationModal from "../../../../../components/Modal/NotificationModal";
import RejectModal from "../../../../../components/Modal/RejectModal";

const ActionModals = ({
  isConfirmModalOpen,
  isNotificationModalOpen,
  isRejectModalOpen,
  onConfirmAccept,
  onCloseConfirm,
  onCloseNotification,
  onConfirmReject,
  onCloseReject
}) => {
  return (
    <>
      {isConfirmModalOpen && (
        <ConfirmModal
          message="Are you sure you want to accept?"
          onConfirm={onConfirmAccept}
          onCancel={onCloseConfirm}
        />
      )}

      {isNotificationModalOpen && (
        <NotificationModal
          message="Action has been successfully accepted!"
          onClose={onCloseNotification}
        />
      )}

      {isRejectModalOpen && (
        <RejectModal
          onConfirm={onConfirmReject}
          onCancel={onCloseReject}
        />
      )}
    </>
  );
};

export default ActionModals;