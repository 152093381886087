import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import './InputNewPassword.css';
import AuthHeader from '../../../components/AuthHeader/authHeader';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../AuthContext';
import axios from 'axios';
import NotificationModal from '../../../components/Modal/NotificationModal';

function InputNewPassword() {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const navigate = useNavigate()
  const { accessToken } = useAuth();
  const [isNotificationModalOpen, setIsNotificationModalOpen] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage('');

    if (password !== confirmPassword) {
      setMessage('Mật khẩu xác nhận không khớp.');
      setLoading(false);
      return;
    }

    try {
      await axios.post(`${process.env["REACT_APP_BASE_API"]}/api/forgot-password/`, {
          new_password: password
      }, {
          headers: {
              Authorization: `Bearer ${accessToken}`
          }
      })
      setNotificationMessage("Đã đặt lại mật khẩu thành công")
      setIsNotificationModalOpen(true)
      navigate("/teacher/dashboard")
    } catch (error) {
      console.error('Error updating password:', error);
      setNotificationMessage("Đã xảy ra lỗi xin vui lòng thử lại sau")
      setIsNotificationModalOpen(true)
    } finally {
      setLoading(false);
    }
  };

  const handleCloseNotificationModal = () => {
    setIsNotificationModalOpen(false); 
    setNotificationMessage(""); 
  };

  return (
    <div className="input-password-container">
      <AuthHeader/>
      <h2 className="input-password-title">Set a new password</h2>
      <p className="input-password-subtitle">Create a new password. Ensure it differs from previous ones for security.</p>
      <form onSubmit={handleSubmit} className="input-password-form">
        <TextField
          label="Password"
          variant="outlined"
          fullWidth
          type={showPassword ? 'text' : 'password'}
          value={password}
          onChange={handlePasswordChange}
          required
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          className="input-password-input"
          placeholder="Enter your password"
        />

        <TextField
          label="Confirm Password"
          variant="outlined"
          fullWidth
          type={showConfirmPassword ? 'text' : 'password'}
          value={confirmPassword}
          onChange={handleConfirmPasswordChange}
          required
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle confirm password visibility"
                  onClick={handleClickShowConfirmPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          className="input-password-input"
          placeholder="Re-Enter your password"
        />

        <button type="submit" disabled={loading} className="input-password-button">
          {loading ? 'Updating...' : 'Update Password'}
        </button>
      </form>
      {message && <p className="input-password-message">{message}</p>}
      {isNotificationModalOpen && (
          <NotificationModal 
              message={notificationMessage} 
              onClose={handleCloseNotificationModal} 
          />
      )}
    </div>
  );
}

export default InputNewPassword;