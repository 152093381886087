import React, { useEffect } from "react";
import AWS from 'aws-sdk';
import "./EditLessonDetailsModal.css";

// Configure S3
const s3 = new AWS.S3({
  accessKeyId: process.env["REACT_APP_AWS_ACCESS_KEY_ID"],
  secretAccessKey: process.env["REACT_APP_AWS_SECRET_ACCESS_KEY"],
  region: "ap-southeast-1",
});

const EditLessonDetailsModal = ({ 
  isOpen, 
  onClose, 
  onSave, 
  initialData,
  formErrors = {}
}) => {
  const [formData, setFormData] = React.useState(initialData);

  useEffect(() => {
    setFormData(initialData);
  }, [initialData]);

  if (!isOpen) return null;

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!formData.title?.trim() || !formData.description?.trim()) {
      alert("Vui lòng điền đầy đủ thông tin!");
      return;
    }
    onSave(formData);
  };

  const uploadToS3 = async (file) => {
    const params = {
      Bucket: "ivsenglishapp",
      Key: `lessons/${Date.now()}-${file.name}`,
      Body: file,
    };
    try {
      const data = await s3.upload(params).promise();
      return data.Location;
    } catch (error) {
      console.error('Error uploading to S3:', error);
      throw new Error('Upload failed');
    }
  };

  const handleImageUpload = async () => {
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.accept = 'image/*';
    
    fileInput.onchange = async (e) => {
      const file = e.target.files[0];
      if (file) {
        try {
          const imageUrl = await uploadToS3(file);
          setFormData(prev => ({
            ...prev,
            image_url: imageUrl
          }));
        } catch (error) {
          console.error('Upload failed:', error);
          alert('Failed to upload image. Please try again.');
        }
      }
    };

    fileInput.click();
  };

  return (
    <div className="edit-lesson-modal-overlay">
      <div className="edit-lesson-modal">
        <h2>Sửa thông tin bộ từ vựng</h2>
        <form onSubmit={handleSubmit}>
          <div className="quiz-form-group">
            <label>Title <span className="required">*</span></label>
            <input
              type="text"
              value={formData.title || ''}
              onChange={(e) => setFormData({...formData, title: e.target.value})}
              placeholder="Nhập tiêu đề"
            />
          </div>

          <div className="quiz-form-group">
            <label>Description <span className="required">*</span></label>
            <textarea
              value={formData.description || ''}
              onChange={(e) => setFormData({...formData, description: e.target.value})}
              placeholder="Nhập mô tả"
            />
          </div>

          <div className="quiz-form-group">
            <label>Image</label>
            <div className="image-input-container">
              <input
                type="text"
                value={formData.image_url || ''}
                onChange={(e) => setFormData({...formData, image_url: e.target.value})}
                placeholder="Image URL"
                className={formErrors?.image_url ? "error" : ""}
              />
              <button 
                type="button"
                className="upload-button"
                onClick={handleImageUpload}
              >
                Upload +
              </button>
            </div>
            {formErrors?.image_url && (
              <span className="error-message">{formErrors.image_url}</span>
            )}
          </div>

          <div className="modal-buttons">
            <button type="button" onClick={onClose}>Cancel</button>
            <button type="submit">Save</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditLessonDetailsModal;