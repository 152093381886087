import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import Sidebar from '../../components/Sidebar/adminSidebar';  
import TeacherList from "../Teachers/TeacherList"; 
import './adminTeacherDashboard.css';
import { useAuth } from '../../../../AuthContext';

const AdminTeacherDashboard = () => {
  const [pendingTeachers, setPendingTeachers] = useState([]);
  const pendingCount = pendingTeachers.length;
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { accessToken } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!accessToken) {
      navigate('/login');
    }
  }, [accessToken, navigate]);
  
  useEffect(() => {
  const fetchPendingTeachers = async () => {
    try {
      const response = await axios.get(`${process.env["REACT_APP_BASE_API"]}/api/admin/getTeachersInActive/`, 
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      setPendingTeachers(response.data);
    } catch (error) {
      setError("Lỗi khi tải dữ liệu.");
    } finally {
      setLoading(false);
    }
  };
    fetchPendingTeachers();
  }, [accessToken]);

  if (error) return <div className="error-message">{error}</div>;

  return (
    <div className="app">
      <Sidebar pendingCount={pendingCount}/> 
      <div className="main-content">
        <TeacherList pendingTeacher={pendingTeachers}/>
      </div>
    </div>
  );
};

export default AdminTeacherDashboard;

