import { useState } from 'react';
import axios from 'axios';

export const useUserSearch = (accessToken) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [autoCompleteKey, setAutoCompleteKey] = useState(0);
  const [userList, setUserList] = useState([]);
  const [userInfo, setUserInfo] = useState(null);
  const [loadingUsers, setLoadingUsers] = useState(false);

  const fetchUserDetails = async (userId) => {
    try {
      const response = await axios.post(
        `${process.env["REACT_APP_BASE_API"]}/api/admin/getUserInfor/`,
        { id: userId },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error(`Error fetching details for user ${userId}:`, error);
      return null;
    }
  };

  const handleKeyPress = async (event, get_random = false) => {
    if (event.key === "Enter") {
      event.preventDefault();
      const shouldGetRandom = get_random || !searchTerm.trim();

      try {
        let response;
        if (shouldGetRandom) {
          response = await axios.get(
            `${process.env["REACT_APP_BASE_API"]}/api/user/getRandomUsers/`,
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            }
          );
          setUserList(response.data);
          setUserInfo(null);
          setSearchResults([]);
          setSelectedOption(null);
          setIsOpen(false);
          setSearchTerm("");
        } else {
          response = await axios.get(
            `${process.env["REACT_APP_BASE_API"]}/api/user/search-users/?query=${searchTerm}`,
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            }
          );
          if (response.data.length > 0) {
            setSearchResults(response.data);
            setSelectedOption(response.data[0]);
            setIsOpen(true);
            setUserList([]);
          } else {
            throw new Error("No results found");
          }
        }
      } catch (error) {
        console.error("Error searching:", error);
        setSearchResults([
          {
            username: shouldGetRandom 
              ? "Error occurred. Please try again later"
              : "Cannot find any username. Press Enter to get random users",
            id: -1,
          },
        ]);
        if (!shouldGetRandom) {
          setSelectedOption(null);
          setIsOpen(true);
        }
      }
    }
  };

  const handleUserSelection = async (user) => {
    if (user) {
      document.activeElement.blur();
      setLoadingUsers(true);

      setSearchTerm("");
      setSelectedOption(null);
      setIsOpen(false);
      setSearchResults([]);
      setAutoCompleteKey((prev) => prev + 1);

      const userDetail = await fetchUserDetails(user.id || user.m_id);
      if (userDetail) {
        setUserInfo(userDetail);
        setUserList([]);
      }
      setLoadingUsers(false);
    }
  };

  return {
    searchTerm,
    setSearchTerm,
    searchResults,
    isOpen,
    setIsOpen,
    selectedOption,
    setSelectedOption,
    autoCompleteKey,
    userList,
    userInfo,
    loadingUsers,
    handleKeyPress,
    handleUserSelection,
  };
};