import React, { useState } from 'react';
import './RejectModal.css';

const RejectModal = ({ onConfirm, onCancel }) => {
  const [reason, setReason] = useState('');
  const [error, setError] = useState(false);

  const handleConfirm = () => {
    if (reason.trim() === '') {
      setError(true);
    } else {
      onConfirm(reason);
      setReason('');
      setError(false);
    }
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>Reject Teacher</h2>
        <p>Please provide a reason for rejection:</p>
        <textarea
          value={reason}
          onChange={(e) => {
            setReason(e.target.value);
            setError(false);
          }}
          placeholder="Enter rejection reason"
          rows="4"
          className={error ? 'error' : ''}
        />
        {error && <p className="error-message">Please provide a reason for rejection.</p>}
        <div className="modal-buttons">
          <button onClick={handleConfirm} className="confirm-button">Confirm</button>
          <button onClick={onCancel} className="cancel-button">Cancel</button>
        </div>
      </div>
    </div>
  );
};

export default RejectModal;
