import React from 'react';
import defaultBrainImage from '../../../../../../assets/brain.png';
import ArrowDown from '../../../../../../assets/Arrow--down.svg';
import ArrowUp from '../../../../../../assets/Arrow--up.svg';
import Launch from '../../../../../../assets/Launch.svg';
import TrashCan from '../../../../../../assets/Trash-can.svg';
import './VocabularyItem.css';

const VocabularyItem = ({
  item,
  index,
  isEditing,
  totalItems,
  onMoveItem,
  onEdit,
  onDelete
}) => {
  return (
    <div className="vocabulary-item">
      <div className="vocabulary-item-image">
        <img
          src={item.m_vocab_image || defaultBrainImage}
          alt={item.m_english_word || "Vocabulary"}
          className="vocabulary-image"
        />
      </div>

      <div className="vocabulary-item-content">
        <div className="vocabulary-left-column">
          <div className="vocabulary-section">
            <p className="vocabulary-label">Word</p>
            <p className="vocabulary-value">{item.m_english_word}</p>
          </div>
          <div className="vocabulary-section">
            <p className="vocabulary-label">Meaning</p>
            <p className="vocabulary-value">{item.m_definition}</p>
          </div>
          <div className="vocabulary-section">
            <p className="vocabulary-label">Transcription</p>
            <p className="vocabulary-value">{item.m_transcription}</p>
          </div>
        </div>

        <div className="vocabulary-right-column">
          {item.m_example_1 && (
            <div className="vocabulary-section">
              <p className="vocabulary-label">Example 1</p>
              <p className="vocabulary-value">{item.m_example_1}</p>
            </div>
          )}
          {item.m_example_2 && (
            <div className="vocabulary-section">
              <p className="vocabulary-label">Example 2</p>
              <p className="vocabulary-value">{item.m_example_2}</p>
            </div>
          )}
        </div>

        {isEditing && (
          <div className="vocabulary-edit-actions-item">
            <button
              className="vocabulary-edit-btn"
              onClick={() => onMoveItem(index, "down")}
              disabled={index === totalItems - 1}
            >
              <img
                src={ArrowDown}
                alt="Move down"
                style={{
                  opacity: index === totalItems - 1 ? 0.5 : 1,
                }}
              />
            </button>
            <button
              className="vocabulary-edit-btn"
              onClick={() => onMoveItem(index, "up")}
              disabled={index === 0}
            >
              <img
                src={ArrowUp}
                alt="Move up"
                style={{ opacity: index === 0 ? 0.5 : 1 }}
              />
            </button>
            <button
              className="vocabulary-edit-btn"
              onClick={() => onEdit(item)}
            >
              <img src={Launch} alt="Edit" />
            </button>
            <button
              className="vocabulary-edit-btn"
              onClick={() => onDelete(index)}
            >
              <img src={TrashCan} alt="Delete" />
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default VocabularyItem;