import React from 'react';
import { AreaChart, Area, XAxis, YAxis, Tooltip, CartesianGrid, ResponsiveContainer } from 'recharts';

const data = [
  { name: '01/10', Students: 40, Teachers: 80 },
  { name: '02/10', Students: 30, Teachers: 50 },
  { name: '03/10', Students: 40, Teachers: 40 },
  { name: '04/10', Students: 50, Teachers: 70 },
  // Các điểm dữ liệu khác...
];

const Chart = () => (
  <ResponsiveContainer width="100%" height={300}>
    <AreaChart data={data}>
      <defs>
        <linearGradient id="colorStudents" x1="0" y1="0" x2="0" y2="1">
          <stop offset="5%" stopColor="#ff7300" stopOpacity={0.8}/>
          <stop offset="95%" stopColor="#ff7300" stopOpacity={0}/>
        </linearGradient>
        <linearGradient id="colorTeachers" x1="0" y1="0" x2="0" y2="1">
          <stop offset="5%" stopColor="#387908" stopOpacity={0.8}/>
          <stop offset="95%" stopColor="#387908" stopOpacity={0}/>
        </linearGradient>
      </defs>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" />
      <YAxis />
      <Tooltip />
      <Area type="monotone" dataKey="Students" stroke="#ff7300" fillOpacity={1} fill="url(#colorStudents)" />
      <Area type="monotone" dataKey="Teachers" stroke="#387908" fillOpacity={1} fill="url(#colorTeachers)" />
    </AreaChart>
  </ResponsiveContainer>
);

export default Chart;
