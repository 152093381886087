import React from 'react';

const TeacherHeader = ({ teacher, onAccept, onReject, activeTab, setActiveTab }) => {
  return (
    <div className="box">
      <div className="path">
        <p style={{ color: "#ABABAB", fontWeight: 600 }}>Users / </p>
        <p style={{ color: "#ABABAB", fontWeight: 600, marginLeft: 5 }}>
          Teachers /{" "}
        </p>
        <p style={{ color: "#3D8FEF", fontWeight: 600, marginLeft: 5 }}>
          User details
        </p>
      </div>
      <div className="title">
        User details
        {teacher && teacher.m_status === "IN_ACTIVE" && (
          <div className="title-buttons">
            <button className="title-button-green" onClick={onAccept}>
              Accept
            </button>
            <button onClick={onReject} className="title-button-red">
              Reject
            </button>
          </div>
        )}
      </div>
      <div className="white-tabs-container">
        <div
          className={`white-tab ${activeTab === "detailedInformation" ? "active" : ""}`}
          onClick={() => setActiveTab("detailedInformation")}
        >
          Detailed information
        </div>
        <div
          className={`white-tab ${activeTab === "courses" ? "active" : ""}`}
          onClick={() => setActiveTab("courses")}
        >
          Courses
        </div>
      </div>
    </div>
  );
};

export default TeacherHeader;