// src/Chat.js
import React, { useState, useEffect } from 'react';
import { useAuth } from '../../AuthContext';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import './chatbox.css'; 
import ProfileHeader from '../../components/ProfileHeader/profileHeader';
import ReactMarkdown from 'react-markdown';

const Chatbox = () => {
    const [message, setMessage] = useState('');
    const [replies, setReplies] = useState([]);
    const [currentSpeech, setCurrentSpeech] = useState(null);
    const [isListening, setIsListening] = useState(false); // Trạng thái lắng nghe mic
    const navigate = useNavigate();
    const { accessToken } = useAuth();

    useEffect(() => {
        if (!accessToken) {
            navigate("/login");
        }
    }, [accessToken, navigate]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!message) return;

        try {
            const response = await axios.post(
                `${process.env["REACT_APP_BASE_API"]}/api/chatbox/chat/`, 
                { message },
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            );

            const reply = response.data.reply;
            setReplies((prev) => [...prev, { user: message, gpt: reply }]);
            setMessage('');
        } catch (error) {
            console.error("Error sending message:", error);
            setReplies((prev) => [...prev, { user: message, gpt: "Error connecting to the API." }]);
        }
    };

    const startListening = () => {
        const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
        if (!SpeechRecognition) {
            alert("Speech Recognition is not supported in this browser.");
            return;
        }
    
        const recognition = new SpeechRecognition();
        recognition.lang = 'en-US';
        recognition.interimResults = false;
        recognition.maxAlternatives = 1;
    
        recognition.onstart = () => {
            setIsListening(true);
        };
    
        recognition.onresult = (event) => {
            const speechToText = event.results[0][0].transcript;
            setMessage(speechToText);
        };
    
        recognition.onend = () => {
            setIsListening(false);
        };
    
        recognition.onerror = (event) => {
            console.error("Error recognizing speech:", event.error);
            setIsListening(false);
        };
    
        recognition.start();
    };    

    const speak = (text) => {
        const speech = new SpeechSynthesisUtterance(text);
        speech.onend = () => {
            setCurrentSpeech(null);
        };
        setCurrentSpeech(speech);
        window.speechSynthesis.speak(speech);
    };

    const stopSpeaking = () => {
        if (currentSpeech) {
            window.speechSynthesis.cancel();
            setCurrentSpeech(null);
        }
    };

    return (
        <div>
            <ProfileHeader/>
            <div className="custom-chat-container">
                <h1 className="custom-chat-header">Chatbox</h1>
                <div className="custom-chat-box">
                    {replies.map((reply, index) => (
                        <div key={index} className="custom-message">
                            <div className="message-bubble user-bubble">
                                {reply.user}
                            </div>
                            <div className="message-bubble gpt-bubble">
                                <ReactMarkdown>{reply.gpt}</ReactMarkdown>
                                <div className="button-group">
                                    <button 
                                        className="custom-speak-button" 
                                        onClick={() => speak(reply.gpt)}
                                    >
                                        🔊 Speak
                                    </button>
                                    <button 
                                        className="custom-stop-button" 
                                        onClick={stopSpeaking}
                                    >
                                        ⏹️ Stop
                                    </button>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <form className="custom-form" onSubmit={handleSubmit}>
                    <input
                        type="text"
                        className="custom-input"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        placeholder="Type your message here..."
                    />
                    <button type="submit" className="custom-button">Send</button>
                    <button 
                        type="button" 
                        className="custom-mic-button" 
                        onClick={startListening}
                        disabled={isListening}
                    >
                        {isListening ? "Listening..." : "🎤 Speak"}
                    </button>
                </form>
            </div>
        </div>
    );
};

export default Chatbox;