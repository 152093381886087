import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import "./GrammarLesson.css";
import ProfileHeader from "../../../../components/ProfileHeader/profileHeader";
import { useAuth } from "../../../../AuthContext";
import { useNavigate } from "react-router-dom";
import MarkdownPreview from "@uiw/react-markdown-preview";
import lessonSampleBanner from "../../../../assets/Lesson-Sample-1.png";

const GrammarLesson = () => {
  const { id } = useParams();
  const [lesson, setLesson] = useState(null);
  const [grammarContent, setGrammarContent] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { accessToken } = useAuth();
  const [courseName, setCourseName] = useState("");
  const [editTitle, setEditTitle] = useState("");
  const [editDescription, setEditDescription] = useState("");
  const [editImageUrl, setEditImageUrl] = useState("");
  const [imageError, setImageError] = useState(false);
  const navigate = useNavigate();
  const [notification, setNotification] = useState({
    isOpen: false,
    message: "",
  });
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [confirmAction, setConfirmAction] = useState(null);
  const [confirmMessage, setConfirmMessage] = useState("");
  const [isPublished, setIsPublished] = useState(false);

  useEffect(() => {
    const fetchLesson = async () => {
      if (!accessToken) {
        navigate('/login');
        return;
      }

      try {
        const response = await axios.get(
          `${process.env["REACT_APP_BASE_API"]}/api/course/getGrammarLesson/?lesson_id=${id}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        setLesson(response.data.lesson);
        setGrammarContent(response.data.grammar.m_content);
        setEditTitle(response.data.lesson.m_title);
        setEditDescription(response.data.lesson.m_content);
        setEditImageUrl(response.data.lesson.m_lesson_image || "");
        setIsPublished(response.data.lesson.m_status === "public");
        // Get course name from m_course_id of lesson
        const courseResponse = await axios.get(
          `${process.env["REACT_APP_BASE_API"]}/api/course/getCourse/?m_course_id=${response.data.lesson.m_course_id}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              // log the course
            },
          }
        );
        console.log(response.data.lesson.m_course_id);
        console.log(courseResponse.data);
        console.log(courseResponse.data.m_title);
        setCourseName(courseResponse.data.m_title);
      } catch (error) {
        console.error("Error fetching lesson:", error);
      }
    };

    fetchLesson();
  }, [id, accessToken, setCourseName, navigate]);

  const handleEditClick = () => {
    navigate(`/teacher/grammar-lesson/${id}/edit`, {
      state: { lesson, grammarContent },
    });
  };

  const handleSaveClick = async () => {
    setConfirmAction(() => async () => {
      try {
        await axios.put(
          `${process.env["REACT_APP_BASE_API"]}/api/course/updateGrammar/`,
          {
            m_lesson_id: lesson.m_lesson_id,
            m_grammar_content: grammarContent,
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        setIsEditing(false);
      } catch (error) {
        console.error("Error updating grammar content:", error);
      }
    });
    setConfirmMessage('Save the changes?');
    setIsConfirmDialogOpen(true);
  };

  const handleDeleteLesson = async () => {
    setConfirmAction(() => async () => {
      try {
        await axios.delete(
          `${process.env.REACT_APP_BASE_API}/api/course/deleteLesson/`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
            data: {
              m_lesson_id: id,
            },
          }
        );
        setNotification({ isOpen: true, message: "Xóa bài học thành công" });
        navigate(`/teacher/course/${lesson.m_course_id}`);
      } catch (error) {
        setNotification({ isOpen: true, message: "Lỗi khi xóa bài học" });
      }
    });
    setConfirmMessage('Are you sure you want to delete this lesson?');
    setIsConfirmDialogOpen(true);
  };

  const handleDialogSave = async () => {
    if (!editTitle.trim() || !editDescription.trim()) {
      setNotification({
        isOpen: true,
        message: "All fields must not be empty",
      });
      return;
    }
    setConfirmAction(() => async () => {
      try {
        const response = await axios.put(
          `${process.env["REACT_APP_BASE_API"]}/api/course/updateLesson/`,
          {
            m_lesson_id: lesson.m_lesson_id,
            m_title: editTitle,
            m_content: editDescription,
            m_lesson_image: editImageUrl,
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        if (response.status === 200) {
          setLesson({
            ...lesson,
            m_title: editTitle,
            m_content: editDescription,
            m_lesson_image: editImageUrl,
          });
          setIsDialogOpen(false);
          setNotification({ isOpen: true, message: "Saved succesfully" });
        } else {
          setNotification({ isOpen: true, message: "Error: " + response.data.error });
        }
      } catch (error) {
        console.error('Error updating lesson information:', error);
        setNotification({ isOpen: true, message: "Error: " + error.response.data.error });
      }
    });
    setConfirmMessage('Save the changes?');
    setIsConfirmDialogOpen(true);
  };

  const handleConfirmAction = async () => {
    if (confirmAction) {
      await confirmAction();
    }
    setIsConfirmDialogOpen(false);
  };

  const handlePublishClick = async () => {
    setConfirmAction(() => async () => {
      try {
        await axios.put(
          `${process.env["REACT_APP_BASE_API"]}/api/course/updateLesson/`,
          {
            m_lesson_id: lesson.m_lesson_id,
            m_status: "public",
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        setIsPublished(true);
        setNotification({ isOpen: true, message: "The lesson has been published" });
      } catch (error) {
        console.error('Error publishing lesson:', error);
        setNotification({ isOpen: true, message: "Error while publishing" });
      }
    });
    setConfirmMessage('Publish this lesson?');
    setIsConfirmDialogOpen(true);
  };

  const handleGoBack = () => {
    navigate(`/teacher/course/${lesson.m_course_id}?tab=contents`);
  };

  return (
    <div>
      <div className="grammarLesson-header-newlesson">
        <ProfileHeader />
      </div>

      <button
            className="grammarLesson-button grammarLesson-button-just-blue-border grammarLesson-back-button"
            onClick={handleGoBack}
          >
            Back
          </button>

      <div className="grammarLesson-container">
        <div className="grammarLesson-sidebar">
          
        </div>
        <div className="grammarLesson-main">
          {lesson && (
            <>
              {/* <p className="grammarLesson-meta">
                Chương trình học {'> '} Quản lý khóa học {'> '} {courseName} {'> '} {lesson.m_title}
              </p> */}

              <div className="grammarLesson-subheader">
                <div className="grammar-breadcrumb">
                  <span
                    className="grammar-breadcrumb-link"
                    onClick={() => navigate("/teacher/course")}
                  >
                    Chương trình học
                  </span>
                  <span>›</span>
                  <span
                    className="grammar-breadcrumb-link"
                    onClick={() => navigate("/teacher/course")}
                  >
                    Quản lý khóa học
                  </span>
                  <span>›</span>
                  <span
                    className="grammar-breadcrumb-link"
                    onClick={() => navigate(-1)}
                  >
                    {courseName}
                  </span>
                  <span>›</span>
                  <span>Grammar lessons</span>
                </div>


                <div className="grammarLesson-actions">
                  <button
                    className="grammarLesson-button grammarLesson-button-just-blue-border"
                    onClick={() => setIsDialogOpen(true)}
                  >
                    Edit
                  </button>
                  <button
                    className="grammarLesson-button grammarLesson-button-just-red-border"
                    onClick={handleDeleteLesson}
                  >
                    Delete
                  </button>
                </div>
              </div>

              <div className="grammarLesson-header">
                <img
                  src={lesson.m_lesson_image || lessonSampleBanner}
                  alt="Sample"
                  className="grammarLesson-image"
                  style={{
                    height: "auto",
                    maxHeight: "400px",
                    width: "33%",
                    objectFit: "cover",
                  }}
                />
              </div>
              <h1 className="grammarLesson-title">
                <b>{lesson.m_title}</b>
              </h1>
              {/* <p className="grammarLesson-meta">
                Ngày tạo: {new Date(lesson.m_created_at).toLocaleDateString()} | Ngày cập nhật: {new Date(lesson.m_updated_at).toLocaleDateString()}
              </p> */}
              <h2>Descriptions</h2>
              <p className="grammarLesson-description">{lesson.m_content}</p>
              <div className="grammarLesson-content-header">
                <h2>Contents</h2>
                <div className="grammarLesson-content-actions">
                  {!isPublished ? (
                    <button
                      className="grammarLesson-button-just-green-inside"
                      onClick={handlePublishClick}
                    >
                      Publish Lesson
                    </button>
                  ) : (
                    <button
                      className="grammarLesson-button-just-green-inside"
                      disabled
                    >
                      Lesson Published
                    </button>
                  )}
                  <button
                    className="grammarLesson-button-just-blue-inside"
                    onClick={handleEditClick}
                  >
                    Edit lesson
                  </button>
                </div>
              </div>
              {!isEditing ? (
                <div className="grammarLesson-content">
                  <MarkdownPreview
                    source={grammarContent}
                    className="grammarLesson-content-box"
                  />
                </div>
              ) : (
                <div className="grammarLesson-editContainer">
                  <button onClick={handleSaveClick}>Lưu</button>
                  <button onClick={() => setIsEditing(false)}>Hủy</button>
                </div>
              )}
            </>
          )}
        </div>
      </div>

      {/* Dialogue Box for editing lesson information */}
      {isDialogOpen && (
        <div className="grammarLesson-dialog-overlay">
          <div className="gl-dialog">
            <h2>Edit course information</h2>
            <label style={{ color: "grey" }}>
              Title:
              <input
                type="text"
                value={editTitle}
                onChange={(e) => setEditTitle(e.target.value)}
              />
            </label>
            <label style={{ color: "grey" }}>
              Description:
              <textarea
                value={editDescription}
                onChange={(e) => setEditDescription(e.target.value)}
              />
            </label>
            <label style={{ color: "grey" }}>
              Image URL:
              <input
                type="text"
                value={editImageUrl}
                onChange={(e) => setEditImageUrl(e.target.value)}
                onBlur={() => setImageError(false)}
              />
            </label>
            {editImageUrl && !imageError && (
              <img
                src={editImageUrl}
                alt="Lesson Preview"
                onError={() => setImageError(true)}
                style={{ maxWidth: "80%", marginTop: "10px" }}
              />
            )}
            {imageError && (
              <p style={{ color: "red" }}>
                Image could not be loaded. Please check the URL.
              </p>
            )}
            <button
              className="grammarLesson-button-just-purple-inside"
              onClick={handleDialogSave}
            >
              Lưu
            </button>
            <button
              className="grammarLesson-button-just-purple-border"
              onClick={() => setIsDialogOpen(false)}
            >
              Hủy
            </button>
          </div>
        </div>
      )}

      {/* Notification Dialog */}
      {notification.isOpen && (
        <div className="grammarLesson-dialog-overlay">
          <div
            className="gl-dialog"
            style={{ width: "500px", height: "140px", padding: "20px" }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "25px",
              }}
            >
              <h2>{notification.message}</h2>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "25px",
              }}
            >
              <button
                className="grammarLesson-button-just-purple-border"
                onClick={() => setNotification({ isOpen: false, message: "" })}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Confirm Dialog */}
      {isConfirmDialogOpen && (
        <div className="grammarLesson-dialog-overlay">
          <div
            className="gl-dialog"
            style={{ width: "500px", height: "140px", padding: "20px" }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "25px",
              }}
            >
              <h2>{confirmMessage}</h2>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "25px",
              }}
            >
              <button
                className="grammarLesson-button-just-purple-border"
                onClick={() => setIsConfirmDialogOpen(false)}
              >
                Hủy
              </button>
              <button
                className="grammarLesson-button-just-purple-inside"
                onClick={handleConfirmAction}
              >
                OK
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default GrammarLesson;
