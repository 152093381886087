import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// import {io} from 'socket.io-client';
// import ReconnectingWebSocket from 'reconnecting-websocket';
import ChatSidebar from '../../components/Chat/ChatSidebar';
import ChatWindow from '../../components/Chat/ChatWindow';
import ChatInfoPanel from '../../components/Chat/ChatInfoPanel';
import { useAuth } from "../../AuthContext";
import './ChatPage.css';
import axios from "axios";
import ProfileHeader from "../../components/ProfileHeader/profileHeader";
import { ToastContainer } from 'react-toastify';
// console.log(process.env.REACT_APP_BASE_API);

// const socket = io.connect('ws://localhost:8000');
const baseApiUrl = process.env.REACT_APP_BASE_API;
const wsProtocol = baseApiUrl.startsWith('https') ? 'wss' : 'ws';
// const socket = io(`${wsProtocol}://${baseApiUrl.split('://')[1]}`);

function ChatPage() {
  const location = useLocation();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedChat, setSelectedChat] = useState(null);
  const [chats, setChats] = useState([]);
  const [messages, setMessages] = useState([]);
  const navigate = useNavigate();
  const { accessToken } = useAuth();
  const [sockets, setSockets] = useState([]);

  useEffect(() => {
    if (!accessToken) {
        navigate('/login');
        // console.log('what happen login');
        return;
    }

    const fetchUserProfile = async () => {
      // console.log('what happen fetchUserProfile');
      try {
          const response = await axios.get(
              `${process.env["REACT_APP_BASE_API"]}/api/me/`,
              {
                  headers: {
                      Authorization: `Bearer ${accessToken}`,
                  },
              }
          );

          if (response.status === 401) {
              navigate('/login');
          }

          const userProfile = response.data;
          if (userProfile.role !== 'teacher' && userProfile.role !== 'admin') {
              navigate('/not-authorized');
          }
          // console.log("hjjjjjjjjjj");
          // console.log(userProfile);
          setUser(userProfile);

      } catch (error) {
          setError("Lỗi khi tải thông tin người dùng.");
          navigate('/login');
      } finally {
          setLoading(false); // Set loading to false after fetching user profile
      }
    };

    fetchUserProfile();
  }, [accessToken, navigate]);

  
  // console.log(user);

  const username = user ? user.m_user_name : null;

  useEffect(() => {
    // Fetch chats from the API
    const fetchChats = async () => {
      try {
        const response = await axios.get(
            `${process.env.REACT_APP_BASE_API}/api/group/getGroupList/`,
            {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            }
        );
        // const response = await fetch(`${process.env.REACT_APP_BASE_API}/api/group/getGroupList`);
        const data = await response.data;
        setChats(data["groups"]);
      } catch (error) {
        console.error('Error fetching chats:', error);
      }
    };

    fetchChats();
  }, [accessToken]);

  useEffect(() => {
    if (chats.length > 0) {
      Object.values(sockets).forEach(socket => socket.close());
      const newSockets = {};
      chats.forEach(chat => {
        const newSocket = new WebSocket(
          `${wsProtocol}://${baseApiUrl.split('://')[1]}/ws/chat/${chat._id}/`
        );

        newSocket.onmessage = function(event) {
          const message = JSON.parse(event.data);
          console.log('New message:', message);
          // If the message is for the selected chat, update the messages state
          if (selectedChat && chat._id === selectedChat._id) {
            console.log("cập nhật message");
            setMessages(prevMessages => [...prevMessages, message]);
          }
          
          // Update the chats state to reflect the new message and move the chat to the top
          setChats(prevChats => {
            const updatedChats = prevChats.map(c => {
              if (c._id === chat._id) {
                return {
                  ...c,
                  m_group_lastMessage: message.m_message_text,
                  m_group_lastSender: message.m_message_sender.username,
                  m_group_lastUpdated: new Date().toISOString(),
                };
              }
              return c;
            });

            // Move the updated chat to the top
            const chatIndex = updatedChats.findIndex(c => c._id === message.m_message_group_id);
            const [updatedChat] = updatedChats.splice(chatIndex, 1);
            updatedChats.unshift(updatedChat);

            return updatedChats;
          });
        };

        newSocket.onclose = function(event) {
          console.log('WebSocket closed:', event);
        };

        newSocket.onerror = function(error) {
          console.error('WebSocket error:', error);
        };

        newSockets[chat._id] = newSocket;

      });

      setSockets(newSockets);
    }

    return () => {
      Object.values(sockets).forEach(socket => socket.close());
    };  
  }, [selectedChat]);

  useEffect(() => {
    if (selectedChat) {
      // Fetch messages for the selected chat from the API
      const fetchMessages = async () => {
        try {
          const response = await axios.get(
            `${baseApiUrl}/api/group/${selectedChat._id}/messages/`,
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            }
          );
          const data = response.data;
          setMessages(data);
        } catch (error) {
          console.error('Error fetching messages:', error);
        }
      };

      fetchMessages();

      // if (socket) {
      //   socket.close();
      // }

      // const newSocket = new WebSocket(
      //    `${wsProtocol}://${baseApiUrl.split('://')[1]}` + '/ws/chat/' + `${selectedChat._id}` + '/'
      // );

      // newSocket.onmessage = function(event) {
      //   const message = JSON.parse(event.data);
      //   setMessages((prevMessages) => [...prevMessages, message]);
      // };

      // setSocket(newSocket);

      // // Cleanup on unmount
      // return () => {
      //   if (socket){
      //     socket.send(JSON.stringify({ type: 'leave', conversation_id: selectedChat._id }));
      //     socket.close();
      //   }
      // };
    }
  }, [selectedChat]);

  const sendMessage = async (message, image) => {
    let imageUrl = null;
    if (image) {
      const formData = new FormData();
      formData.append('file', image);
      const response = await fetch(`${process.env.REACT_APP_BASE_API}/upload/`, {
        method: 'POST',
        body: formData,
      });
      const data = await response.json();
      imageUrl = data.url;
    }

    const newMessage = {
      userid: user.m_id,
      username: user.m_user_name,
      timestamp: new Date().toISOString(),
      m_message_text: message,
      m_message_image_url: imageUrl
    };

  
    if (sockets && selectedChat) {
      sockets[selectedChat._id].send(JSON.stringify({ type: 'message', conversation_id: selectedChat._id, ...newMessage }));
    };

  };

  const handleGroupUpdated = async () => {
    // Fetch updated chats from the API
    const fetchChats = async () => {
      try {
        const response = await axios.get(
          `${baseApiUrl}/api/group/getGroupList/`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        const data = response.data;
        setChats(data["groups"]);
        // console.log('Chats:', data);
      } catch (error) {
        // console.error('Error fetching chats:', error);
      }
    }

    fetchChats();
  };

  return (
    <div style={{height: "100vh", width: "100%", display: "flex", flexDirection: "column", flex:1}}>
      <ToastContainer />
      <ProfileHeader style={{position: "fixed", zIndex: 1, top: 0, width: "100%"}}></ProfileHeader>
      <div className="chat-page">
        <ChatSidebar chats={chats} onSelectedChat={setSelectedChat} username={username}/>
        <ChatWindow messages={messages} selectedChat={selectedChat} onSendMessage={sendMessage} currentUser={username} />
        <ChatInfoPanel chat={selectedChat} onGroupUpdated={handleGroupUpdated} onSelectedChat={setSelectedChat}/>
      </div>
    </div>
  );
}

export default ChatPage;