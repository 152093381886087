import React, { useState } from 'react';
import { TextField, Button, IconButton, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff, Google as GoogleIcon, Facebook as FacebookIcon } from '@mui/icons-material';
import './teacher_signup.css';
import AuthHeader from '../../components/AuthHeader/authHeader';
import { useNavigate } from 'react-router-dom';
import ValidateRegister from './verify';

const TeacherSignUp = () => {
    const [email, setEmail] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [emailError, setEmailError] = useState('');
    const [usernameError, setUsernameError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [confirmPasswordError, setConfirmPasswordError] = useState('');
    const navigate = useNavigate();

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    const handleRegister = () => {
        let valid = true;

        if (!email) {
            setEmailError('Email is required.');
            valid = false;
        } else if (!emailRegex.test(email)) {
            setEmailError('Please enter a valid email address.');
            valid = false;
        } else {
            setEmailError('');
        }

        const usernameValidation = ValidateRegister.validate_username(username);
        if (!username) {
            setUsernameError('Username is required.');
            valid = false;
        } else if (usernameValidation === "too_short") {
            setUsernameError('Username must be at least 6 characters long.');
            valid = false;
        } else if (usernameValidation === "too_long") {
            setUsernameError('Username must be less than 30 characters.');
            valid = false;
        } else if (usernameValidation === "invalid_characters") {
            setUsernameError('Username can only contain letters, numbers, and underscores.');
            valid = false;
        } else {
            setUsernameError('');
        }

        const passwordValidation = ValidateRegister.validate_password(password);
        if (!password) {
            setPasswordError('Password is required.');
            valid = false;
        } else if (passwordValidation === "invalid_length") {
            setPasswordError('Password must be between 8 and 64 characters.');
            valid = false;
        } else if (passwordValidation === "contains_space") {
            setPasswordError('Password should not contain spaces.');
            valid = false;
        } else if (passwordValidation === "no_uppercase") {
            setPasswordError('Password must contain at least one uppercase letter.');
            valid = false;
        } else if (passwordValidation === "no_lowercase") {
            setPasswordError('Password must contain at least one lowercase letter.');
            valid = false;
        } else if (passwordValidation === "no_digit") {
            setPasswordError('Password must contain at least one digit.');
            valid = false;
        } else if (passwordValidation === "no_special_char") {
            setPasswordError('Password must contain at least one special character.');
            valid = false;
        } else {
            setPasswordError('');
        }

        if (!confirmPassword) {
            setConfirmPasswordError('Please confirm your password.');
            valid = false;
        } else if (password !== confirmPassword) {
            setConfirmPasswordError('Passwords do not match.');
            valid = false;
        } else {
            setConfirmPasswordError('');
        }

        ValidateRegister.is_unique_infor(username, email)
        .then(is_unique_infor => {
            if (is_unique_infor === "email_invalid") {
                setEmailError('Email has already been used');
                valid = false;
            } else if (is_unique_infor === "username_invalid") {
                setUsernameError('Username has already been used');
                valid = false;
            }
            
            if (valid) {
                navigate('/upload-image', {
                    state: { email, username, password, role: 'teacher' }
                });
            }
        })  
    };

    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleClickShowConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);

    return (
        <div className="signup-page">
            <AuthHeader />
            <div className="signup-header">
                <h1>English App</h1>
                <p>Học một ngôn ngữ mới, sống một cuộc đời mới.</p>
            </div>

            <div className="signup-container">
                <div className="signup-image">
                    <img src={require('../../assets/signup_teacher.png')} alt="teacher" />
                </div>

                <div className="form-container"> 
                    <div className="auth-tabs">
                        <button className="tab" onClick={() => navigate('/login')}>Log in</button>
                        <button className="tab active" onClick={() => navigate('/signup')}>Sign up</button>
                    </div>

                    <form onSubmit={(e) => e.preventDefault()}>
                        <TextField
                            label="Email"
                            variant="outlined"
                            fullWidth
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            margin="normal"
                            error={!!emailError}
                            helperText={emailError}
                            required
                        />

                        <TextField
                            label="Username"
                            variant="outlined"
                            fullWidth
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            margin="normal"
                            error={!!usernameError}
                            helperText={usernameError}
                            required
                        />

                        <TextField
                            label="Password"
                            variant="outlined"
                            fullWidth
                            type={showPassword ? 'text' : 'password'}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            margin="normal"
                            error={!!passwordError}
                            helperText={passwordError}
                            required
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={handleClickShowPassword}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />

                        <TextField
                            label="Confirm Password"
                            variant="outlined"
                            fullWidth
                            type={showConfirmPassword ? 'text' : 'password'}
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            margin="normal"
                            error={!!confirmPasswordError}
                            helperText={confirmPasswordError}
                            required
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={handleClickShowConfirmPassword}
                                            edge="end"
                                        >
                                            {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />

                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={handleRegister}
                            className="register-button"
                        >
                            Next
                        </Button>
                    </form>

                    <div className="social-login">
                        <span>Or</span>
                        <div className="social-buttons">
                            <Button
                                variant="outlined"
                                startIcon={<FacebookIcon />}
                                className="social-btn fb"
                                style={{ borderColor: '#3b5998', color: '#3b5998', margin: '0 10px' }}
                            >
                                Facebook
                            </Button>
                            <Button
                                variant="outlined"
                                startIcon={<GoogleIcon />}
                                className="social-btn google"
                                style={{ borderColor: '#db4437', color: '#db4437' }}
                            >
                                Google
                            </Button>
                        </div>
                    </div>

                    <div className="login-link">
                        Do you have an account? <a href="/" onClick={() => navigate('/login')}>Login</a>
                    </div>
                </div>
            </div>

            <footer className="signup-footer">
                @IVS Joint Stock Company
            </footer>
        </div>
    );
};

export default TeacherSignUp;
