import React from 'react';
import './AppDownloadPopup.css';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';

const AppDownloadPopup = ({ open, onClose }) => {
  return (
    <Dialog 
      open={open} 
      onClose={onClose}
    >
      <DialogTitle id="app-download-title">
        Tải ứng dụng của chúng tôi
      </DialogTitle>
      <DialogContent>
        <p id="app-download-description">
          Để đăng ký và sử dụng dịch vụ với tư cách học sinh, vui lòng tải ứng dụng trên Android hoặc iOS để có trải nghiệm tốt nhất.
        </p>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Quay lại
        </Button>
        <Button 
          variant="contained" 
          color="primary"
        >
          Tải ứng dụng
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AppDownloadPopup;
