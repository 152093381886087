import './ConfirmModal.css';

const ConfirmModal = ({ isOpen, onClose, onConfirm, title, message }) => {
    if (!isOpen) return null;
  
    return (
      <div className="confirm-modal-overlay">
        <div className="confirm-modal">
          <div className="confirm-modal-close" onClick={onClose}>
            <span>×</span>
          </div>
          <h2>{title}</h2>
          <p>{message}</p>
          <div className="confirm-modal-actions">
            <button className="confirm-modal-cancel" onClick={onClose}>
              Cancel
            </button>
            <button className="confirm-modal-ok" onClick={onConfirm}>
              OK
            </button>
          </div>
        </div>
      </div>
    );
  };
  
  export default ConfirmModal;