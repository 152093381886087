import React, { useEffect, useState } from "react";
import "./DegreeInformation.css";
import EditImageModal from "./EditImageModal";

const parseImages = (imageString) => {
  if (!imageString) return [];
  return imageString.split(",").filter((url) => url.trim());
};

const DegreeInformation = ({ user, toggleZoom, onImagesUpdate }) => {
  const [editingSection, setEditingSection] = useState(null);
  const [imagePreviews, setImagePreviews] = useState({
    portrait: [],
    certificate: [],
    other: [],
  });
  const [currentImages, setCurrentImages] = useState({
    portrait: [],
    certificate: [],
    other: [],
  });
  const isRejected = user?.m_status === "REJECTED";

  useEffect(() => {
    setCurrentImages({
      portrait: parseImages(user.m_portrait_image),
      certificate: parseImages(user.m_certificate_image),
      other: parseImages(user.m_other_image),
    });
  }, [user]);

  useEffect(() => {
    const remainingPreviews = Object.values(imagePreviews).some(
      (preview) => preview.length > 0
    );
    if (!remainingPreviews) {
      onImagesUpdate(null, null, false);
    }
  }, [imagePreviews, onImagesUpdate]);

  useEffect(() => {
    return () => {
      Object.values(imagePreviews).forEach((sectionPreviews) => {
        if (Array.isArray(sectionPreviews)) {
          sectionPreviews.forEach((preview) => URL.revokeObjectURL(preview));
        }
      });
    };
  }, [imagePreviews]);

  const openEditModal = (section) => {
    if (!isRejected) return;
    setEditingSection(section);
  };

  const closeEditModal = () => {
    setEditingSection(null);
  };

  const handleImagesUpdate = (section, newFiles) => {
    if (newFiles.length > 0) {
      if (section === "portrait") {
        const singleFile = newFiles[0];
        const previewUrl = URL.createObjectURL(singleFile);
        setImagePreviews((prev) => ({
          ...prev,
          [section]: [previewUrl],
        }));
        onImagesUpdate(section, [singleFile], true);
      } else {
        const newPreviews = newFiles.map((file) => URL.createObjectURL(file));
        setImagePreviews((prev) => ({
          ...prev,
          [section]: [...(prev[section] || []), ...newPreviews],
        }));

        const existingImages = currentImages[section] || [];

        onImagesUpdate(section, [...existingImages, ...newFiles], true);
      }
    }
  };

  const handleRemoveAllPreviewImages = (section) => {
    if (imagePreviews[section]?.length > 0) {
      imagePreviews[section].forEach((preview) => URL.revokeObjectURL(preview));
      setImagePreviews((prev) => {
        const newPreviews = {
          ...prev,
          [section]: [],
        };

        const hasRemainingPreviews = Object.values(newPreviews).some(
          (previews) => previews.length > 0
        );

        if (!hasRemainingPreviews) {
          onImagesUpdate(null, null, false);
        } else {
          onImagesUpdate(section, null, false);
        }

        return newPreviews;
      });
    }
  };

  const handleRemoveImage = (section, imageUrl) => {
    if (!isRejected) return;

    setCurrentImages((prev) => {
      const updatedImages = {
        ...prev,
        [section]: prev[section].filter((url) => url !== imageUrl),
      };

      onImagesUpdate(section, updatedImages[section], true);
      return updatedImages;
    });
  };

  const renderImageSection = (title, section, currentImageString) => {
    const previewImages = imagePreviews[section];
    const images = currentImages[section] || [];
    const totalImages =
      images.length + (previewImages.length > 0 ? previewImages.length : 0);
    const remainingSlots = 10 - totalImages;

    return (
      <div className="image-section">
        <div className="section-header">
          <h3>{title}</h3>
          {isRejected && remainingSlots > 0 && (
            <button
              className="edit-button"
              onClick={() => openEditModal(section)}
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          )}
          {isRejected && remainingSlots <= 0 && (
            <p className="limit-reached">Đã đạt giới hạn 10 ảnh.</p>
          )}
        </div>

        <div className="image-container">
          {previewImages.length > 0 ? (
            <div className="preview-container">
              <div className="image-comparison">
                <div className="current-images">
                  <p>Current Images</p>
                  {images.length > 0 ? (
                    renderImageGrid(images, section, previewImages)
                  ) : (
                    <div className="image-wrapper">
                      <img
                        src="../../assets/place_holder.jpg"
                        alt={`No ${title}`}
                        className="image-placeholder"
                      />
                    </div>
                  )}
                </div>
                <div className="preview-images">
                  <p>New Images (Preview)</p>
                  <div className="previews-grid">
                    {previewImages.map((preview) => (
                      <div key={preview} className="image-wrapper">
                        <img
                          src={preview}
                          alt={`New ${section}`}
                          className="image-placeholder"
                        />
                      </div>
                    ))}
                  </div>
                  <button
                    className="remove-preview-button"
                    onClick={() => handleRemoveAllPreviewImages(section)}
                    type="button"
                  >
                    Remove All
                  </button>
                </div>
              </div>
            </div>
          ) : images.length > 0 ? (
            renderImageGrid(images, section, previewImages)
          ) : (
            <div className="image-wrapper">
              <img
                src="../../assets/place_holder.jpg"
                alt={`No ${title}`}
                className="image-placeholder"
              />
            </div>
          )}
        </div>

        {editingSection === section && (
          <EditImageModal
            open={editingSection === section}
            onUpdate={handleImagesUpdate}
            section={section}
            onClose={closeEditModal}
            singleImageMode={section === "portrait"}
            currentImages={images}
          />
        )}
      </div>
    );
  };

  const renderImageGrid = (images, section, previewImages) => {
    return (
      <div className="images-grid">
        {images.map((img) => (
          <div key={img} className="image-wrapper">
            <img
              src={img}
              alt={`${section}`}
              className="image-placeholder"
              onClick={() => toggleZoom(img)}
            />
            {isRejected && images.length > 1 && previewImages.length === 0 && (
              <button
                className="remove-image-button"
                onClick={() => handleRemoveImage(section, img)}
                type="button"
              >
                ×
              </button>
            )}
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="profile-content degree-information">
      <h2>Degree information</h2>
      {renderImageSection("Portrait images", "portrait", user.m_portrait_image)}
      {renderImageSection(
        "Certificate images",
        "certificate",
        user.m_certificate_image
      )}
      {renderImageSection("Other images", "other", user.m_other_image)}
    </div>
  );
};

export default DegreeInformation;
