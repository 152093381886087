import React, { useState } from "react";
import SearchBar from "./components/SearchBar";
import StatusFilter from "./components/StatusFilter";
import ViewModeSelect from "./components/ViewModeSelect";
import CourseCard from "./components/CourseCard";
import CourseGridCard from "./components/CourseGridCard";
import Pagination from "./components/Pagination";
import "./CourseList.css";

const CourseList = ({
  courses,
  searchTerm,
  setSearchTerm,
  filterStatus,
  setFilterStatus,
  currentPage,
  setCurrentPage,
  loading
}) => {
  const [viewMode, setViewMode] = useState('list');
  const itemsPerPage = 10;

  const filteredCourses = courses.filter((course) => {
    const matchesSearch = course.m_title.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesStatus = filterStatus ? course.m_status === filterStatus : true;
    return matchesSearch && matchesStatus;
  });

  const totalItems = filteredCourses.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, totalItems);
  const currentCourses = filteredCourses.slice(startIndex, endIndex);

  const getNoResultsMessage = () => {
    if (searchTerm && filterStatus) {
      return `No courses found matching "${searchTerm}" with status "${filterStatus}"`;
    } else if (searchTerm) {
      return `No courses found matching "${searchTerm}"`;
    } else if (filterStatus) {
      return `No ${filterStatus} courses found`;
    }
    return 'No courses found';
  };

  return (
    <div className="course-list">
      <div className="path">
        <p style={{ color: "#ABABAB", fontWeight: 600 }}>Courses</p>
      </div>
      
      <div className="topbar">
        <SearchBar searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
        <StatusFilter filterStatus={filterStatus} setFilterStatus={setFilterStatus} />
        <ViewModeSelect viewMode={viewMode} setViewMode={setViewMode} />
      </div>

      <div className="table-content">
        {loading ? (
          <div className="list-loading-container">
            <div className="loading-spinner"></div>
            <p className="loading-text">Loading courses...</p>
          </div>
        ) : filteredCourses.length === 0 ? (
          <div className="no-results">
            <p>{getNoResultsMessage()}</p>
          </div>
        ) : (
          <>
            <div className={viewMode === 'list' ? 'courses-grid' : 'courses-grid-view'}>
              {currentCourses.map((course) => (
                viewMode === 'list' ? (
                  <CourseCard key={course.m_course_id} course={course} viewMode={viewMode} />
                ) : (
                  <CourseGridCard key={course.m_course_id} course={course} />
                )
              ))}
            </div>
            
            <Pagination
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              totalPages={totalPages}
              startIndex={startIndex}
              endIndex={endIndex}
              totalItems={totalItems}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default CourseList;
