import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Splash from './pages/Splash/splash';
import LoginPage from './pages/Login/login';
import SignUpPage from './pages/SignUp/sign_up';
import StudentSignUp from './pages/StudentSignUp/student_signup';
import TeacherSignUp from './pages/TeacherSignUp/teacher_signup';
import UploadImg from './pages/TeacherSignUp/upload_img';
import VerifyCodePage from './pages/VerifyEmail/verify_email';
import AdminGeneralDashboard from './pages/Admin/features/Dashboard/adminGeneralDashboard';
import AllUsersDashboard from './pages/Admin/features/AllUsers/adminAllUsersDashboard';
import Profile from './pages/Teacher/Profile/profile';
import TeacherDashBoard from './pages/Teacher/Dashboard/teacherDashboard';
import TeacherSearchUser from './pages/Teacher/SearchUser/searchUser'
import TeacherSearchDetail from './pages/Teacher/TeacherSearchDetail/teacherSearchDetail';
import AdminTeacherDetails from './pages/Admin/features/Teachers/adminTeacherDetails';
import TeacherCourse from './pages/Teacher/Course/course';
import NewCourse from './pages/Teacher/Course/newCourse';
import CourseInfo from './pages/Teacher/Course/currentCourse';
import AdminTeacherDashboard from './pages/Admin/features/Teachers/adminTeacherDashboard'
import AdminCourseDashboard from './pages/Admin/features/Courses/adminCourseDashboard'
import Chatbox from './pages/Chatbox/Chatbox';
import EnterEmailPage from './pages/ResetPassword/EnterEmail/EnterEmailPage.jsx';
import InputNewPassword from './pages/ResetPassword/InputNewPassword/InputNewPassword.jsx';
import LessonInfo from './pages/Teacher/Lesson/currentLesson.jsx';
import NewGrammarLesson from './pages/Teacher/Lesson/GrammarLesson/NewGrammarLesson.jsx';
import GrammarLesson from './pages/Teacher/Lesson/GrammarLesson/GrammarLesson.jsx';
import NewVocabularyLesson from './pages/Teacher/Lesson/VocabularyLesson/NewVocabularyLesson.jsx';
import VocabularyLesson from './pages/Teacher/Lesson/VocabularyLesson/VocabularyLesson.jsx';
import EditGrammarContent from './pages/Teacher/Lesson/GrammarLesson/EditGrammarContent.jsx';
import QuizLesson from './pages/Teacher/Lesson/QuizLesson/QuizLesson.jsx';
import NewQuizLesson from './pages/Teacher/Lesson/QuizLesson/newQuizLesson.jsx';
import ChatPage from './pages/Chat/ChatPage.jsx';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Splash />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/signup" element={<SignUpPage />} />
        <Route path="/student-signup" element={<StudentSignUp />} />
        <Route path="/teacher-signup" element={<TeacherSignUp />} />
        <Route path="/upload-image" element={<UploadImg />} />
        <Route path="/verify-email" element={<VerifyCodePage />} />
        <Route path="/enter-email" element={<EnterEmailPage />} />
        <Route path="/new-password" element={<InputNewPassword />} />
        <Route path="/admin/" >
          <Route path="dashboard" element={<AdminGeneralDashboard />} />
          <Route path="all-users" element={<AllUsersDashboard />} />
          <Route path="teachers" element={<AdminTeacherDashboard />} />
          <Route path="teacher/:id" element={<AdminTeacherDetails />} />
          <Route path="courses" element={<AdminCourseDashboard />} />
        </Route>
        <Route path="/teacher/">
          <Route path="profile" element={<Profile />} />
          <Route path="dashboard" element={<TeacherDashBoard />} />
          <Route path="search-users" element={<TeacherSearchUser />} />
          <Route path="/teacher/teacher-detail/:id" element={<TeacherSearchDetail />} />
          <Route path="course" element={<TeacherCourse />} />
          <Route path="course/newCourse" element={<NewCourse />} />
          <Route path="course/:id" element={<CourseInfo />} />
          <Route path="lesson/:id" element={<LessonInfo />} />
          <Route path="new-grammar-lesson" element={<NewGrammarLesson />} />
          <Route path="grammar-lesson/:id" element={<GrammarLesson />} />
          <Route path="grammar-lesson/:id/edit" element={<EditGrammarContent />} />
          <Route path="new-vocabulary-lesson" element={<NewVocabularyLesson />} />
          <Route path="vocabulary-lesson/:id" element={<VocabularyLesson />} />
          <Route path="new-quiz-lesson" element={<NewQuizLesson />} />
          <Route path="quiz-lesson/:id" element={<QuizLesson />} />
          <Route path="message" element={<ChatPage />} />
        </Route>
        <Route path="/chatbox" element={<Chatbox />} />
    </Routes>
</Router>
  );
};
export default App;
