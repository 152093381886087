import ProfileHeader from "../../../components/ProfileHeader/profileHeader";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import './currentLesson.css';
import { useState, useEffect } from "react";
import axios from "axios";
import { useAuth } from "../../../AuthContext";
import SuccessModal from "../../../components/SuccessModal";

const LessonInfo = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [lesson, setLesson] = useState(null);
    const [showConfirm, setShowConfirm] = useState(false);
    const { accessToken } = useAuth();
    const [showEditForm, setShowEditForm] = useState(false);
    const [newLessonTitle, setNewLessonTitle] = useState("");
    const [newLessonContent, setNewLessonContent] = useState("");
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const { state } = useLocation();
    const lessonType = state?.lessonType;

    useEffect(() => {
        if (!accessToken) {
            navigate('/login');
            return;
        }
    
        const fetchUserProfile = async () => {
            try {
                const response = await axios.get(
                    `${process.env["REACT_APP_BASE_API"]}/api/me/`,
                    {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    }
                );
                setUser(response.data);
            } catch (error) {
                setError("Lỗi khi tải thông tin người dùng.");
            } finally {
                setLoading(false);
            }
        };
    
        fetchUserProfile();
    }, [accessToken]);
    
    useEffect(() => {
        if (!accessToken || !id) return;
    
        const fetchLesson = async () => {
            setLoading(true);
            try {
                let response
                if(lessonType == "VOCABULARY") {
                    response = await axios.get(
                        `${process.env["REACT_APP_BASE_API"]}/api/course/getVocabularyLesson/`,
                        {
                            headers: {
                                Authorization: `Bearer ${accessToken}`,
                            },
                            params: {
                                lesson_id: id
                            }
                        }
                    );
                } else {
                    response = await axios.get(
                        `${process.env["REACT_APP_BASE_API"]}/api/course/getGrammarLesson/`,
                        {
                            headers: {
                                Authorization: `Bearer ${accessToken}`,
                            },
                            params: {
                                lesson_id: id
                            }
                        }
                    );
                }
    
                const lesson = response.data.lesson
                if (lesson) {
                    setLesson(lesson);
                    setNewLessonTitle(lesson.m_title);
                    setNewLessonContent(lesson.m_content);
                } else {
                    setError("Không tìm thấy bài học.");
                }
            } catch (error) {
                setError("Lỗi khi tải thông tin bài học.");
            } finally {
                setLoading(false);
            }
        };
    
        if (user) {
            fetchLesson();
        }
    }, [accessToken, id, user]);

    const handleDeleteLesson = async () => {
        try {
            await axios.delete(`${process.env.REACT_APP_BASE_API}/api/course/deleteLesson/`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
                data: {
                    m_lesson_id: id
                }
            });
            alert("Xóa khóa học thành công")
            navigate(`/teacher/course/${lesson.m_course_id}`);
        } catch (error) {
            setError("Lỗi khi xóa bài học.");
        }
    };

    const handleEditLesson = async () => {
        try {
            await axios.put(`${process.env.REACT_APP_BASE_API}/api/course/updateLesson/`, {
                m_lesson_id: id,
                m_title: newLessonTitle,
                m_content: newLessonContent
            }, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                }
            });
            setShowEditForm(false);
            setShowSuccessModal(true);
        } catch (error) {
            setError("Lỗi khi chỉnh sửa bài học.");
        }
    };

    if (error) return <p>{error}</p>;

    return (
        <div className="lesson-dashboard-container">
            <ProfileHeader></ProfileHeader>
            {showSuccessModal && (
                <SuccessModal 
                    onClose={() => setShowSuccessModal(false)} 
                    message="Thành công!" 
                />
            )}
            <div className="lesson-headerinfo">
                <img src="https://photographylife.com/wp-content/uploads/2023/05/Nikon-Z8-Official-Samples-00002.jpg" alt="Avatar" className="lesson-headerinfo-img"></img>
                <div className="cc-lesson-info">
                    <div className="cc-lesson-info-header">
                        <div className="cc-lesson-info-title">{lesson ? lesson.m_title : "Tên bài học"}</div>
                        <div className="cc-button-group">
                            <div className="cc-button-blue" onClick={() => setShowEditForm(true)}>Chỉnh sửa</div>
                            <div className="cc-button-redborder" onClick={() => setShowConfirm(true)}>Xóa</div>
                        </div>
                    </div>
                    <div className="cc-lesson-info-description">{lesson ? lesson.m_content : "Nội dung bài học"}</div>
                </div>
            </div>

            {showConfirm && (
                <>
                    <div className="overlay"></div>
                    <div className="confirm-dialog">
                        <p>Bạn có chắc chắn muốn xóa bài học này không?</p>
                        <button className="confirm-button cancel" onClick={() => setShowConfirm(false)}>Hủy</button>
                        <button className="confirm-button confirm" onClick={handleDeleteLesson}>Xác nhận</button>
                    </div>
                </>
            )}

            {showEditForm && (
                <>
                    <div className="overlay"></div>
                    <div className="edit-lesson-form">
                        <h2>Chỉnh sửa bài học</h2>
                        <input 
                            type="text" 
                            placeholder="Tiêu đề bài học" 
                            value={newLessonTitle} 
                            onChange={(e) => setNewLessonTitle(e.target.value)} 
                            style={{marginBottom: "10px", width: "100%", height: "30px"}}
                        />
                        <textarea 
                            placeholder="Nội dung bài học" 
                            value={newLessonContent} 
                            onChange={(e) => setNewLessonContent(e.target.value)} 
                            style={{marginBottom: "10px", width: "100%", height: "180px", fontFamily: "Arial"}}
                        />
                        <div className="form-buttons">
                            <button className="confirm-button-cancel" onClick={() => setShowEditForm(false)}>Hủy</button>
                            <button className="confirm-button-confirm" onClick={handleEditLesson}>Lưu</button>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}

export default LessonInfo;