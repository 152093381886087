import React from 'react';
import FilterAltIcon from '@mui/icons-material/FilterAlt';

const StatusFilter = ({ filterStatus, setFilterStatus }) => {
  return (
    <div className="filter-container">
      <div className="filter-button">
        <FilterAltIcon className="filter-icon" />
        <select
          value={filterStatus}
          onChange={(e) => setFilterStatus(e.target.value)}
          className="filter-select"
        >
          <option value="">All Statuses</option>
          <option value="public">Public</option>
          <option value="private">Private</option>
        </select>
      </div>
    </div>
  );
};

export default StatusFilter;
