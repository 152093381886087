import React, { useCallback } from 'react';
import AWS from 'aws-sdk';
import './VocabularyModal.css';

// Thêm cấu hình S3
const s3 = new AWS.S3({
  accessKeyId: process.env["REACT_APP_AWS_ACCESS_KEY_ID"],
  secretAccessKey: process.env["REACT_APP_AWS_SECRET_ACCESS_KEY"],
  region: "ap-southeast-1",
});

const VocabularyModal = ({
  isOpen,
  title,
  vocabulary,
  formErrors,
  isEditing,
  onClose,
  onSave,
  onInputChange,
}) => {
  if (!isOpen) return null;

  // Thêm hàm upload
  const uploadToS3 = async (file) => {
    const params = {
      Bucket: "ivsenglishapp",
      Key: `vocabulary/${Date.now()}-${file.name}`,
      Body: file,
    };
    try {
      const data = await s3.upload(params).promise();
      return data.Location;
    } catch (error) {
      console.error('Error uploading to S3:', error);
      throw new Error('Upload failed');
    }
  };

  // Xử lý khi click nút upload
  const handleImageUpload = async () => {
    // Tạo input file tạm thời
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.accept = 'image/*';
    
    fileInput.onchange = async (e) => {
      const file = e.target.files[0];
      if (file) {
        try {
          const imageUrl = await uploadToS3(file);
          onInputChange("m_vocab_image", imageUrl);
        } catch (error) {
          console.error('Upload failed:', error);
        }
      }
    };

    // Trigger click để mở dialog chọn file
    fileInput.click();
  };

  return (
    <div className="vocabulary-modal-overlay">
      <div className="vocabulary-modal">
        <h2>{title}</h2>
        <div className="vocabulary-modal-content">
          <div className="vocabulary-modal-field">
            <label>
              Word <span className="vocabulary-required">*</span>
            </label>
            <input
              type="text"
              value={vocabulary.m_english_word}
              onChange={(e) => {
                onInputChange("m_english_word", e.target.value);
              }}
              placeholder="Please enter word"
              className={formErrors.m_english_word ? "vocabulary-error" : ""}
            />
            {formErrors.m_english_word && (
              <span className="vocabulary-error-icon" />
            )}
            {formErrors.m_english_word && (
              <span className="vocabulary-error-message">
                Please enter word
              </span>
            )}
          </div>

          <div className="vocabulary-modal-field">
            <label>
              Meaning <span className="vocabulary-required">*</span>
            </label>
            <input
              type="text"
              value={vocabulary.m_definition}
              onChange={(e) => onInputChange("m_definition", e.target.value)}
              placeholder="Please enter meaning"
              className={formErrors.m_definition ? "vocabulary-error" : ""}
            />
            {formErrors.m_definition && (
              <span className="vocabulary-error-icon" />
            )}
            {formErrors.m_definition && (
              <span className="vocabulary-error-message">
                Please enter meaning
              </span>
            )}
          </div>

          <div className="vocabulary-modal-field">
            <label>
              Transcription <span className="vocabulary-required">*</span>
            </label>
            <input
              type="text"
              value={vocabulary.m_transcription}
              onChange={(e) => onInputChange("m_transcription", e.target.value)}
              placeholder="Please enter transcription"
              className={formErrors.m_transcription ? "vocabulary-error" : ""}
            />
            {formErrors.m_transcription && (
              <span className="vocabulary-error-icon" />
            )}
            {formErrors.m_transcription && (
              <span className="vocabulary-error-message">
                Please enter transcription
              </span>
            )}
          </div>

          <div className="vocabulary-modal-field">
            <label>Example 1</label>
            <input
              type="text"
              value={vocabulary.m_example_1}
              onChange={(e) => onInputChange("m_example_1", e.target.value)}
              placeholder="Please enter example 1"
            />
          </div>

          <div className="vocabulary-modal-field">
            <label>Example 2</label>
            <input
              type="text"
              value={vocabulary.m_example_2}
              onChange={(e) => onInputChange("m_example_2", e.target.value)}
              placeholder="Please enter example 2"
            />
          </div>

          <div className="vocabulary-modal-field vocabulary-modal-image-input">
            <label>Image</label>
            <input
              type="text"
              value={vocabulary.m_vocab_image}
              onChange={(e) => onInputChange("m_vocab_image", e.target.value)}
              placeholder="Image URL"
            />
            <button 
              className="vocabulary-modal-upload"
              onClick={handleImageUpload}
            >
              Upload +
            </button>
          </div>
        </div>

        <div className="vocabulary-modal-actions">
          <button
            className="vocabulary-modal-cancel"
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            className="vocabulary-modal-save"
            onClick={onSave}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default VocabularyModal;