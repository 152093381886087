import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import { useDropzone } from "react-dropzone";
import "./EditImageModal.css";

const EditImageModal = ({
  open,
  onClose,
  section,
  onUpdate,
  singleImageMode,
  currentImages,
}) => {
  const [newImages, setNewImages] = useState([]);
  const [previews, setPreviews] = useState([]);

  const totalImages = newImages.length + currentImages.length;
  const remainingSlots = 10 - totalImages;
  const maxFiles = remainingSlots > 0 ? remainingSlots : 0;

  useEffect(() => {
    return () => {
      previews.forEach((preview) => URL.revokeObjectURL(preview));
    };
  }, [previews]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [".jpeg", ".jpg", ".png"],
    },
    onDrop: (acceptedFiles) => {
      const newPreviews = acceptedFiles.map((file) =>
        URL.createObjectURL(file)
      );
      setPreviews((prev) => [...prev, ...newPreviews]);
      setNewImages((prev) => [...prev, ...acceptedFiles]);
    },
    multiple: !singleImageMode,
    maxFiles: maxFiles,
  });

  const handleRemoveImage = (previewUrl) => {
    const index = previews.indexOf(previewUrl);
    if (index !== -1) {
      URL.revokeObjectURL(previewUrl);
      setPreviews((prev) => prev.filter((preview) => preview !== previewUrl));
      setNewImages((prev) => prev.filter((_, i) => i !== index));
    }
  };

  const handleSave = () => {
    if (newImages.length > 0) {
      if (currentImages.length + newImages.length > 10) {
        alert("Tổng số lượng ảnh không được vượt quá 10 tấm.");
        return;
      }
      onUpdate(section, newImages);
      setPreviews([]);
      setNewImages([]);
      onClose();
    }
  };

  const handleClose = () => {
    previews.forEach((preview) => URL.revokeObjectURL(preview));
    setPreviews([]);
    setNewImages([]);
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="md"
      PaperProps={{
        style: {
          width: "800px",
          margin: 16,
          borderRadius: 12,
          overflow: "hidden",
        },
      }}
    >
      <DialogTitle>
        Update {section.charAt(0).toUpperCase() + section.slice(1)} Images
      </DialogTitle>
      <DialogContent>
        <div
          className={`upload-content ${
            previews.length === 0 ? "no-previews" : ""
          }`}
        >
          <div {...getRootProps()} className="dropzone">
            <input {...getInputProps()} />
            <p>Drag and drop images here, or click to select</p>
            <p>Supported formats: JPG, JPEG, PNG</p>
            <p>
              Số lượng ảnh còn lại: {remainingSlots > 0 ? remainingSlots : 0}
            </p>
          </div>

          {previews.length > 0 && (
            <div className="previews-grid">
              {previews.map((preview) => (
                <div key={preview} className="preview-wrapper">
                  <img src={preview} alt="Preview" className="preview-image" />
                  <button
                    className="remove-image-button"
                    onClick={() => handleRemoveImage(preview)}
                    type="button"
                  >
                    ×
                  </button>
                </div>
              ))}
            </div>
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button
          onClick={handleSave}
          color="primary"
          disabled={newImages.length === 0}
          variant="contained"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditImageModal;
