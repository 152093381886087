import React from 'react';
import './CourseCardWhiteBig.css';
import person_icon_1 from '../../../assets/following.png';

const truncateSubtitle = (subtitle) => {
    return subtitle.length > 60 ? subtitle.substring(0, 60) + '...' : subtitle;
};

const CourseCardWhiteBig = ({ title, subtitle, userCount, author, onClick }) => {
    return (
        <div className="ea-90-course-card" onClick={onClick}>
            <img className='ea90-coursethumbnail' src='https://wallpapers.com/images/hd/windows-default-background-p8fcubp4h5xlu78i.jpg' alt='Course Thumbnail' />
            <div className="ea90-course-title">{title}</div>
            <div className="ea90-course-subtitle">{truncateSubtitle(subtitle)}</div>
            <div className="ea90-course-info">
                <span >
                    <img className='ea90-usericon' src={person_icon_1} alt='User Icon' />
                    {userCount} 
                </span>
                <span >{author}</span>
            </div>
        </div>
    );
};

export default CourseCardWhiteBig;