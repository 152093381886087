import React, { useEffect, useState } from "react";
import axios from "axios";
import SearchIcon from "@mui/icons-material/Search";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../../AuthContext";
import "./TeacherList.css";

const TeacherList = ({ pendingTeacher }) => {
  const [allTeachers, setAllTeachers] = useState([]);
  const pendingTeachers = pendingTeacher;
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");   
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [filterStatus, setFilterStatus] = useState(""); 
  const [activeTab, setActiveTab] = useState("allTeachers");
  const pendingCount = pendingTeachers.length;

  const itemsPerPage = 10;

  const navigate = useNavigate();

  const { accessToken } = useAuth();

  useEffect(() => {
    const fetchAllTeachers = async () => {
      try {
        const response = await axios.get(
          `${process.env["REACT_APP_BASE_API"]}/api/admin/getAllTeacher/`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        setAllTeachers(response.data);
      } catch (error) {
        setError("Lỗi khi tải dữ liệu.");
      } finally {
        setLoading(false);
      }
    };
    if (accessToken) {
      fetchAllTeachers();
    }
  }, [accessToken]);

  const toggleOptions = () => {
    if (isOpenFilter) setFilterStatus("");
    setIsOpenFilter(!isOpenFilter);
  };

  const handleViewDetail = (e, id) => {
    e.preventDefault();
    if (!accessToken) {
      navigate("/login");
      return;
    }
    navigate(`/admin/teacher/${id}`);
  };

  const teachers = activeTab === "allTeachers" ? allTeachers : pendingTeachers;

  const filteredTeachers = teachers.filter((teacher) => {
    const matchesSearch = teacher.m_user_name
      .toLowerCase()
      .includes(searchTerm.toLowerCase());
    const matchesStatus = filterStatus
      ? teacher.m_status === filterStatus
      : true;
    return matchesSearch && matchesStatus;
  });

  const totalItems = filteredTeachers.length; 
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage + 1;
  const endIndex = Math.min(startIndex + itemsPerPage - 1, totalItems);

  const handlePageClick = (page) => {
    if (page < 1 || page > totalPages) return;
    setCurrentPage(page);
  };


  const currentTeachers = filteredTeachers.slice(startIndex - 1, endIndex);

  if (error) return <div>{error}</div>;

  return (
    <div className="teacher-list">
      <div className="path">
        <p style={{ color: "#ABABAB", fontWeight: 600 }}>Users / </p>
        <p style={{ color: "#3D8FEF", fontWeight: 600, marginLeft: 5 }}>
          {" "}
          Teachers
        </p>
      </div>
      <div className="tabs-container">
        <div
          className={`tab ${activeTab === "allTeachers" ? "active" : ""}`}
          onClick={() => setActiveTab("allTeachers")}
        >
          All Teachers
        </div>
        <div
          className={`tab ${activeTab === "teachersPending" ? "active" : ""}`}
          onClick={() => setActiveTab("teachersPending")}
        >
          Teachers Pending
          <span className="badge">{pendingCount}</span>
        </div>
      </div>
      <div className="topbar">
        <div className="search-container">
          <SearchIcon className="search-icon" />
          <input
            type="text"
            placeholder="Tìm kiếm username"
            className="search-input"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        <div className="ea99-filter-container">
          <div className="ea99-filter-button" onClick={toggleOptions}>
            <FilterAltIcon className="filter-icon" />
            <p>Lọc</p>
          </div>
        </div>
        {isOpenFilter && (
          <select
            value={filterStatus}
            onChange={(e) => setFilterStatus(e.target.value)}
            className="ea99-filter-select"
          >
            <option value="">All Statuses</option>
            <option value="ACTIVE">Active</option>
            <option value="IN_ACTIVE">Pending</option>
            <option value="REJECTED">Rejected</option>
          </select>
        )}
      </div>
      <div className="table-content">
        <table>
          <caption className="table-caption">Teachers List</caption>
          <thead>
            <tr>
              <th>ID user</th>
              <th>Username</th>
              <th>Gmail</th>
              <th>Full Name</th>
              <th>Birtday</th>
              <th>View detail</th>
            </tr>
          </thead>
          <tbody>
            {currentTeachers.map((teacher) => (
              <tr key={teacher.m_id}>
                <td>{teacher.m_id}</td>
                <td>{teacher.m_user_name}</td>
                <td>{teacher.m_email || "N/A"}</td>
                <td>{teacher.m_name || "N/A"}</td>
                <td>{teacher.m_birthdate}</td>
                <td>
                  <button
                    onClick={(e) => handleViewDetail(e, teacher.m_id)}
                    className="view-detail"
                    style={{ cursor: "pointer" }}
                  >
                    View detail
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="pagination-controls">
          <div className="index">
            {startIndex === endIndex ? startIndex : `${startIndex}-${endIndex}`}{" "}
            in {totalItems}
          </div>
          <div>
            <button
              onClick={() => handlePageClick(currentPage - 1)}
              disabled={currentPage === 1}
              style={{ fontSize: 20 }}
            >
              &#8249;
            </button>
            {Array.from({ length: totalPages }, (_, i) => (
              <button
                key={i + 1}
                onClick={() => handlePageClick(i + 1)}
                className={currentPage === i + 1 ? "active" : ""} // Thêm class active cho trang hiện tại
              >
                {i + 1}
              </button>
            ))}
            <button
              onClick={() => handlePageClick(currentPage + 1)}
              disabled={currentPage === totalPages} // Vô hiệu hóa nếu đang ở trang cuối
              style={{ fontSize: 20 }}
            >
              &#8250;
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeacherList;
