import React from 'react';

const Path = () => {
  return (
    <div className="allusers-path">
      <p style={{ color: "#ABABAB", fontWeight: 600 }}>Users / </p>
      <p style={{ color: "#3D8FEF", fontWeight: 600, marginLeft: 5 }}>
        All users
      </p>
    </div>
  );
};

export default Path;