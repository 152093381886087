import React from 'react';
import SearchIcon from "@mui/icons-material/Search";
import Autocomplete from "@mui/material/Autocomplete";

const SearchBar = ({
  autoCompleteKey,
  searchResults,
  isOpen,
  selectedOption,
  setIsOpen,
  searchTerm,
  setSearchTerm,
  setSelectedOption,
  handleKeyPress,
  handleUserSelection,
}) => {
  return (
    <div className="topbar">
      <Autocomplete
        key={autoCompleteKey}
        freeSolo
        options={searchResults}
        open={isOpen}
        value={selectedOption}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        getOptionLabel={(option) =>
          typeof option === "string" ? option : option.username
        }
        getOptionDisabled={(option) => option.id === -1}
        autoHighlight
        autoSelect
        disablePortal={false}
        componentsProps={{
          paper: {
            sx: {
              backgroundColor: "white",
            },
          },
        }}
        renderOption={(props, option) => {
          // Extract key from props and use remaining props
          const { key, ...otherProps } = props;
          return (
            <li
              key={option.id || key} // Use option.id as key if available, fallback to provided key
              {...otherProps}
              style={{
                cursor: option.id === -1 ? "not-allowed" : "pointer",
                color: option.id === -1 ? "#1a1a1a" : "inherit",
              }}
            >
              {option.username}
            </li>
          );
        }}
        renderInput={(params) => (
          <div ref={params.InputProps.ref} className="search-container">
            <SearchIcon className="search-icon" />
            <input
              type="text"
              {...params.inputProps}
              placeholder="Tìm kiếm username"
              className="search-input-all-user"
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value);
                setSelectedOption(null);
              }}
              onKeyPress={(e) =>
                handleKeyPress(
                  e,
                  searchResults.length === 1 && searchResults[0].id === -1
                )
              }
            />
          </div>
        )}
        onChange={(event, newValue) => {
          if (newValue && newValue.id !== -1) {
            handleUserSelection(newValue);
          }
        }}
      />
    </div>
  );
};

export default SearchBar;