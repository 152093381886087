import axios from "axios";
class ValidateRegister {
    static validate_username(username) {
        if (username.length < 6) {
            return "too_short";
        }
        if (username.length > 30) {
            return "too_long";
        }
        if (!/^[a-zA-Z0-9_]+$/.test(username)) {
            return "invalid_characters";
        }
        return "valid";
    }

    static validate_password(password) {
        if (password.length < 8 || password.length > 64) {
            return "invalid_length";
        }
        if (/\s/.test(password)) {
            return "contains_space";
        }
        if (!/[A-Z]/.test(password)) {
            return "no_uppercase";
        }
        if (!/[a-z]/.test(password)) {
            return "no_lowercase";
        }
        if (!/\d/.test(password)) {
            return "no_digit";
        }
        if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
            return "no_special_char";
        }
        return "valid";
    }

    static async is_unique_infor(username, email) {
        try {
            const response = await axios.post(`${process.env["REACT_APP_BASE_API"]}/api/precheck-register/`, {
                username: username,
                email: email
            });
    
            if (response.status === 200) {
                return "valid";
            }
        } catch (error) {
            if (error.response) {
                const errorMessage = error.response.data.error;
    
                if (errorMessage === "Tên đăng nhập đã tồn tại.") {
                    return "username_invalid"; 
                } else if (errorMessage === "Email đã được sử dụng.") {
                    return "email_invalid"; 
                }
            } else {
                console.error("Error:", error.message);
                return "unknown_error"; // Lỗi không xác định
            }
        }
    
        return "unknown_error"; // Trả về lỗi nếu không có phản hồi phù hợp
    }
}

export default ValidateRegister;
