import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import Sidebar from '../../components/Sidebar/adminSidebar';
import CourseList from "./CourseList";
import { useAuth } from '../../../../AuthContext';

const AdminCourseDashboard = () => {
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterStatus, setFilterStatus] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const { accessToken } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!accessToken) {
      navigate('/login');
    }
  }, [accessToken, navigate]);
  

  const handleFilterChange = (newStatus) => {
    setLoading(true);
    setFilterStatus(newStatus);
    setCurrentPage(1);
  };

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        let url = `${process.env["REACT_APP_BASE_API"]}/api/course/getAllCourses/`;
        if (filterStatus) {
          url += `?status=${filterStatus}`;
        }
        
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        setCourses(response.data);
      } catch (error) {
        setError("Error loading courses.");
      } finally {
        setLoading(false);
      }
    };

    if (accessToken) {
      fetchCourses();
    }
  }, [accessToken, filterStatus]);

  if (error) return <div className="error-message">{error}</div>;

  return (
    <div className="app">
      <Sidebar />
      <div className="main-content">
        <CourseList 
          courses={courses}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          filterStatus={filterStatus}
          setFilterStatus={handleFilterChange}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          loading={loading}
        />
      </div>
    </div>
  );
};

export default AdminCourseDashboard;
