import React, { useState, useRef } from 'react';
import ProfileHeader from '../../../../components/ProfileHeader/profileHeader';
import VocabularyHeader from './components/VocabularyHeader/VocabularyHeader';
import VocabularyModal from './components/VocabularyModal/VocabularyModal';
import VocabularyItem from './components/VocabularyItem/VocabularyItem';
import defaultBackground from "../../../../assets/bg_teacher_detail.jpg";
import "./NewVocabularyLesson.css";
import { useAuth } from '../../../../AuthContext';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import AWS from 'aws-sdk';

// Thêm cấu hình S3
const s3 = new AWS.S3({
    accessKeyId: process.env["REACT_APP_AWS_ACCESS_KEY_ID"],
    secretAccessKey: process.env["REACT_APP_AWS_SECRET_ACCESS_KEY"],
    region: "ap-southeast-1",
});

// Thêm hàm uploadToS3
const uploadToS3 = async (file) => {
    const params = {
        Bucket: "ivsenglishapp",
        Key: `lessons/${Date.now()}-${file.name}`,
        Body: file,
    };
    try {
        const data = await s3.upload(params).promise();
        return data.Location;
    } catch (error) {
        console.error('Error uploading to S3:', error);
        throw new Error('Upload failed');
    }
};

const NewVocabularyLesson = () => {
    const [vocabularyItems, setVocabularyItems] = useState([]);
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [newVocabulary, setNewVocabulary] = useState({
        m_english_word: "",
        m_definition: "",
        m_transcription: "",
        m_example_1: "",
        m_example_2: "",
        m_vocab_image: "",
    });
    const [formErrors, setFormErrors] = useState({
        m_english_word: false,
        m_definition: false,
        m_transcription: false,
    });
    const [isEditingVocab, setIsEditingVocab] = useState(false);
    const { accessToken } = useAuth();
    const { state } = useLocation();
    const navigate = useNavigate();
    const course_id = state?.course_id;
    const courseTitle = state?.courseTitle;
    const [lessonImage, setLessonImage] = useState({
        preview: defaultBackground,
        file: null,
        url: ""
    });
    const fileInputRef = useRef(null);

    const handleAddVocabulary = () => {
        setIsAddModalOpen(true);
    };

    const handleInputChange = (field, value) => {
        setNewVocabulary(prev => ({
            ...prev,
            [field]: value
        }));
        if (formErrors[field]) {
            setFormErrors(prev => ({
                ...prev,
                [field]: false
            }));
        }
    };

    const handleSaveNewVocabulary = () => {
        const errors = {
            m_english_word: !newVocabulary.m_english_word,
            m_definition: !newVocabulary.m_definition,
            m_transcription: !newVocabulary.m_transcription,
        };

        setFormErrors(errors);

        if (Object.values(errors).some(error => error)) {
            return;
        }

        if (isEditingVocab) {
            setVocabularyItems(prev =>
                prev.map(item =>
                    item.tempId === newVocabulary.tempId ? { ...newVocabulary } : item
                )
            );
        } else {
            setVocabularyItems(prev => [...prev, { 
                ...newVocabulary, 
                tempId: Date.now() // Add temporary ID for editing purposes
            }]);
        }

        setNewVocabulary({
            m_english_word: "",
            m_definition: "",
            m_transcription: "",
            m_example_1: "",
            m_example_2: "",
            m_vocab_image: "",
        });
        setIsEditingVocab(false);
        setIsAddModalOpen(false);
    };

    const handleMoveItem = (index, direction) => {
        const newItems = [...vocabularyItems];
        if (direction === "up" && index > 0) {
            [newItems[index], newItems[index - 1]] = [newItems[index - 1], newItems[index]];
        } else if (direction === "down" && index < vocabularyItems.length - 1) {
            [newItems[index], newItems[index + 1]] = [newItems[index + 1], newItems[index]];
        }
        setVocabularyItems(newItems);
    };

    const handleEditVocabItem = (item) => {
        setNewVocabulary({
            ...item,
            tempId: item.tempId
        });
        setIsEditingVocab(true);
        setIsAddModalOpen(true);
    };

    const handleDeleteVocabulary = (index) => {
        setVocabularyItems(prev => prev.filter((_, i) => i !== index));
    };

    const saveVocabulary = async () => {
        if (!title || !description) {
            alert('Vui lòng điền đầy đủ thông tin bài học!');
            return;
        }

        if (vocabularyItems.length === 0) {
            alert('Vui lòng thêm ít nhất một từ vựng!');
            return;
        }

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BASE_API}/api/course/createVocabulary/`,
                {
                    m_title: title,
                    m_course_id: course_id,
                    m_content: description,
                    m_lesson_image: lessonImage.url,
                    vocabulary_items: vocabularyItems.map(item => ({
                        m_english_word: item.m_english_word,
                        m_definition: item.m_definition,
                        m_transcription: item.m_transcription,
                        m_example_1: item.m_example_1,
                        m_example_2: item.m_example_2,
                        m_vocab_image: item.m_vocab_image
                    })),
                },
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            );

            if (response.status === 201) {
                navigate(`/teacher/course/${course_id}`);
            }
        } catch (error) {
            console.error(error);
            alert('Có lỗi xảy ra khi tạo danh sách từ vựng.');
        }
    };

    const handleCancel = () => {
        navigate(-1);
    };

    const handleImageUpload = async (e) => {
        const file = e.target.files[0];
        if (file) {
            try {
                // Tạo preview ngay lập tức
                const preview = URL.createObjectURL(file);
                setLessonImage(prev => ({
                    ...prev,
                    preview: preview,
                    file: file
                }));

                // Upload lên S3
                const imageUrl = await uploadToS3(file);
                setLessonImage(prev => ({
                    ...prev,
                    url: imageUrl
                }));

            } catch (error) {
                console.error('Upload failed:', error);
                alert("Lỗi khi tải ảnh lên");
                // Reset về ảnh mặc định nếu upload thất bại
                setLessonImage({
                    preview: defaultBackground,
                    file: null,
                    url: ""
                });
            }
        }
    };

    const handleChangeImageClick = () => {
        fileInputRef.current.click();
    };

    return (
        <div>
            <div className="vocabulary-lesson-header-newlesson">
                <ProfileHeader />
            </div>
            
            <div className="vocabulary-lesson-wrapper">
                <div className="vocabulary-lesson-navigation">
                    <button className="vocabulary-back-btn" onClick={handleCancel}>
                        BACK
                    </button>
                </div>

                <div className="vocabulary-lesson-container">
                    <div className="vocabulary-header-newlesson">
                    <VocabularyHeader 
                            courseTitle={courseTitle}
                            isNewLesson={true}
                            onSave={saveVocabulary}
                            onCancel={handleCancel}
                    />
                    </div>
                        

                    <div className="lesson-image-container">
                        <img 
                            src={lessonImage.preview} 
                            alt="Lesson background" 
                            className="vocabulary-lesson-background"
                        />
                        <div className="change-image-button">
                            <button 
                                className="btn-change-image"
                                onClick={handleChangeImageClick}
                            >
                                Change Image +
                            </button>
                            <input
                                ref={fileInputRef}
                                type="file"
                                accept="image/*"
                                onChange={handleImageUpload}
                                style={{ display: 'none' }}
                            />
                        </div>
                    </div>

                    <div className="new-vocabulary-lesson-container">
                        <div className="new-vocabulary-lesson-form">
                            
                            <div className="new-vocabulary-lesson-form-group">
                                <label>Tiêu đề<span className="required-mark">*</span></label>
                                <input
                                    type="text"
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                    placeholder="Tên tiêu đề"
                                />
                            </div>
                            
                            <div className="new-vocabulary-lesson-form-group">
                                <label>Description<span className="required-mark">*</span></label>
                                <textarea
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                    placeholder="Descriptions"
                                />
                            </div>
                        </div>

                        <div className="vocabulary-edit-container-newlesson">
                            <div className="vocabulary-edit-header">
                                <h2>Vocabulary Card</h2>
                                <button
                                    className="vocabulary-edit-add-newlesson"
                                    onClick={handleAddVocabulary}
                                >
                                    Add vocabulary
                                </button>
                            </div>

                            <div className="vocabulary-items">
                                {vocabularyItems.map((item, index) => (
                                    <VocabularyItem
                                        key={item.tempId || index}
                                        item={item}
                                        index={index}
                                        isEditing={true}
                                        totalItems={vocabularyItems.length}
                                        onMoveItem={handleMoveItem}
                                        onEdit={handleEditVocabItem}
                                        onDelete={handleDeleteVocabulary}
                                    />
                                ))}
                            </div>
                        </div>
                    </div>

                    <VocabularyModal
                        isOpen={isAddModalOpen}
                        title={isEditingVocab ? "Chỉnh sửa từ vựng" : "Thêm từ mới"}
                        vocabulary={newVocabulary}
                        formErrors={formErrors}
                        isEditing={isEditingVocab}
                        onClose={() => {
                            setIsAddModalOpen(false);
                            setIsEditingVocab(false);
                            setFormErrors({
                                m_english_word: false,
                                m_definition: false,
                                m_transcription: false,
                            });
                            setNewVocabulary({
                                m_english_word: "",
                                m_definition: "",
                                m_transcription: "",
                                m_example_1: "",
                                m_example_2: "",
                                m_vocab_image: "",
                            });
                        }}
                        onSave={handleSaveNewVocabulary}
                        onInputChange={handleInputChange}
                    />
                </div>
            </div>
        </div>
    );
};

export default NewVocabularyLesson;