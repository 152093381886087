import React from "react";
import Modal from "../Modal/viewProfile";

const ViewProfileModal = ({ isOpen, onClose, user }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} user={user}>
      <h2 className="fullname" style={{ marginTop: 20 }}>
        {user.m_name}
      </h2>
      <p className="bio">{user.m_bio || ""}</p>
      <div
        className="profile-info-grid"
        style={{ textAlign: "left", padding: "0 40px" }}
      >
        <div className="info-item">
          <span className="info-label">Fullname</span>
          <span className="info-value">{user.m_name || "---"}</span>
        </div>
        <div className="info-item">
          <span className="info-label">Start Day</span>
          <span className="info-value">{user.startDay || "---"}</span>
        </div>
        <div className="info-item">
          <span className="info-label">Active status</span>
          <span className="info-value">
            <span
              className={`status-button ${
                user.m_status === "ACTIVE" ? "online" : "offline"
              }`}
            >
              <span className="status-dot"></span>
              {user.m_status === "ACTIVE" ? "Online" : "Offline"}
            </span>
          </span>
        </div>
      </div>
    </Modal>
  );
};

export default ViewProfileModal;
