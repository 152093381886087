// CourseContents.jsx

import React, { useState, useEffect } from 'react';
import './CourseContents.css';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../../../AuthContext';
import axios from 'axios';
import ConfirmModal from '../../../Lesson/components/ConfirmModal/ConfirmModal';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import SortableLesson from '../SortableLesson';

const CourseContents = ({ lessons, course }) => {
  const navigate = useNavigate();
  const { accessToken } = useAuth();
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [lessonToDelete, setLessonToDelete] = useState(null);
  const [orderedLessons, setOrderedLessons] = useState(lessons || []);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (Array.isArray(lessons)) {
      setOrderedLessons(lessons);
      setIsLoading(false);
    }
  }, [lessons]);

  const handleDeleteClick = (lesson) => {
    if (!lesson || !lesson.m_lesson_id) {
      console.error('Invalid lesson data');
      return;
    }
    setLessonToDelete(lesson);
    setIsConfirmModalOpen(true);
  };

  const handleDeleteLesson = async () => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_BASE_API}/api/course/deleteLesson/`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          data: {
            m_lesson_id: lessonToDelete.m_lesson_id,
          },
        }
      );

      setIsConfirmModalOpen(false);
      setOrderedLessons((prev) =>
        prev.filter((l) => l.m_lesson_id !== lessonToDelete.m_lesson_id)
      );
    } catch (error) {
      console.error('Error deleting lesson:', error);
      alert('Failed to delete lesson. Please try again.');
    }
  };

  // Setting up sensors for DnD
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragEnd = async (event) => {
    const { active, over } = event;

    if (!over) {
      return;
    }

    if (active.id !== over.id) {
      const oldIndex = orderedLessons.findIndex(
        (lesson) => lesson.m_lesson_id.toString() === active.id
      );
      const newIndex = orderedLessons.findIndex(
        (lesson) => lesson.m_lesson_id.toString() === over.id
      );

      if (oldIndex === -1 || newIndex === -1) {
        console.error('Invalid lesson indices');
        return;
      }

      const newOrderedLessons = arrayMove(orderedLessons, oldIndex, newIndex);
      setOrderedLessons(newOrderedLessons);

      try {
        await axios.put(
          `${process.env.REACT_APP_BASE_API}/api/course/reorderLessons/`,
          {
            course_id: course.m_course_id,
            lesson_order: newOrderedLessons.map((lesson, index) => ({
              lesson_id: lesson.m_lesson_id,
              order: index,
            })),
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
      } catch (error) {
        // Revert to previous order on error
        setOrderedLessons(orderedLessons);
        console.error('Error reordering lessons:', error);
        alert('Failed to save lesson order. Please try again.');
      }
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="course-contents">
      <h1>{course?.m_title}</h1>
      <div className="contents-header">
        <h2>Lessons</h2>
        <div className="content-actions">
          <button
            className="action-button new-quiz"
            onClick={() => {
              if (course) {
                navigate('/teacher/new-quiz-lesson', {
                  state: {
                    course_id: course.m_course_id,
                    courseTitle: course.m_title,
                  },
                });
              }
            }}
          >
            New Quiz
          </button>
          <button
            className="action-button new-grammar"
            onClick={() => {
              if (course) {
                navigate('/teacher/new-grammar-lesson', {
                  state: {
                    course_id: course.m_course_id,
                    courseName: course.m_title,
                  },
                });
              }
            }}
          >
            New Grammar
          </button>
          <button
            className="action-button new-vocabulary"
            onClick={() => {
              if (course) {
                navigate('/teacher/new-vocabulary-lesson', {
                  state: {
                    course_id: course.m_course_id,
                    courseTitle: course.m_title,
                  },
                });
              }
            }}
          >
            New Vocabulary
          </button>
        </div>
      </div>

      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
      >
        <SortableContext
          items={orderedLessons.map((lesson) => lesson.m_lesson_id.toString())}
          strategy={verticalListSortingStrategy}
        >
          <div className="lessons-list">
            {orderedLessons.map((lesson) => (
              <SortableLesson
                key={lesson.m_lesson_id}
                lesson={lesson}
                course={course}
                onDeleteClick={handleDeleteClick}
              />
            ))}
          </div>
        </SortableContext>
      </DndContext>

      {isConfirmModalOpen && lessonToDelete && (
        <ConfirmModal
          isOpen={isConfirmModalOpen}
          onClose={() => setIsConfirmModalOpen(false)}
          onConfirm={handleDeleteLesson}
          title="Delete Lesson"
          message={`Are you sure you want to delete "${lessonToDelete.m_title}"?`}
        />
      )}
    </div>
  );
};

export default CourseContents;
