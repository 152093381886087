import React, { useState } from "react";
import './authHeader.css';
import logo from '../../assets/splash.png';
import { useNavigate } from 'react-router-dom';

const AuthHeader = () => {
    const [dropdown, setDropdown] = useState(null);
    const navigate = useNavigate();

    const handleMouseEnter = (menu) => {
        setDropdown(menu);
    };

    const handleMouseLeave = () => {
        setDropdown(null);
    };

    return (
        <header className="header">
            <div className="logo-section" onClick={() => navigate("/")}>
                <img src={logo} alt='logo' width={32} height={32} className="logo" />
                <h1 className="title">English App</h1>
            </div>

            <nav className="nav">
                <div 
                className="nav-item" 
                onMouseEnter={() => handleMouseEnter('program')}
                onMouseLeave={handleMouseLeave}
                >
                <span>Chương trình học</span>
                {dropdown === 'program' && (
                    <ul className="dropdown">
                    <li><a href="/">Bài học 1</a></li>
                    <li><a href="/">Bài học 2</a></li>
                    <li><a href="/">Bài học 3</a></li>
                    </ul>
                )}
                </div>

                <div 
                className="nav-item" 
                onMouseEnter={() => handleMouseEnter('flashcards')}
                onMouseLeave={handleMouseLeave}
                >
                <span>Flashcards</span>
                {dropdown === 'flashcards' && (
                    <ul className="dropdown">
                    <li><a href="/">Flashcard 1</a></li>
                    <li><a href="/">Flashcard 2</a></li>
                    <li><a href="/">Flashcard 3</a></li>
                    </ul>
                )}
                </div>

                <div 
                className="nav-item" 
                onMouseEnter={() => handleMouseEnter('exchange')}
                onMouseLeave={handleMouseLeave}
                >
                <span>Trao đổi</span>
                {dropdown === 'exchange' && (
                    <ul className="dropdown">
                    <li><a href="/">Trao đổi 1</a></li>
                    <li><a href="/">Trao đổi 2</a></li>
                    <li><a href="/">Trao đổi 3</a></li>
                    </ul>
                )}
                </div>
            </nav>

            <div className="buttons">
                <button className="chatbox-button">Chatbot AI</button>
                <button className="login-auth-button" onClick={() => navigate('/login')}>Đăng nhập</button>
            </div>
        </header>
    );
};

export default AuthHeader;