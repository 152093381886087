import React from "react";
import { useNavigate } from "react-router-dom";

const QuizHeader = ({
  lesson,
  courseTitle,
  isEditing,
  isNewLesson = false,
  onEditLesson,
  onDeleteLesson,
  onSave,
  onCancel,
}) => {
  const navigate = useNavigate();

  return (
    <div className="vocabulary-lesson-header">
      <div className="vocabulary-breadcrumb">
        <span
          className="vocabulary-breadcrumb-link"
          onClick={() => navigate("/teacher/course")}
        >
          Chương trình học
        </span>
        <span>›</span>
        <span
          className="vocabulary-breadcrumb-link"
          onClick={() => navigate("/teacher/course")}
        >
          Quản lý khóa học
        </span>
        <span>›</span>
        <span
          className="vocabulary-breadcrumb-link"
          onClick={() => navigate(-1)}
        >
          {courseTitle}
        </span>
        <span>›</span>
        <span>Quiz lessons</span>
      </div>

      {isNewLesson ? (
        <div>
          <button className="vocabulary-lesson-edit-btn" onClick={onSave}>
            Save
          </button>
          <button className="vocabulary-lesson-delete-btn" onClick={onCancel}>
            Cancel
          </button>
        </div>
      ) : (
        !isEditing && (
          <div>
            <button
              className="vocabulary-lesson-edit-btn"
              onClick={onEditLesson}
            >
              Edit
            </button>
            <button
              className="vocabulary-lesson-delete-btn"
              onClick={onDeleteLesson}
            >
              Delete
            </button>
          </div>
        )
      )}
      {isEditing && (
        <div>
          <button className="vocabulary-lesson-edit-btn" onClick={onSave}>
            Save
          </button>
          <button
            className="vocabulary-lesson-delete-btn"
            onClick={onDeleteLesson}
          >
            Delete
          </button>
        </div>
      )}
    </div>
  );
};

export default QuizHeader;
