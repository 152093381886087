import React from 'react';

const Pagination = ({ 
  currentPage, 
  setCurrentPage, 
  totalPages, 
  startIndex, 
  endIndex, 
  totalItems 
}) => {
  return (
    <div className="pagination-controls">
      <div className="index">
        {startIndex === endIndex ? startIndex : `${startIndex + 1}-${endIndex}`} in {totalItems}
      </div>
      <div className="pagination-buttons">
        <button
          onClick={() => setCurrentPage(currentPage - 1)}
          disabled={currentPage === 1}
          style={{ fontSize: 20 }}
        >
          &#8249;
        </button>
        {[...Array(totalPages)].map((_, i) => (
          <button
            key={i + 1}
            onClick={() => setCurrentPage(i + 1)}
            className={currentPage === i + 1 ? "active" : ""}
          >
            {i + 1}
          </button>
        ))}
        <button
          onClick={() => setCurrentPage(currentPage + 1)}
          disabled={currentPage === totalPages}
          style={{ fontSize: 20 }}
        >
          &#8250;
        </button>
      </div>
    </div>
  );
};

export default Pagination;