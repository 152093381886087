import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { useNavigate } from 'react-router-dom';
import { CSS } from '@dnd-kit/utilities';

const SortableLesson = ({ lesson, course, onDeleteClick }) => {
  const navigate = useNavigate();

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: lesson.m_lesson_id.toString() });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1,
  };

  // Create a drag handle component
  const DragHandle = () => (
    <div className="drag-handle" {...listeners}>
      ⋮⋮
    </div>
  );

  const handleEditClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (lesson.m_type === 'VOCABULARY') {
      navigate(`/teacher/vocabulary-lesson/${lesson.m_lesson_id}`, {
        state: { courseTitle: course.m_title },
      });
    } else if (lesson.m_type === 'GRAMMAR') {
      navigate(`/teacher/grammar-lesson/${lesson.m_lesson_id}`, {
        state: { courseTitle: course.m_title },
      });
    } else {
      navigate(`/teacher/quiz-lesson/${lesson.m_lesson_id}`, {
        state: { courseTitle: course.m_title },
      });
    }
  };

  const handleDeleteClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (onDeleteClick) {
      onDeleteClick(lesson);
    }
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      className={`lesson-card ${isDragging ? 'dragging' : ''}`}
      {...attributes}
    >
      <DragHandle />
      <div className="lesson-type">
        {lesson.m_type === 'VOCABULARY'
          ? 'Vocabulary'
          : lesson.m_type === 'GRAMMAR'
          ? 'Grammar'
          : 'Quiz'}
      </div>
      <div className="lesson-status">
        <span className={`status-badge-lesson ${lesson.m_status?.toLowerCase()}`}>
          {lesson.m_status}
        </span>
      </div>
      <div className="lesson-title">{lesson.m_title}</div>
      <div className="lesson-description">
        {lesson.m_content.length > 60
          ? `${lesson.m_content.substring(0, 60)}...`
          : lesson.m_content}
      </div>
      <div className="lesson-actions" onClick={(e) => e.stopPropagation()}>
        <button className="edit-lesson" onClick={handleEditClick}>
          ✎
        </button>
        <button className="delete-lesson" onClick={handleDeleteClick}>
          ×
        </button>
      </div>
    </div>
  );
};

export default SortableLesson;
