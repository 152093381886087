import React, { useEffect } from "react";
import "./splash.css";
import AuthHeader from "../../components/AuthHeader/authHeader";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const Splash = () => {
  const navigate = useNavigate();
  const accessToken = localStorage.getItem("accessToken"); // Lấy accessToken từ localStorage hoặc nguồn khác

  useEffect(() => {
    if (accessToken) {
      // Gọi API /me để lấy thông tin role
      axios
        .get(`${process.env["REACT_APP_BASE_API"]}/api/me/`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
        .then((response) => {
          if (response.data.role === "admin") {
            navigate("/admin/dashboard");
          } else if (
            response.data.role === "teacher" ||
            response.data.role === "student"
          ) {
            navigate("/teacher/dashboard");
          }
        })
        .catch((error) => {
          console.error("Error fetching user data:", error);
        });
    }
  }, [accessToken, navigate]);

  return (
    <div className="splash">
      <AuthHeader />
      <div className="content-splash">
        <img
          src={require("../../assets/splash.png")}
          title="Logo"
          className="image-splash"
          alt="Logo"
        />
        <h1 className="title-splash">English App</h1>
        <h3 className="subtitle-splash">
          Học một ngôn ngữ mới - Sống một cuộc đời mới
        </h3>
      </div>
      <footer className="footer-splash">@IVS Joint Stock Company</footer>
    </div>
  );
};

export default Splash;
