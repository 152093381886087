import React from "react";

const GeneralInformation = ({ user, rejectionReason }) => {
  const infoItems = [
    { label: "ID User", value: user.m_id },
    { label: "Username", value: user.m_user_name },
    { label: "Email", value: user.m_email },
    { label: "Fullname", value: user.m_name },
    { label: "Birthday", value: user.m_birthdate },
    { label: "Numberphone", value: user.m_phone },
    { label: "Start Day", value: user.startDay },
    {
      label: "Active status",
      value: user.m_status === "ACTIVE" ? "Online" : "Offline",
      isStatus: true,
      statusClass: user.m_status === "ACTIVE" ? "online" : "offline",
    },
    {
      label: "Acceptance status",
      value:
        user.m_status === "ACTIVE"
          ? "Accepted"
          : user.m_status === "REJECTED"
            ? "Rejected"
            : user.m_status === "IN_ACTIVE"
              ? "Pending"
              : "",
      isStatus: true,
      statusClass:
        user.m_status === "ACTIVE"
          ? "accepted"
          : user.m_status === "REJECTED"
            ? "rejected"
            : user.m_status === "IN_ACTIVE"
              ? "pending"
              : "",
    },
  ];

  return (
    <div className="profile-content">
      <h2>General information</h2>
      <div className="profile-info-grid">
        {infoItems.map((item, index) => (
          <div key={index} className="info-item">
            <span className="info-label">{item.label}</span>
            <span className="info-value">
              {item.isStatus ? (
                <span className={`status-button ${item.statusClass}`}>
                  <span className="status-dot"></span>
                  {item.value}
                </span>
              ) : (
                item.value || "---"
              )}
            </span>
          </div>
        ))}
      </div>
      {user.m_status === 'REJECTED' && (
          <div className="rejection-reason">
            <h3>Rejection Reason</h3>
            <div className="rejection-content">
              <i className="fas fa-exclamation-circle"></i>
              <p>{rejectionReason}</p>
            </div>
        </div>
      )}
    </div>
  );
};

export default GeneralInformation;
