import React, { useState } from 'react';
import './EnterEmailPage.css';
import AuthHeader from '../../../components/AuthHeader/authHeader';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'

function EnterEmailPage() {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const navigate = useNavigate()

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage('');
    
    try {
      const response = await axios.get(`${process.env["REACT_APP_BASE_API"]}/api/get-username-and-email/`, {
        params: { username_or_email: email }
        });
        
        const { user_email, user_username } = response.data;
        await axios.post(`${process.env["REACT_APP_BASE_API"]}/api/resend-otp/`, {
            username: user_username
        });
        console.log(user_username)

        navigate('/verify-email', { state: { username: user_username, email: user_email, is_forgot_password: true } });
    } catch (error) {
      console.error('Error sending OTP:', error);
      setMessage('Đã xảy ra lỗi khi gửi OTP.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="enter-email-container">
      <AuthHeader/>
      <h2 className="enter-email-title">Reset Password</h2>
      <p className="enter-email-subtitle">Please enter your email to reset the password</p>
      <form onSubmit={handleSubmit} className="enter-email-form">
        <label className="enter-email-label">Email</label>
        <input
          type="email"
          value={email}
          onChange={handleEmailChange}
          required
          className="enter-email-input"
          placeholder="Enter your email"
        />
        <button type="submit" disabled={loading} className="enter-email-button">
          {loading ? 'Sending...' : 'Reset Password'}
        </button>
      </form>
      {message && <p className="enter-email-message">{message}</p>}
    </div>
  );
}

export default EnterEmailPage;