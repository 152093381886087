import React from 'react';

const Card = ({ title, count, icon }) => (
  <div className="card">
    <img src={icon} alt={title} />
    <h3>{count}</h3>
    <p>{title}</p>
  </div>
);

export default Card;