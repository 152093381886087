import React from 'react';
import './CourseSidebar.css';

const CourseSidebar = ({ activeTab, setActiveTab }) => {
  return (
    <div className="course-sidebar">
      <div className="sidebar-menu">
        <div 
          className={`sidebar-item ${activeTab === 'overview' ? 'active' : ''}`}
          onClick={() => setActiveTab('overview')}
        >
          Overview
        </div>
        <div 
          className={`sidebar-item ${activeTab === 'contents' ? 'active' : ''}`}
          onClick={() => setActiveTab('contents')}
        >
          Contents
        </div>
        <div 
          className={`sidebar-item ${activeTab === 'settings' ? 'active' : ''}`}
          onClick={() => setActiveTab('settings')}
        >
          Settings
        </div>
      </div>
    </div>
  );
};

export default CourseSidebar;
