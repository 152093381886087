import React, { useState, useEffect } from "react";
import "./adminSidebar.css";
import logo from "../../../../assets/splash.png";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../../../../AuthContext";
import axios from "axios";
import { useCallback } from "react";

const Sidebar = ({ pendingCount }) => {
  const location = useLocation();
  const [expandedUsers, setExpandedUsers] = useState(
    location.pathname.includes("/admin/teachers") ||
      location.pathname.includes("/admin/students") ||
      location.pathname.includes("/admin/all-users")
  );
  const navigate = useNavigate();
  const { refreshToken, setAccessToken, setRefreshToken } = useAuth();

  const handleLogout = async () => {
    try {
      await axios.post(`${process.env["REACT_APP_BASE_API"]}/api/logout/`, {
        refresh: refreshToken, // Gửi refresh token trong body
      });
      setAccessToken(null);
      setRefreshToken(null);
      navigate("/login");
    } catch (error) {
      console.error("Logout failed:", error);
    }
  };

  // Cập nhật activeItem và activeSubItem dựa trên đường dẫn hiện tại
  const getActiveItem = useCallback(() => {
    if (
      location.pathname.includes("/admin/teachers") ||
      location.pathname.includes("/admin/students")
    ) {
      return "user";
    }
    if (location.pathname.includes("/admin/courses")) return "courses";
    if (location.pathname.includes("/admin/event")) return "event";
    return "dashboard";
  }, [location]);

  const getActiveSubItem = useCallback(() => {
    if (location.pathname.includes("/admin/teachers")) return "teachers";
    if (location.pathname.includes("/admin/students")) return "students";
    return "";
  }, [location]);

  const [activeItem, setActiveItem] = useState(getActiveItem());
  const [activeSubItem, setActiveSubItem] = useState(getActiveSubItem());

  useEffect(() => {
    setActiveItem(getActiveItem());
    setActiveSubItem(getActiveSubItem());
  }, [getActiveItem, getActiveSubItem]);

  const handleMenuItemClick = (item, path) => {
    setActiveItem(item);
    navigate(path);
    setExpandedUsers(false);
  };

  const handleSubMenuItemClick = (item, path) => {
    setActiveSubItem(item);
    navigate(path);
  };

  const handleUserClick = () => {
    setActiveItem("user"); // Đặt 'user' làm activeItem
    setExpandedUsers(true); // Mở rộng submenu khi bấm vào User
  };

  return (
    <div className="sidebar">
      <div className="logo-section">
        <img src={logo} alt="English App Logo" className="logo-sidebar" />
        <h2>English App</h2>
      </div>
      <p className="menuTitle">Home</p>
      <ul className="menu">
        <li
          className={`menu-item ${activeItem === "dashboard" ? "active" : ""}`}
          onClick={() => handleMenuItemClick("dashboard", "/admin/dashboard")}
          style={{ cursor: "pointer" }}
        >
          <div>
            <img
              src={
                activeItem === "dashboard"
                  ? require("../../../../assets/Category-active.png")
                  : require("../../../../assets/Category.png")
              }
              alt="Category"
              className="menuIcon"
            />
            Dashboard
          </div>
        </li>
      </ul>
      <p className="menuTitle">Pages</p>
      <ul className="menu">
        <li className={`menu-item ${activeItem === "user" ? "active" : ""}`}>
          <div onClick={handleUserClick} style={{ cursor: "pointer" }}>
            <img
              src={
                activeItem === "user"
                  ? require("../../../../assets/following-active.png")
                  : require("../../../../assets/following.png")
              }
              alt="User"
              className="menuIcon"
            />
            User
            <ExpandMoreIcon className="dropdown-icon" />
          </div>
        </li>
        {expandedUsers && activeItem === "user" && (
          <ul className="submenu">
            <li
              className={`menu-item ${
                activeSubItem === "all-users" ? "active" : ""
              }`}
              onClick={() =>
                handleSubMenuItemClick("all-users", "/admin/all-users")
              }
              style={{ cursor: "pointer" }}
            >
              <div>
                <span className="dot"></span> All users
              </div>
            </li>
            <li
              className={`menu-item ${
                activeSubItem === "teachers" ? "active" : ""
              }`}
              onClick={() =>
                handleSubMenuItemClick("teachers", "/admin/teachers")
              }
              style={{ cursor: "pointer" }}
            >
              <div>
                <span className="dot"></span> Teachers
                <span className="badge">{pendingCount}</span>
              </div>
            </li>
            <li
              className={`menu-item ${
                activeSubItem === "students" ? "active" : ""
              }`}
              onClick={() =>
                handleSubMenuItemClick("students", "/admin/students")
              }
              style={{ cursor: "pointer" }}
            >
              <div>
                <span className="dot"></span> Students
              </div>
            </li>
          </ul>
        )}
        <li
          className={`menu-item ${activeItem === "courses" ? "active" : ""}`}
          onClick={() => handleMenuItemClick("courses", "/admin/courses")}
          style={{ cursor: "pointer" }}
        >
          <div>
            <img
              src={
                activeItem === "courses"
                  ? require("../../../../assets/Location.png")
                  : require("../../../../assets/Location.png")
              }
              alt="Courses"
              className="menuIcon"
            />
            Courses
          </div>
        </li>
        <li
          className={`menu-item ${activeItem === "event" ? "active" : ""}`}
          onClick={() => handleMenuItemClick("event", "/admin/event")}
          style={{ cursor: "pointer" }}
        >
          <div>
            <img
              src={
                activeItem === "event"
                  ? require("../../../../assets/calendar-star.png")
                  : require("../../../../assets/calendar-star.png")
              }
              alt="Event"
              className="menuIcon"
            />
            Event
          </div>
        </li>
        <div className="divider"></div>
      </ul>
      <div className="logout-section">
        <div className="divider"></div>
        <div onClick={handleLogout} style={{ cursor: "pointer" }}>
          <PowerSettingsNewIcon className="logoutIcon" />
          Logout
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
