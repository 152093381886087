import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import MDEditor from '@uiw/react-md-editor';
import axios from 'axios';
import { useAuth } from '../../../../AuthContext';
import './EditGrammarContent.css';

const EditGrammarContent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { lesson, grammarContent } = location.state;
  const [content, setContent] = useState(grammarContent);
  const { accessToken } = useAuth();
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [confirmAction, setConfirmAction] = useState(null);
  const [confirmMessage, setConfirmMessage] = useState('');

  const handleSaveClick = () => {
    setConfirmAction(() => async () => {
      try {
        await axios.put(
          `${process.env["REACT_APP_BASE_API"]}/api/course/updateGrammar/`,
          {
            m_lesson_id: lesson.m_lesson_id,
            m_grammar_content: content,
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        navigate(-1);
      } catch (error) {
        console.error('Error updating grammar content:', error);
      }
    });
    setConfirmMessage('Are you sure you want to save this content?');
    setIsConfirmDialogOpen(true);
  };

  const handleCancelClick = () => {
    setConfirmAction(() => {
      navigate(-1);
    });
    setConfirmMessage('Thay đổi của bạn chưa được lưu');
    setIsConfirmDialogOpen(true);
  };

  const handleConfirmAction = async () => {
    if (confirmAction) {
      await confirmAction();
    }
    setIsConfirmDialogOpen(false);
  };

  return (
    <div className="editGrammarContent-container">
      <div className="editGrammarContent-sidebar">
        <button className="editGrammarContent-button editGrammarContent-button-just-blue-border" onClick={handleCancelClick}>
          Go back
        </button>
      </div>
      <div className="editGrammarContent-main">
        <div className="editGrammarContent-header">
          <h1 className="editGrammarContent-title"><b>{lesson.m_title}</b></h1>
          <div className="editGrammarContent-actions">
            <button className="editGrammarContent-button editGrammarContent-button-just-purple-inside" onClick={handleSaveClick}>
              Lưu
            </button>
            <button className="editGrammarContent-button editGrammarContent-button-just-purple-border" onClick={handleCancelClick}>
              Hủy
            </button>
          </div>
        </div>
        <p className="editGrammarContent-meta">
          Ngày tạo: {new Date(lesson.m_created_at).toLocaleDateString()} | Ngày cập nhật: {new Date(lesson.m_updated_at).toLocaleDateString()}
        </p>
        <div className="editGrammarContent-editor">
          <MDEditor
            value={content}
            onChange={setContent}
            style={{ height: '80%', marginBottom: '20px' }}
            preview="edit"
          />
        </div>
      </div>
      {isConfirmDialogOpen && (
        <div className="editGrammarContent-dialog-overlay">
          <div className="gl-dialog" style={{ width: '500px', height: '140px', padding: '20px' }}>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '25px' }}>
              <h2>{confirmMessage}</h2>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '25px' }}>
              <button
                className="editGrammarContent-button-just-purple-border"
                onClick={() => setIsConfirmDialogOpen(false)}
              >
                Cancel
              </button>
              <button
                className="editGrammarContent-button-just-purple-inside"
                onClick={handleConfirmAction}
              >
                OK
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EditGrammarContent;