import React from 'react';

const ImageModal = ({ imageSrc, onClose }) => {
  return (
    <div className="modal-overlay" onClick={onClose}>
      <img src={imageSrc} alt="Zoomed" className="image-modal" />
    </div>
  );
};

export default ImageModal;