import React, { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import "./QuizLesson.css";
import ProfileHeader from "../../../../components/ProfileHeader/profileHeader";
import QuizHeader from "./components/QuizHeader/QuizHeader";
import QuizQuestionModal from "./components/QuizQuestionModal/QuizQuestionModal";
import ArrowUp from "../../../../assets/Arrow--up.svg";
import ArrowDown from "../../../../assets/Arrow--down.svg";
import Launch from "../../../../assets/Launch.svg";
import TrashCan from "../../../../assets/Trash-can.svg";
import ConfirmModal from "../components/ConfirmModal/ConfirmModal";
import { useAuth } from "../../../../AuthContext";
import EditLessonDetailsModal from "./components/EditLessonDetailsModal/EditLessonDetailsModal";

const QuizLesson = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [quizData, setQuizData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isQuestionModalOpen, setIsQuestionModalOpen] = useState(false);
  const [questionData, setQuestionData] = useState(null);
  const [editingIndex, setEditingIndex] = useState(null);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const { accessToken } = useAuth();
  const [deletedQuestionIds, setDeletedQuestionIds] = useState([]);
  const [isSaveConfirmModalOpen, setIsSaveConfirmModalOpen] = useState(false);
  const [isDeleteQuestionModalOpen, setIsDeleteQuestionModalOpen] =
    useState(false);
  const [questionToDeleteIndex, setQuestionToDeleteIndex] = useState(null);
  const courseTitle = state?.courseTitle;
  const [isPublished, setIsPublished] = useState(false);
  const [confirmModal, setConfirmModal] = useState({
    isOpen: false,
    title: "",
    message: "",
    onConfirm: null,
  });
  const [isEditingLesson, setIsEditingLesson] = useState(false);
  const [editedLesson, setEditedLesson] = useState({
    title: "",
    description: "",
    image_url: "",
  });
  const [lessonFormErrors, setLessonFormErrors] = useState({});

  useEffect(() => {
    const fetchQuiz = async () => {
      try {
        const accessToken = localStorage.getItem("accessToken");
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_API}/api/course/getQuizByLessonId/?m_lesson_id=${id}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        setQuizData(response.data);
        setIsPublished(response.data.lesson.m_status === "public");
      } catch (err) {
        setError("Failed to load quiz data. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    fetchQuiz();
  }, [id, accessToken]);

  const handlePublishClick = () => {
    openConfirmModal(
      isPublished ? "Ẩn bài học" : "Xuất bản bài học",
      isPublished
        ? "Bạn có chắc chắn muốn ẩn bài học này không?"
        : "Bạn nên lưu tất cả thay đổi trước khi xuất bản. Bạn có chắc chắn muốn xuất bản bài học này?",
      () => handlePublishQuiz()
    );
  };

  const handlePublishQuiz = async () => {
    try {
      await axios.put(
        `${process.env.REACT_APP_BASE_API}/api/course/updateLesson/`,
        {
          m_lesson_id: id,
          m_status: isPublished ? "private" : "public",
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      setIsPublished(!isPublished);
      openConfirmModal(
        "Thành công",
        isPublished ? "Bài học đã được ẩn" : "Bài học đã được công khai",
        () => closeConfirmModal()
      );
    } catch (error) {
      console.error("Error publishing lesson:", error);
      openConfirmModal(
        "Lỗi",
        isPublished ? "Không thể ẩn bài học" : "Không thể công khai bài học",
        () => closeConfirmModal()
      );
    }
  };

  const handleAddQuestion = () => {
    setQuestionData(null);
    setEditingIndex(null);
    setIsQuestionModalOpen(true);
  };

  const handleSaveQuestion = (questionData) => {
    console.log(questionData)
    if (questionData.option_items.length < 2 && questionData.questionType == "MULTIPLE_CHOICE") {
      alert(`Câu hỏi "${questionData.title}" cần có ít nhất 2 lựa chọn.`);
      return;
    }
    const correctOptionIndex = questionData.option_items.findIndex(
      (option) => option.isCorrect || option.is_correct
    );

    if(questionData.questionType == "MULTIPLE_CHOICE") {
      if (correctOptionIndex === -1) {
        alert(`Câu hỏi "${questionData.title}" phải có đúng một đáp án đúng.`);
        return;
      }
    }

    if (editingIndex !== null) {
      setQuizData((prev) => {
        const updatedQuestions = [...prev.question_items];
        updatedQuestions[editingIndex] = {
          question: {
            ...updatedQuestions[editingIndex].question,
            m_question_text: questionData.title,
            m_correct_text_answer: questionData.m_correct_text_answer,
            questionType: questionData.questionType,
            m_question_description: questionData.m_question_text,
          },
          options: questionData.option_items.map((option, index) => ({
            ...updatedQuestions[editingIndex].options[index],
            m_option_text: option.option_text,
            m_is_correct: index === correctOptionIndex,
          })),
        };
        return { ...prev, question_items: updatedQuestions };
      });
    } else {
      const newQuestion = {
        question: {
          m_id: null,
          m_question_text: questionData.title,
          m_question_description: questionData.m_question_text,
          m_correct_text_answer: questionData.m_correct_text_answer,
          questionType: questionData.questionType,
        },
        options: questionData.option_items.map((option, index) => ({
          m_id: null,
          m_option_text: option.option_text,
          m_is_correct: index === correctOptionIndex,
        })),
      };

      setQuizData((prev) => ({
        ...prev,
        question_items: [...prev.question_items, newQuestion],
      }));
    }
    setIsQuestionModalOpen(false);
    setQuestionData(null);
    setEditingIndex(null);
  };

  const moveQuestion = (index, direction) => {
    const newQuestions = [...quizData.question_items];
    if (direction === "up" && index > 0) {
      [newQuestions[index - 1], newQuestions[index]] = [
        newQuestions[index],
        newQuestions[index - 1],
      ];
    } else if (direction === "down" && index < newQuestions.length - 1) {
      [newQuestions[index], newQuestions[index + 1]] = [
        newQuestions[index + 1],
        newQuestions[index],
      ];
    }
    setQuizData((prev) => ({ ...prev, question_items: newQuestions }));
  };

  const handleDeleteLesson = async () => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_BASE_API}/api/course/deleteLesson/`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          data: {
            m_lesson_id: id,
          },
        }
      );
      navigate(`/teacher/course/${quizData.lesson.m_course_id}`);
    } catch (error) {
      console.error("Error deleting lesson:", error);
      alert("Failed to delete lesson. Please try again.");
    }
  };

  const handleEditQuestion = (item, index) => {
    console.log(item)
    setQuestionData({
      title: item.question.m_question_text,
      m_question_text: item.question.m_question_description || "",
      questionType: item.question.m_type,
      m_correct_text_answer: item.question.m_correct_text_answer,
      option_items: item.options.map((option) => ({
        id: option.m_id,
        option_text: option.m_option_text,
        is_correct: option.m_is_correct,
      })),
    });
    setEditingIndex(index);
    setIsQuestionModalOpen(true);
  };

  const handleDeleteQuestion = (index) => {
    setQuestionToDeleteIndex(index);
    setIsDeleteQuestionModalOpen(true);
  };

  const handleConfirmDeleteQuestion = () => {
    const index = questionToDeleteIndex;
    const questionToDelete = quizData.question_items[index];

    if (questionToDelete.question.m_id) {
      setDeletedQuestionIds((prev) => [
        ...prev,
        questionToDelete.question.m_id,
      ]);
    }

    const updatedQuestions = quizData.question_items.filter(
      (_, idx) => idx !== index
    );
    setQuizData((prev) => ({
      ...prev,
      question_items: updatedQuestions,
    }));

    setIsDeleteQuestionModalOpen(false);
    setQuestionToDeleteIndex(null);
  };

  const handleSaveAllChanges = () => {
    if (!quizData.question_items.length) {
      alert("Vui lòng thêm ít nhất một câu hỏi.");
      return;
    }

    for (const quizItem of quizData.question_items) {
      const correctCount = quizItem.options.filter(
        (opt) => opt.m_is_correct
      ).length;
      if (correctCount !== 1 && quizItem.question.questionType == "MULTIPLE_CHOICE") {
        alert(
          `Mỗi câu hỏi phải có đúng một đáp án đúng. Kiểm tra câu hỏi: "${quizItem.question.m_question_text}"`
        );
        return;
      }
    }

    setIsSaveConfirmModalOpen(true);
  };

  const handleConfirmSave = async () => {
    try {
      const payload = {
        lesson: {
          m_title: quizData.lesson.m_title,
          m_content: quizData.lesson.m_content,
        },
        quiz_items: quizData.question_items.map((item) => ({
          id: item.question.m_id,
          m_question_text: item.question.m_question_text,
          m_question_type: item.question.questionType,
          m_correct_text_answer: item.question.m_correct_text_answer,
          m_question_description: item.question.m_question_description,
          option_items: item.options.map((option) => ({
            id: option.m_id,
            option_text: option.m_option_text,
            is_correct: option.m_is_correct,
          })),
        })),
        delete_question_items: deletedQuestionIds,
      };

      const response = await axios.put(
        `${process.env.REACT_APP_BASE_API}/api/course/updateQuizByLessonId/${id}/`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.status === 200) {
        setQuizData(response.data);
        setDeletedQuestionIds([]);
        alert("Quiz đã được cập nhật thành công!");
      }
    } catch (error) {
      console.error("Error updating quiz:", error);
      if (error.response?.data?.error) {
        alert(error.response.data.error);
      } else {
        alert("Cập nhật quiz thất bại. Vui lòng thử lại.");
      }
    } finally {
      setIsSaveConfirmModalOpen(false);
    }
  };

  const openConfirmModal = (title, message, onConfirm) => {
    setConfirmModal({
      isOpen: true,
      title,
      message,
      onConfirm,
    });
  };

  const closeConfirmModal = () => {
    setConfirmModal({
      isOpen: false,
      title: "",
      message: "",
      onConfirm: null,
    });
  };

  const handleEditLessonDetails = () => {
    setEditedLesson({
      title: quizData.lesson.m_title,
      description: quizData.lesson.m_content,
      image_url: quizData.lesson.m_image_url,
    });
    setIsEditingLesson(true);
  };

  const handleSaveLessonDetails = async (formData) => {
    setLessonFormErrors({});
    
    const errors = {};
    if (!formData.title?.trim()) {
      errors.title = "Please enter title";
    }
    if (!formData.description?.trim()) {
      errors.description = "Please enter description";
    }
    
    if (Object.keys(errors).length > 0) {
      setLessonFormErrors(errors);
      return;
    }

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_API}/api/course/updateLesson/`,
        {
          m_lesson_id: id,
          m_title: formData.title,
          m_content: formData.description,
          m_lesson_image: formData.image_url,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.status === 200) {
        setQuizData((prev) => ({
          ...prev,
          lesson: {
            ...prev.lesson,
            m_title: formData.title,
            m_content: formData.description,
            m_image_url: formData.image_url,
          },
        }));
        setIsEditingLesson(false);
        alert("Cập nhật thông tin bài học thành công!");
      }
    } catch (error) {
      console.error("Error updating lesson details:", error);
      alert("Cập nhật thông tin bài học thất bại. Vui lòng thử lại.");
    }
  };

  if (loading) return <p></p>;
  if (error) return <p>{error}</p>;

  return (
    <div>
      <div className="vocabulary-lesson-header-newlesson">
        <ProfileHeader />
      </div>

      <div className="vocabulary-lesson-wrapper">
        <div className="vocabulary-lesson-navigation">
          <button className="vocabulary-back-btn" onClick={() => navigate(-1)}>
            BACK
          </button>
        </div>

        <div className="vocabulary-lesson-container">
          <div className="vocabulary-header-newlesson">
            <QuizHeader
              lesson={quizData?.lesson}
              courseTitle={courseTitle}
              isEditing={true}
              onDeleteLesson={() => setIsConfirmModalOpen(true)}
              onSave={handleSaveAllChanges}
            />
          </div>

          <div className="quiz-content">
            <div className="quiz-title-section">
              <h1 className="quiz-title">{quizData?.lesson?.m_title}</h1>
              <button className="quiz-edit-button" onClick={handleEditLessonDetails}>
                <span className="quiz-edit-icon">✎</span>
                <span className="quiz-edit-text">Edit</span>
              </button>
            </div>
            <div className="quiz-header">
              <h2 className="quiz-subtitle">{quizData?.lesson?.m_content}</h2>
              <div className="quiz-header-actions">
                <button
                  className="publish-quiz-btn"
                  onClick={handlePublishClick}
                >
                  {isPublished ? "Hide Quiz" : "Publish Quiz"}
                </button>
                <button
                  className="create-question-btn"
                  onClick={handleAddQuestion}
                >
                  Tạo câu hỏi trắc nghiệm
                </button>
              </div>
            </div>
            <div className="quiz-divider"></div>

            <div className="quiz-list-container">
              {quizData.question_items.map((item, index) => (
                <div key={item.question.m_id} className="quiz-question-card">
                  <div className="quiz-question-header">
                    <h3>{item.question.m_question_text}</h3>
                    <div className="quiz-question-actions">
                      <button
                        className="quiz-action-btn"
                        onClick={() => moveQuestion(index, "up")}
                        disabled={index === 0}
                      >
                        <img
                          src={ArrowUp}
                          alt="Move up"
                          style={{ opacity: index === 0 ? 0.5 : 1 }}
                        />
                      </button>
                      <button
                        className="quiz-action-btn"
                        onClick={() => moveQuestion(index, "down")}
                        disabled={index === quizData.question_items.length - 1}
                      >
                        <img
                          src={ArrowDown}
                          alt="Move down"
                          style={{
                            opacity:
                              index === quizData.question_items.length - 1
                                ? 0.5
                                : 1,
                          }}
                        />
                      </button>
                      <button
                        className="quiz-action-btn"
                        onClick={() => handleEditQuestion(item, index)}
                      >
                        <img src={Launch} alt="Edit" />
                      </button>
                      <button
                        className="quiz-action-btn"
                        onClick={() => handleDeleteQuestion(index)}
                      >
                        <img src={TrashCan} alt="Delete" />
                      </button>
                    </div>
                  </div>

                  <div className="quiz-question-content">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.question.m_question_description,
                      }}
                    />
                  </div>

                  <div className="quiz-options-container">
                    {item.options.map((option, optionIndex) => (
                      option.m_option_text !== "" && (
                        <div key={option.m_id} className="quiz-option-item">
                          <div className="quiz-option-letter">
                            {String.fromCharCode(65 + optionIndex)}.
                          </div>
                          <div className="quiz-option-text">
                            {option.m_option_text}
                          </div>
                          <div className="quiz-option-correct">
                            {option.m_is_correct && (
                              <span className="correct-answer-indicator">✓</span>
                            )}
                          </div>
                        </div>
                      )
                    ))}
                  </div>

                  {item.question.m_correct_text_answer && (
                    <div className="quiz-correct-text-answer">
                      Correct answer: {item.question.m_correct_text_answer}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <QuizQuestionModal
        isOpen={isQuestionModalOpen}
        onClose={() => {
          setIsQuestionModalOpen(false);
          setQuestionData(null);
          setEditingIndex(null);
        }}
        onSave={handleSaveQuestion}
        initialData={questionData}
      />

      {isConfirmModalOpen && (
        <ConfirmModal
          isOpen={isConfirmModalOpen}
          onClose={() => setIsConfirmModalOpen(false)}
          onConfirm={handleDeleteLesson}
          title="Xóa quiz?"
          message="Quiz sẽ được xóa hoàn toàn"
        />
      )}

      {isSaveConfirmModalOpen && (
        <ConfirmModal
          isOpen={isSaveConfirmModalOpen}
          onClose={() => setIsSaveConfirmModalOpen(false)}
          onConfirm={handleConfirmSave}
          title="Lưu quiz"
          message="Thông tin Quiz sẽ được lưu lại"
        />
      )}

      {isDeleteQuestionModalOpen && (
        <ConfirmModal
          isOpen={isDeleteQuestionModalOpen}
          onClose={() => {
            setIsDeleteQuestionModalOpen(false);
            setQuestionToDeleteIndex(null);
          }}
          onConfirm={handleConfirmDeleteQuestion}
          title="Xóa câu hỏi?"
          message="Câu hỏi này sẽ được xóa khỏi quiz"
        />
      )}

      {confirmModal.isOpen && (
        <ConfirmModal {...confirmModal} onClose={closeConfirmModal} />
      )}

      {isEditingLesson && (
        <EditLessonDetailsModal
          isOpen={isEditingLesson}
          onClose={() => setIsEditingLesson(false)}
          onSave={handleSaveLessonDetails}
          initialData={editedLesson}
          formErrors={lessonFormErrors}
        />
      )}

      <EditLessonDetailsModal
        isOpen={isEditingLesson}
        onClose={() => setIsEditingLesson(false)}
        onSave={handleSaveLessonDetails}
        initialData={editedLesson}
        formErrors={lessonFormErrors}
      />
    </div>
  );
};

export default QuizLesson;
