import React, { useState, useEffect } from "react";
import defaultAvatar from "../../../assets/basic_avatar.jpg";
import iconPerson from "../../../assets/icon_person.svg";
import iconCake from "../../../assets/icon_cake.svg";
import iconMessage from "../../../assets/icon_message.svg";
import iconCall from "../../../assets/icon_call.svg";
import iconHeart from "../../../assets/icon_heart.svg";
import iconChat from "../../../assets/icon_chat.svg";
import "./teacherSearchDetail.css";
import axios from "axios";
import { useAuth } from "../../../AuthContext";
import { useLocation, useNavigate } from "react-router-dom";
import ProfileHeader from "../../../components/ProfileHeader/profileHeader";
import CourseCard from "./components/CourseCard";

const SuggestionItem = ({ name, email, avatar, onClick }) => (
  <div className="tsud-suggestion-item" onClick={onClick}>
    <img
      src={avatar || defaultAvatar}
      alt={name}
      className="tsud-suggestion-avatar"
    />
    <div className="tsud-suggestion-info">
      <h4 className="tsud-suggestion-name">{name}</h4>
      <p className="tsud-suggestion-email">{email}</p>
    </div>
  </div>
);

const TeacherSearchDetail = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [suggestions, setSuggestions] = useState([]);
  const { accessToken } = useAuth();
  const user = location.state?.user;
  const [courses, setCourses] = useState([]);

  useEffect(() => {
    if (!user) {
      navigate("/teacher/search-users");
    }
  }, [user, navigate]);

  const handleBack = () => {
    navigate("/teacher/search-users");
  };

  const handleUserChange = (newUser) => {
    navigate(`/teacher/teacher-detail/${newUser.m_id}`, {
      state: { user: newUser },
    });
  };

  useEffect(() => {
    const fetchSuggestions = async () => {
      try {
        if (!user || !user.m_user_name) return;

        const response = await axios.get(
          `${process.env["REACT_APP_BASE_API"]}/api/user/search-users-new/?query=${user.m_user_name}&type=teacher&limit=10`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        let filteredSuggestions = response.data.filter(
          (teacher) => teacher.m_id !== user.m_id
        );

        if (filteredSuggestions.length === 0) {
          const latestResponse = await axios.get(
            `${process.env["REACT_APP_BASE_API"]}/api/teacher/get-latest-teachers/?limit=10`,
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            }
          );
          filteredSuggestions = latestResponse.data.filter(
            (teacher) => teacher.m_id !== user.m_id
          );
        }

        setSuggestions(filteredSuggestions);
      } catch (error) {
        console.error("Error fetching suggestions:", error);
      }
    };

    fetchSuggestions();
  }, [user, accessToken]);

  // Determine the cover image UR
  useEffect(() => {
    const fetchCourses = async () => {
      try {
        if (!user?.m_id) return;

        const response = await axios.get(
          `${process.env["REACT_APP_BASE_API"]}/api/course/getAllCourses/?status=public&teacher_id=${user.m_id}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        const transformedCourses = response.data.map((course) => ({
          id: course.m_course_id,
          title: course.m_title,
          description: course.m_description,
          image: course.m_course_image,
          time: course.m_created_at
            ? new Date(course.m_created_at).toLocaleDateString()
            : "Not specified",
          views: "0",
          comments: "0",
          onClick: () => navigate(`/course/detail/${course.m_course_id}`),
        }));

        setCourses(transformedCourses);
      } catch (error) {
        console.error("Error fetching courses:", error);
      }
    };

    fetchCourses();
  }, [user?.m_id, accessToken, navigate]);

  // Thêm hàm format date
  const formatBirthdate = (birthdate) => {
    if (!birthdate) return "Not specified";

    const date = new Date(birthdate);
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    return `Born ${
      months[date.getMonth()]
    } ${date.getDate()}, ${date.getFullYear()}`;
  };

  return (
    <div className="tsu-container">
      <div className="tsud-header">
        <ProfileHeader />
      </div>
      <div className="tsud-content">
        <button className="tsud-back-btn" onClick={handleBack}>
          BACK
        </button>

        <div
          className="tsud-cover"
          style={{
            background: `url(${
              user.m_cover_link ||
              require("../../../assets/backgroundProfile.jpg")
            })`,
            backgroundSize: "100% auto",
            backgroundPosition: "center",
          }}
        >
          <div className="tsud-profile-info">
            <div className="tsud-avatar">
              <img
                src={
                  user.m_avatar_link || user.m_portrait_image || defaultAvatar
                }
                alt="Teacher avatar"
              />
            </div>
            <div className="tsud-basic-info">
              <h2 className="tsud-name">{user.m_name}</h2>
              <h3 className="tsud-role">Teacher</h3>
            </div>
            <div className="tsud-btn-container">
              <button className="tsud-message-btn">Message</button>
            </div>
          </div>
        </div>

        <div className="tsud-sub-content">
          <div className="tsud-left-sidebar">
            <h3>About</h3>
            <div className="tsud-info-item">
              <img src={iconPerson} alt="Gender" className="tsud-icon" />
              <span>{user.m_gender || "Not specified"}</span>
            </div>
            <div className="tsud-info-item">
              <img src={iconCake} alt="Birthday" className="tsud-icon" />
              <span>{formatBirthdate(user.m_birthdate)}</span>
            </div>
            <div className="tsud-info-item">
              <img src={iconMessage} alt="Email" className="tsud-icon" />
              <span>{user.m_email}</span>
            </div>
            <div className="tsud-info-item">
              <img src={iconCall} alt="Phone" className="tsud-icon" />
              <span>{user.m_phone || "Not specified"}</span>
            </div>
          </div>

          <div className="tsud-main-content">
            <div className="tsud-nav">
              <button className="tsud-nav-item active">Courses</button>
              <button className="tsud-nav-item">Roadmap</button>
              <button className="tsud-nav-item">Groups</button>
            </div>

            <div className="tsud-courses-grid">
              {courses.length > 0 ? (
                courses.map((course) => (
                  <CourseCard key={course.id} {...course} />
                ))
              ) : (
                <div className="tsud-no-courses">No courses available</div>
              )}
            </div>
          </div>

          <div className="tsud-right-sidebar">
            <h3 className="tsud-suggestions-title">You might know</h3>
            <div className="tsud-suggestions">
              {suggestions.map((suggestion) => (
                <SuggestionItem
                  key={suggestion.m_id}
                  name={suggestion.m_name}
                  email={suggestion.m_email}
                  avatar={
                    suggestion.m_avatar_link || suggestion.m_portrait_image
                  }
                  onClick={() => handleUserChange(suggestion)}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeacherSearchDetail;
