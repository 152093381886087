import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import { useAuth } from "../../../AuthContext";
import ProfileHeader from "../../../components/ProfileHeader/profileHeader";
import { useNavigate } from "react-router-dom";
import "./profile.css";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import ProfileHeaderSection from "../../../components/TeacherProfile/ProfileHeaderSection";
import GeneralInformation from "../../../components/TeacherProfile/GeneralInformation";
import DegreeInformation from "../../../components/TeacherProfile/DegreeInformation";
import AWS from "aws-sdk";
import UpdateStatusModal from "../../../components/TeacherProfile/UpdateStatusModal";

const Profile = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { accessToken, refreshToken, setAccessToken, setRefreshToken } =
    useAuth();
  const navigate = useNavigate();
  const [zoomedImage, setZoomedImage] = useState(null);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [rejectionReason, setRejectionReason] = useState("Reject");
  const [pendingImages, setPendingImages] = useState({});
  const [hasImageChanges, setHasImageChanges] = useState(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);

  const openViewModal = () => setIsViewModalOpen(true);
  const closeViewModal = () => setIsViewModalOpen(false);
  const openEditModal = () => {
    setFormData({
      m_bio: user.m_bio || "",
      m_name: user.m_name || "",
      m_phone: user.m_phone || "",
      m_birthdate: user.m_birthdate || "",
    });
    setIsEditModalOpen(true);
  };
  const closeEditModal = () => setIsEditModalOpen(false);

  const [formData, setFormData] = useState({
    m_bio: "",
    m_name: "",
    m_phone: "",
    m_birthdate: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.put(
        `${process.env["REACT_APP_BASE_API"]}/api/teacher/updateTeacherInfor/`,
        {
          m_bio: formData.m_bio || user.m_bio,
          m_name: formData.m_name || user.m_name,
          m_phone: formData.m_phone || user.m_phone,
          m_birthdate: formData.m_birthdate || user.m_birthdate,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      const response = await axios.get(
        `${process.env["REACT_APP_BASE_API"]}/api/me/`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      setUser(response.data);
      closeEditModal();
    } catch (error) {
      console.error("Update failed:", error);
    }
  };

  const toggleZoom = (imageSrc) => {
    setZoomedImage(zoomedImage ? null : imageSrc);
  };

  const handleLogout = async () => {
    try {
      await axios.post(`${process.env["REACT_APP_BASE_API"]}/api/logout/`, {
        refresh: refreshToken,
      });
      setAccessToken(null);
      setRefreshToken(null);
      navigate("/login");
    } catch (error) {
      console.error("Logout failed:", error);
    }
  };

  const handleImagesUpdate = useCallback((section, newFiles, hasChanges) => {
    if (hasChanges) {
      setPendingImages((prev) => {
        const existingFiles = prev[section] || [];
        const totalFiles = existingFiles.length + newFiles.length;
        if (totalFiles > 10) {
          alert(
            `Tổng số lượng ảnh trong section ${section} không được vượt quá 10 tấm.`
          );
          return prev;
        }

        return {
          ...prev,
          [section]: newFiles,
        };
      });
      setHasImageChanges(true);
    } else if (section === null && newFiles === null) {
      setPendingImages({});
      setHasImageChanges(false);
    }
  }, []);

  const handleStatusUpdate = async () => {
    try {
      setLoading(true);
      const uploadedUrls = {};
      for (const section of Object.keys(pendingImages)) {
        // Chỉ lặp qua các section có thay đổi
        const images = pendingImages[section];
        const sectionUrls = [];

        for (const image of images) {
          if (typeof image === "string") {
            sectionUrls.push(image);
          } else if (image instanceof File) {
            try {
              const url = await uploadToS3(image);
              sectionUrls.push(url);
            } catch (error) {
              console.error("Error uploading file:", error);
              throw error;
            }
          }
        }

        uploadedUrls[`m_${section}_image`] = sectionUrls.join(",");
      }

      await axios.put(
        `${process.env["REACT_APP_BASE_API"]}/api/teacher/updateTeacherInfor/`,
        {
          ...uploadedUrls,
          m_status: "IN_ACTIVE",
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      setPendingImages({});
      setHasImageChanges(false);

      window.location.reload();
    } catch (error) {
      console.error("Update failed:", error);
      alert("Failed to update images. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const uploadToS3 = async (file) => {
    const s3 = new AWS.S3({
      accessKeyId: process.env["REACT_APP_AWS_ACCESS_KEY_ID"],
      secretAccessKey: process.env["REACT_APP_AWS_SECRET_ACCESS_KEY"],
      region: "ap-southeast-1",
    });

    const params = {
      Bucket: "ivsenglishapp",
      Key: `${Date.now()}-${file.name}`,
      Body: file,
    };

    try {
      const data = await s3.upload(params).promise();
      return data.Location;
    } catch (error) {
      console.error("Error uploading to S3:", error);
      throw new Error("Upload failed");
    }
  };

  useEffect(() => {
    if (!accessToken) {
      navigate("/login");
    }

    const fetchUserProfile = async () => {
      try {
        const response = await axios.get(
          `${process.env["REACT_APP_BASE_API"]}/api/me/`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        setUser(response.data);
        setFormData({
          m_bio: response.data.m_bio || "",
          m_name: response.data.m_name || "",
          m_phone: response.data.m_phone || "",
          m_birthdate: response.data.m_birthdate || "",
        });

        if (response.data.m_status === "REJECTED") {
          const reasonResponse = await axios.get(
            `${process.env["REACT_APP_BASE_API"]}/api/teacher/get-rejection-reason/`,
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            }
          );
          setRejectionReason(reasonResponse.data.reason);
        }
      } catch (error) {
        setError("Lỗi khi tải thông tin người dùng.");
      } finally {
        setLoading(false);
      }
    };

    if (accessToken) {
      fetchUserProfile();
    }
  }, [accessToken, navigate]);

  useEffect(() => {
    if (user) {
      setFormData({
        m_bio: user.m_bio || "",
        m_name: user.m_name || "",
        m_phone: user.m_phone || "",
        m_birthdate: user.m_birthdate || "",
      });
    }
  }, [user]);

  if (error) return <div>{error}</div>;
  if (!user) return <div>Không tìm thấy thông tin người dùng.</div>;

  return (
    <div className="profile-page">
      <ProfileHeader username={user.fullName} />
      <ProfileHeaderSection
        user={user}
        isViewModalOpen={isViewModalOpen}
        isEditModalOpen={isEditModalOpen}
        openViewModal={openViewModal}
        closeViewModal={closeViewModal}
        openEditModal={openEditModal}
        closeEditModal={closeEditModal}
        handleSubmit={handleSubmit}
        handleChange={handleChange}
        formData={formData}
      />
      <GeneralInformation user={user} rejectionReason={rejectionReason} />
      <DegreeInformation
        user={user}
        toggleZoom={toggleZoom}
        onImagesUpdate={handleImagesUpdate}
      />
      {hasImageChanges && (
        <>
          <button
            className="status-change-button"
            onClick={() => setIsUpdateModalOpen(true)}
          >
            Update Status
          </button>
          <UpdateStatusModal
            isOpen={isUpdateModalOpen}
            onClose={() => setIsUpdateModalOpen(false)}
            onConfirm={() => {
              handleStatusUpdate();
              setIsUpdateModalOpen(false);
            }}
          />
        </>
      )}
      {zoomedImage && (
        <div className="modal-overlay" onClick={() => setZoomedImage(null)}>
          <img src={zoomedImage} alt="Zoomed" className="image-modal" />
        </div>
      )}
    </div>
  );
};

export default Profile;
