import React, { useState, useEffect } from "react";
import Sidebar from "../../components/Sidebar/adminSidebar";
import AllUsersDashboard from "./allUsers";
import axios from "axios";
import { useAuth } from "../../../../AuthContext";
import { useNavigate } from "react-router-dom";

const AdminAllUsersDashboard = () => {
  const [pendingTeachers, setPendingTeachers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { accessToken } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!accessToken) {
      navigate("/login");
    }
  }, [accessToken, navigate]);

  useEffect(() => {
    const fetchPendingTeachers = async () => {
      try {
        const response = await axios.get(
          `${process.env["REACT_APP_BASE_API"]}/api/admin/getTeachersInActive/`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        setPendingTeachers(response.data);
      } catch (error) {
        setError("Lỗi khi tải dữ liệu.");
      } finally {
        setLoading(false);
      }
    };
    fetchPendingTeachers();
  }, [accessToken]);

  if (error) return <div className="error-message">{error}</div>;

  return (
    <div className="app">
      <Sidebar pendingCount={pendingTeachers.length}/>
      <div>
        <AllUsersDashboard initialPendingTeachers={pendingTeachers} initialLoading={loading} />
      </div>
    </div>
  );
};

export default AdminAllUsersDashboard;
