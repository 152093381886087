import React from 'react';
import './CourseCard.css';

const truncateSubtitle = (subtitle) => {
    return subtitle.length > 60 ? subtitle.substring(0, 20) + '...' : subtitle;
};

const FreeCourseCard = ({ title, subtitle, userCount, duration }) => {
    return (
        <div className="ea-90-o-course-card">
            <div className="ea-90-o-course-title">{title}</div>
            <div className="ea-90-o-course-subtitle">{truncateSubtitle(subtitle)}</div>
            <div className="ea-90-o-course-info">
                <span className="user-count">{userCount} người học</span>
                <span className="duration">{duration}</span>
            </div>
            <div className="free-label">Miễn phí</div>
        </div>
    );
};

export default FreeCourseCard;

