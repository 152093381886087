import React from 'react';
import AWS from 'aws-sdk';
import './EditLessonModal.css';

// Cấu hình S3
const s3 = new AWS.S3({
  accessKeyId: process.env["REACT_APP_AWS_ACCESS_KEY_ID"],
  secretAccessKey: process.env["REACT_APP_AWS_SECRET_ACCESS_KEY"],
  region: "ap-southeast-1",
});

const EditLessonModal = ({
  isOpen,
  lesson,
  formErrors,
  onClose,
  onSave,
  onInputChange,
}) => {
  if (!isOpen) return null;

  const uploadToS3 = async (file) => {
    const params = {
      Bucket: "ivsenglishapp",
      Key: `lessons/${Date.now()}-${file.name}`,
      Body: file,
    };
    try {
      const data = await s3.upload(params).promise();
      return data.Location;
    } catch (error) {
      console.error('Error uploading to S3:', error);
      throw new Error('Upload failed');
    }
  };

  const handleImageUpload = async () => {
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.accept = 'image/*';
    
    fileInput.onchange = async (e) => {
      const file = e.target.files[0];
      if (file) {
        try {
          const imageUrl = await uploadToS3(file);
          onInputChange("image_url", imageUrl);
        } catch (error) {
          console.error('Upload failed:', error);
        }
      }
    };

    fileInput.click();
  };

  return (
    <div className="vocabulary-modal-overlay">
      <div className="vocabulary-modal">
        <h2>Sửa thông tin bộ từ vựng</h2>
        <div className="vocabulary-modal-content">
          <div className="vocabulary-modal-field">
            <label>
              Title <span className="vocabulary-required">*</span>
            </label>
            <input
              type="text"
              value={lesson.title}
              onChange={(e) => onInputChange("title", e.target.value)}
              placeholder="Please enter title"
              className={formErrors.title ? "vocabulary-error" : ""}
            />
            {formErrors.title && (
              <span className="vocabulary-error-icon" />
            )}
            {formErrors.title && (
              <span className="vocabulary-error-message">
                Please enter title
              </span>
            )}
          </div>

          <div className="vocabulary-modal-field">
            <label>
              Description <span className="vocabulary-required">*</span>
            </label>
            <input
              type="text"
              value={lesson.description}
              onChange={(e) => onInputChange("description", e.target.value)}
              placeholder="Please enter description"
              className={formErrors.description ? "vocabulary-error" : ""}
            />
            {formErrors.description && (
              <span className="vocabulary-error-icon" />
            )}
            {formErrors.description && (
              <span className="vocabulary-error-message">
                Please enter description
              </span>
            )}
          </div>

          <div className="vocabulary-modal-field vocabulary-modal-image-input">
            <label>Image</label>
            <input
              type="text"
              value={lesson.image_url}
              onChange={(e) => onInputChange("image_url", e.target.value)}
              placeholder="Image URL"
            />
            <button 
              className="vocabulary-modal-upload"
              onClick={handleImageUpload}
            >
              Upload +
            </button>
          </div>
        </div>

        <div className="vocabulary-modal-actions">
          <button
            className="vocabulary-modal-cancel"
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            className="vocabulary-modal-save"
            onClick={onSave}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditLessonModal;