import React from "react";
import { useAuth } from "../../../../AuthContext";
import { useNavigate } from "react-router-dom";
import { useUserSearch } from "./hooks/useUserSearch";
import Path from "./components/Path";
import SearchBar from "./components/SearchBar";
import StudentInfo from "./components/StudentInfo";
import TeacherInfo from "./components/TeacherInfo";
import UserList from "./components/UserList";
import EmptyState from "./components/EmptyState";
import "./allUsers.css";

const AllUsersDashboard = ({ initialLoading }) => {
  const { accessToken } = useAuth();
  const navigate = useNavigate();
  
  const {
    searchTerm,
    setSearchTerm,
    searchResults,
    isOpen,
    setIsOpen,
    selectedOption,
    setSelectedOption,
    autoCompleteKey,
    userList,
    userInfo,
    loadingUsers,
    handleKeyPress,
    handleUserSelection,
  } = useUserSearch(accessToken);

  if (initialLoading) return <div>Loading...</div>;

  return (
    <div className="allusers-dashboard">
      <Path />
      <SearchBar
        autoCompleteKey={autoCompleteKey}
        searchResults={searchResults}
        isOpen={isOpen}
        selectedOption={selectedOption}
        setIsOpen={setIsOpen}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        setSelectedOption={setSelectedOption}
        handleKeyPress={handleKeyPress}
        handleUserSelection={handleUserSelection}
      />

      {userInfo && (
        userInfo.role === "student" ? (
          <StudentInfo user={userInfo} />
        ) : userInfo.role === "teacher" ? (
          <TeacherInfo user={userInfo} />
        ) : null
      )}

      {userList.length > 0 && (
        <UserList users={userList} onUserSelect={handleUserSelection} />
      )}

      {!userInfo && userList.length === 0 && <EmptyState />}
    </div>
  );
};

export default AllUsersDashboard;
