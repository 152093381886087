import React from 'react';
import adminIcon from "../../../../../assets/admin.png";

const EmptyState = () => {
  return (
    <div className="empty-state-container">
      <img src={adminIcon} alt="Admin" className="empty-state-image" />
      <p className="empty-state-text">
        Nhập username để tìm kiếm người dùng hoặc <br /> nhấn Enter để xem
        danh sách ngẫu nhiên
      </p>
    </div>
  );
};

export default EmptyState;