import ProfileHeader from "../../../components/ProfileHeader/profileHeader";
import './course.css'
import { useNavigate } from 'react-router-dom';
import { useAuth } from "../../../AuthContext";
import React, { useEffect, useState} from "react";
import axios from "axios";
import CourseCardWhiteBig from "../../../components/Card/CourseCard/CourseCardWhiteBig";

const TeacherCourse = () => {
    
    const navigate = useNavigate();
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [myCourses, setMyCourses] = useState([]);
    const [numberOfLessons, setNumberOfLessons] = useState(0);
    const [numberOfStudents, setNumberOfStudents] = useState(0);
    const { accessToken } = useAuth();
    
    useEffect(() => {
        if (!accessToken) {
            navigate('/login');
            return;
        }

        const fetchUserProfile = async () => {
            try {
                const response = await axios.get(
                    `${process.env["REACT_APP_BASE_API"]}/api/me/`,
                    {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    }
                );

                if (response.status === 401) {
                    navigate('/login');
                }

                const userProfile = response.data;
                if (userProfile.role !== 'teacher') {
                    navigate('/not-authorized');
                }
                setUser(userProfile);

            } catch (error) {
                setError("Lỗi khi tải thông tin người dùng.");
                navigate('/login');
            } finally {
                setLoading(false); // Set loading to false after fetching user profile
            }
        };

        fetchUserProfile();
    }, [accessToken, navigate]);

    useEffect(() => {
        if (!user) return;

        const fetchCourses = async () => {
            if (!accessToken) {
                navigate('/login');
                return;
            }

            try {
                const response = await axios.get(
                    `${process.env["REACT_APP_BASE_API"]}/api/course/getAllCourses/`,
                    {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                        params: {
                            teacher_id: user.m_id
                        }
                    }
                );
                if (response.status === 401) {
                    navigate('/login');
                }
                
                setMyCourses(response.data); 
            } catch (error) {
                setError("Lỗi khi tải thông tin khóa học.");
                navigate('/login');
            } finally {
                setLoading(false); 
            }
        };

        const fetchNumberOfLessons = async () => {
            if (!accessToken) {
                navigate('/login');
                return;
            }

            try{
                const response = await axios.get(
                    `${process.env["REACT_APP_BASE_API"]}/api/course/getLessonNumber/`,
                    {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    }
                );
                if (response.status === 401) {
                    navigate('/login');
                }
                
                setNumberOfLessons(response.data.lesson_num);
            } catch (error) {
                setError("Lỗi khi tải thông tin khóa học.");
            }
        };

        const fetchNumberOfStudents = async () => {
            if (!accessToken) {
                navigate('/login');
                return;
            }

            try{
                const response = await axios.get(
                    `${process.env["REACT_APP_BASE_API"]}/api/course/getStudentNumber/`,
                    {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    }
                );
                if (response.status === 401) {
                    navigate('/login');
                }
                
                setNumberOfStudents(response.data.student_num);
            } catch (error) {
                setError("Lỗi khi tải thông tin khóa học.");
            }
        };

        if (accessToken) {
            Promise.all([fetchCourses(), fetchNumberOfLessons(), fetchNumberOfStudents()]);
        }
    }, [accessToken, user, navigate]);
        


    // if (loading) return <Loading text="Đang tải..." />; // Use Loading component

    return (
        <div className="course-dashboard-container">
            <ProfileHeader></ProfileHeader>

            <div className="course-dashboard-headpart">
                <div className="course-dashboard-headpart-infobox">
                    <div className="course-dashboard-headpart-infobox-text">
                        <div style={{color:"gray"}}>Courses</div>
                        <div style={{color:"black", fontSize:"3rem"}}>{myCourses.length}</div>
                    </div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60" fill="none" className="course-dashboard-headpart-infobox-icon">
                        <path opacity="0.21" fillRule="evenodd" clipRule="evenodd" d="M0 30V37C0 49.7025 10.2975 60 23 60H30H37C49.7025 60 60 49.7025 60 37V30V23C60 10.2975 49.7025 0 37 0H30H23C10.2975 0 0 10.2975 0 23V30Z" fill="#3D8FEF"/>
                        <path opacity="0.587821" fillRule="evenodd" clipRule="evenodd" d="M20.6666 23.3333C20.6666 26.2789 23.0544 28.6667 26 28.6667C28.9455 28.6667 31.3333 26.2789 31.3333 23.3333C31.3333 20.3878 28.9455 18 26 18C23.0544 18 20.6666 20.3878 20.6666 23.3333ZM34 28.6667C34 30.8758 35.7908 32.6667 38 32.6667C40.2091 32.6667 42 30.8758 42 28.6667C42 26.4575 40.2091 24.6667 38 24.6667C35.7908 24.6667 34 26.4575 34 28.6667Z" fill="#3D8FEF"/>
                        <path fillRule="evenodd" clipRule="evenodd" d="M25.9778 31.3334C19.6826 31.3334 14.5177 34.5687 14.0009 40.9323C13.9727 41.2789 14.6356 42 14.97 42H36.9956C37.9972 42 38.0128 41.194 37.9972 40.9334C37.6065 34.391 32.3616 31.3334 25.9778 31.3334ZM45.2746 42L40.1333 42C40.1333 38.9988 39.1417 36.2292 37.4683 34.0009C42.0103 34.0505 45.7189 36.3469 45.998 41.2C46.0092 41.3955 45.998 42 45.2746 42Z" fill="#3D8FEF"/>
                    </svg>
                </div>
                <div className="course-dashboard-headpart-infobox">
                    <div className="course-dashboard-headpart-infobox-text">
                        <div style={{color:"gray"}}>Lessons</div>
                        <div style={{color:"black", fontSize:"3rem"}}>{numberOfLessons}</div>
                    </div>                        
                    <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60" fill="none" className="course-dashboard-headpart-infobox-icon">
                        <path opacity="0.21" fillRule="evenodd" clipRule="evenodd" d="M0 30V37C0 49.7025 10.2975 60 23 60H30H37C49.7025 60 60 49.7025 60 37V30V23C60 10.2975 49.7025 0 37 0H30H23C10.2975 0 0 10.2975 0 23V30Z" fill="#FEC53D"/>
                    </svg>
                </div>
                <div className="course-dashboard-headpart-infobox">
                    <div className="course-dashboard-headpart-infobox-text">
                        <div style={{color:"gray"}}>Students</div>
                        <div style={{color:"black", fontSize:"3rem"}}>{numberOfStudents}</div>
                    </div>                        
                    <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60" fill="none" className="course-dashboard-headpart-infobox-icon">
                        <path opacity="0.21" fillRule="evenodd" clipRule="evenodd" d="M0 30V37C0 49.7025 10.2975 60 23 60H30H37C49.7025 60 60 49.7025 60 37V30V23C60 10.2975 49.7025 0 37 0H30H23C10.2975 0 0 10.2975 0 23V30Z" fill="#FEC53D"/>
                    </svg>
                </div>
            </div>

            <div className="course-toolbar">
                <div className="course-toolbar-search">
                    <div className="course-toolbar-box-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                            <path fillRule="evenodd" clipRule="evenodd" d="M7.49996 2.33317C4.64649 2.33317 2.33329 4.64637 2.33329 7.49984C2.33329 10.3533 4.64649 12.6665 7.49996 12.6665C8.91259 12.6665 10.1928 12.0996 11.1255 11.1809C11.1343 11.1712 11.1433 11.1618 11.1526 11.1525C11.1619 11.1432 11.1714 11.1341 11.181 11.1254C12.0997 10.1927 12.6666 8.91246 12.6666 7.49984C12.6666 4.64637 10.3534 2.33317 7.49996 2.33317ZM12.8853 11.7066C13.7925 10.5468 14.3333 9.08649 14.3333 7.49984C14.3333 3.72589 11.2739 0.666504 7.49996 0.666504C3.72601 0.666504 0.666626 3.72589 0.666626 7.49984C0.666626 11.2738 3.72601 14.3332 7.49996 14.3332C9.08661 14.3332 10.547 13.7924 11.7068 12.8851L15.9106 17.089C16.236 17.4144 16.7637 17.4144 17.0891 17.089C17.4145 16.7636 17.4145 16.2359 17.0891 15.9105L12.8853 11.7066Z" fill="#5E5D64"/>
                        </svg>
                        <input type="text" placeholder="Search course" className="course-toolbar-search-input"></input>
                    </div>
                </div>
                <div className="course-toolbar-view"> 
                    <div className="course-toolbar-box-icon"> 
                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="20" viewBox="0 0 18 18" fill="none">
                            <path fillRule="evenodd" clipRule="evenodd" d="M1.91663 2.33333C1.91663 1.8731 2.28972 1.5 2.74996 1.5H15.4484C15.9086 1.5 16.2817 1.8731 16.2817 2.33333V4.2381C16.2817 4.45911 16.1939 4.67107 16.0376 4.82735L11.2023 9.66264V14.3968C11.2023 14.7125 11.024 15.001 10.7417 15.1422L8.202 16.412C7.94368 16.5412 7.6369 16.5274 7.39122 16.3755C7.14553 16.2237 6.99599 15.9555 6.99599 15.6667V9.66264L2.1607 4.82735C2.00442 4.67107 1.91663 4.45911 1.91663 4.2381V2.33333ZM3.58329 3.16667V3.89292L8.41858 8.72821C8.57486 8.88449 8.66266 9.09645 8.66266 9.31746V14.3183L9.53567 13.8818V9.31746C9.53567 9.09645 9.62347 8.88449 9.77975 8.72821L14.615 3.89292V3.16667H3.58329Z" fill="#181719"/>
                        </svg>
                        <div className="course-toolbar-search-input">View</div>
                    </div>
                </div>
                <div className="course-toolbar-white-button">
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="20" viewBox="0 0 18 18" fill="none">
                        <path fillRule="evenodd" clipRule="evenodd" d="M9 1.5C9.46024 1.5 9.83333 1.8731 9.83333 2.33333V8.16667H15.6667C16.1269 8.16667 16.5 8.53976 16.5 9C16.5 9.46024 16.1269 9.83333 15.6667 9.83333H9.83333V15.6667C9.83333 16.1269 9.46024 16.5 9 16.5C8.53976 16.5 8.16667 16.1269 8.16667 15.6667V9.83333H2.33333C1.8731 9.83333 1.5 9.46024 1.5 9C1.5 8.53976 1.8731 8.16667 2.33333 8.16667H8.16667V2.33333C8.16667 1.8731 8.53976 1.5 9 1.5Z" fill="#3D8FEF"/>
                    </svg>
                    <div className="course-toolbar-search-input" style={{color:"#3D8FEF"}}>New roadmap</div>                        
                </div>
                <div className="course-toolbar-blue-button" onClick={() => navigate('newCourse')}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="20" viewBox="0 0 18 18" fill="none">
                        <path fillRule="evenodd" clipRule="evenodd" d="M9 1.5C9.46024 1.5 9.83333 1.8731 9.83333 2.33333V8.16667H15.6667C16.1269 8.16667 16.5 8.53976 16.5 9C16.5 9.46024 16.1269 9.83333 15.6667 9.83333H9.83333V15.6667C9.83333 16.1269 9.46024 16.5 9 16.5C8.53976 16.5 8.16667 16.1269 8.16667 15.6667V9.83333H2.33333C1.8731 9.83333 1.5 9.46024 1.5 9C1.5 8.53976 1.8731 8.16667 2.33333 8.16667H8.16667V2.33333C8.16667 1.8731 8.53976 1.5 9 1.5Z" fill="white"/>
                    </svg>
                    <div className="course-toolbar-search-input" style={{color:"white"}}>New course</div>
                </div>
            </div>

            <div className="ea90-course-list">
                <div className="my-courses">
                    <h2 className="my-courses-title">My courses</h2>
                    {
                        myCourses.length > 0 ? (
                            myCourses.map((course, index) => (
                                <CourseCardWhiteBig 
                                    key={index}
                                    title={course.m_title}
                                    subtitle={course.m_description}
                                    userCount={0}
                                    author={user.m_name}
                                    onClick={() => navigate(`${course.m_course_id}`)}
                                />
                            ))
                        ) : (
                            <div className="no-courses">You have no course yet</div>
                        )
                    }
                </div>
            </div>
        </div>
    );
}

export default TeacherCourse;