import React from 'react';
import { Avatar } from "@mui/material";
import InfoItem from './InfoItem';

const StudentInfo = ({ user }) => {
  const infoItems = [
    { label: "Username", value: user.m_user_name },
    { label: "Gmail", value: user.m_email },
    { label: "Birthday", value: user.m_birthdate },
    { label: "Numberphone", value: user.m_phone },
  ];

  return (
    <div className="user-info">
      <div className="avatar-area">
        <h2 className="role-display">Student</h2>
        <Avatar
          alt={user.m_user_name}
          src={user.m_avatar_link || "/default-avatar.png"}
          sx={{ width: 150, height: 150 }}
        />
        <h3 className="user-fullname">{user.m_name || "--"}</h3>
        {user.m_bio && <p className="user-bio">{user.m_bio}</p>}
      </div>
      <div className="info-area">
        <div className="info-row">
          {infoItems.map((item, index) => (
            <InfoItem key={index} label={item.label} value={item.value} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default StudentInfo;